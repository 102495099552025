import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@doit/pcnt-react-ui-library';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import i18n from '../../../common/i18n';
import useNavigation from '../../../hooks/useNavigation';
import useCommonStyles from '../../../common/styles/useCommonStyles';
import { CREDICUOTAS_COMMERCE_WEB_URL, getEnv } from '../../../api/env';
import { useToaster } from '../../../contexts/ToasterContext';

const BiometricIncompatibleBrowser = ({ hash }) => {
  const classes = useCommonStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToUploadDocuments } = useNavigation();
  const [, copyToClipboard] = useCopyToClipboard();
  const { showToast } = useToaster();

  const getLink = () => `${getEnv(CREDICUOTAS_COMMERCE_WEB_URL)}/login?redirect=/r/${hash}`;

  const handleCopy = () => {
    copyToClipboard(getLink());
    showToast(i18n.BiometricIncompatibleBrowser.linkCopied, 'success', 5000, { vertical: 'top', horizontal: 'center' });
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container sx={{ padding: '40px 24px' }} onClick={handleCopy}>
        <Grid item xs={12}>
          <img src="/images/rejected-icon.svg" alt="Navegador incompatible" />
          <Typography sx={{ marginTop: '24px', fontSize: '24px', fontWeight: 600 }}>{i18n.BiometricIncompatibleBrowser.title}</Typography>
          <Typography sx={{ marginTop: '12px', fontSize: '14px' }}>{parse(i18n.BiometricIncompatibleBrowser.description[0])}</Typography>
          <Typography sx={{ marginTop: '32px', fontSize: '14px' }}>{i18n.BiometricIncompatibleBrowser.description[1]}</Typography>
          <Grid
            container
            alignItems="center"
            sx={{
              marginTop: '16px',
              backgroundColor: '#F5F5F5',
              padding: '12px 16px',
            }}
          >
            <Grid item xs={7} sx={{ paddingRight: '16px' }}>
              <Typography
                align="left"
                sx={{
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  userSelect: 'none',
                }}
              >
                {getLink()}
              </Typography>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right', borderLeft: '1px solid #CCCCCC' }}>
              <Button variant="primary" sx={{ minWidth: '0px' }} endIcon={<ContentCopyIcon />} onClick={handleCopy}>
                {i18n.BiometricIncompatibleBrowser.cta[0]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.ctaContainer}>
            <Button variant="secondary" color="grey" onClick={() => goToUploadDocuments(hash)} fullWidth={isMobile}>
              {i18n.BiometricIncompatibleBrowser.cta[1]}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

BiometricIncompatibleBrowser.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default BiometricIncompatibleBrowser;
