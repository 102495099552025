// Almacena el nombre de usuario del login. (Recordar usuario)
export const COMMERCE_CREDENTIALS_USERNAME = 'commerce_credentials_username';

// Almacena la info del usuario loggeado
export const LOGGED_USER = 'logged_user';

// Almacena la fecha para mostrar el proximo incentivo de descargar la app
export const INCENTIVE_INSTALL_APP_NEXT_DATE = 'install_app';

// Almacena flag de la instancia de camara elegida
export const IS_FRONT_CAMERA = 'is_front_camera';
