/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  calculateMaxAmountAvailable,
  getDocuments, getInstallmentsApi, getInstallmentsDual, getInstallmentsWithOriginalLine, getInstallmentsWithOriginalLineDual,
  getLoanRequest, getMaxAmountLineDual,
  getRequiredInputFields, getVehicleParameterMinAmount,
  sendVoucher,
  setInstallment,
} from '../../api/workflowApiService';
import { PLLLA_DEBIT_CARD_FIELD_NAME, PTLLA_DEBIT_CARD } from '../../constants/commerceContants';
import i18n from '../../common/i18n';
import { getApiErrorMessage } from '../../services/errorApiService';
import { useToaster } from '../../contexts/ToasterContext';
import {
  getMaxAmountAvailableConsideringDualOffer, getVehicleTypeBySubproductId, hasAdvancePayment, isProductToPayVehicle,
  shouldOfferNewVehiclesBySubproductId, shouldOfferUsedVehiclesBySubproductId,
} from '../../utils/loanRequestUtils';
import {
  getCarLoanToValuePercentageByYear,
  getVehicleBrands, getVehicleModels, getVehiclePrice, getVehiclePriceByYearApi,
} from '../../api/vehicleApiService';
import useNavigation from '../useNavigation';

const useInstallmentPlanController = () => {
  const { showToast } = useToaster();
  const { hash } = useParams();
  const { verifyHasDistanceSellingLink, resolveNextStep } = useNavigation();

  const [globalLoading, setGlobalLoading] = useState(false);
  const [percentageByAge, setPercentageByAge] = useState();
  const [loanToValue, setLoanToValue] = useState();
  const [loanRequest, setLoanRequest] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [year, setYear] = useState();
  const [vehicleNotLoanable, setVehicleNotLoanable] = useState();
  const [vehicleYearsList, setVehicleYearsList] = useState();
  const [vehicleModelLoading, setVehicleModelLoading] = useState();
  const [maximumLoanable, setMaximumLoanable] = useState();
  const [sendOfferLoading, setSendOfferLoading] = useState(false);
  const [installmentsLoading, setInstallmentsLoading] = useState(false);
  const [installments, setInstallments] = useState();
  const [requestedAmount, setRequestedAmount] = useState();
  const [advanceAmount, setAdvanceAmount] = useState();
  const [selectInstallmentLoading, setSelectInstallmentLoading] = useState();
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [vehicleMinAmount, setVehicleMinAmount] = useState();
  const [cancelFlexLoading, setCancelFlexLoading] = useState(false);
  const [cancelFlexEmpty, setCancelFlexEmpty] = useState(false);

  const getCancelableLoanIdSelected = (lr) => {
    const selectLoanIdList = [];
    if (lr.loanToBePrePaid) {
      lr.loanToBePrePaid.forEach((loan) => {
        if (loan.selected) {
          selectLoanIdList.push(loan.loanSappId);
        }
      });
    }
    return selectLoanIdList;
  };

  const getInstallments = async ({ amount }) => {
    try {
      setInstallmentsLoading(true);
      if (amount > getMaxAmountAvailableConsideringDualOffer(loanRequest)) {
        showToast(i18n.InstallmentPlanPage.inputAmountGreaterThanMax, 'info');
        return;
      }

      // Si es cancelacion flexible y no selecciono ninguno
      if (loanRequest.cancelOfferSelected && loanRequest.showCancelablesFlex) {
        const countSelected = loanRequest.loanToBePrePaid.filter((loan) => loan.selected === true).length;
        if (countSelected === 0) {
          setCancelFlexEmpty(true);
          return;
        }
        setCancelFlexEmpty(false);
      }

      if (isProductToPayVehicle(loanRequest)) {
        if (vehicleYearsList && vehicleYearsList.length > 0
          && (!brand || brand === 'none' || !model || model === 'none' || !year || year === 'none')) {
          // Para auto valido que haya completado marca, modelo y año
          showToast(i18n.InstallmentPlanPage.vehicleInfoRequired[1], 'info');
          return;
        }
        if (!brand || brand === 'none' || !model || model === 'none') {
          // Para moto valido que haya completado marca y modelo
          showToast(i18n.InstallmentPlanPage.vehicleInfoRequired[0], 'info');
          return;
        }
        if (amount > maximumLoanable) {
          // Si el monto es mayor al maximo prestable para el vehiculo
          showToast(i18n.InstallmentPlanPage.inputAmountGreaterThanMax, 'info');
          return;
        }
        if (vehicleNotLoanable) {
          // Si el vehiculo no es apto para prendario
          showToast(i18n.InstallmentPlanPage.vehicleSelection.priceLowerThanMin, 'info');
          return;
        }
      }

      let amountAsked = amount;
      if (hasAdvancePayment(loanRequest)) {
        // amountAsked: es el calculado luego de aplicar el porcentaje de anticipo, es lo que se va a financiar
        // eslint-disable-next-line operator-assignment
        amountAsked = amountAsked / (1 + (loanRequest.advancePaymentPercentage / 100));
        // advancePaymentAmount: es el monto del anticipo propiamente dicho
        const advancePaymentAmount = amount - amountAsked;
        setAdvanceAmount(advancePaymentAmount);
      }

      if ((loanRequest.isOfferDual && loanRequest.cancelOfferSelected) || !loanRequest.isOfferDual) {
        // Si es oferta dual, sumo el monto a cancelar solo si tiene cancelacion seleccionada.
        // Si no es oferta dual, sumo el monto a cancelar, si no tiene suma cero.
        if (loanRequest.showCancelablesFlex && loanRequest.loanToBePrePaid?.length > 0) {
          // Si tiene cancelables flexibles al monto solicitado le suma el de los creditos a cancelar
          let amountAux = 0;
          loanRequest.loanToBePrePaid.forEach((loan) => {
            if (loan.selected) {
              amountAux += loan.amount;
            }
          });
          amountAsked = Number(amount) + Math.ceil(amountAux);
        } else {
          amountAsked = Number(amount) + loanRequest.response.prePaidLoanAmount;
        }
      }
      amountAsked = parseInt(amountAsked, 10);
      setRequestedAmount(amountAsked);
      const getInstallmentsApiResolved = loanRequest.parallelOfferSelected ? getInstallmentsDual : getInstallmentsApi;
      const { data: installmentsAux } = await getInstallmentsApiResolved(hash, amountAsked, getCancelableLoanIdSelected(loanRequest));

      const getInstallmentsWithOriginalLineResolved = loanRequest.parallelOfferSelected
        ? getInstallmentsWithOriginalLineDual : getInstallmentsWithOriginalLine;
      const { data: installmentsWithOriginalLineAux } = await getInstallmentsWithOriginalLineResolved(hash, amountAsked, installmentsAux[0].idLinea);
      // Almaceno en las cuotas el monto original para saber si tiene o no descuento
      installmentsWithOriginalLineAux.forEach((iol) => {
        const inst = installmentsAux.find((i) => i.installments === iol.installments);
        if (inst) {
          inst.amountOriginalLine = iol.amount;
        }
      });
      setInstallments(installmentsAux);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setInstallmentsLoading(false);
    }
  };

  const getModelsByBrand = async (brandId) => {
    try {
      setVehicleModelLoading(true);
      const vehicleType = getVehicleTypeBySubproductId(loanRequest.subProductId);
      const newVehicles = shouldOfferNewVehiclesBySubproductId(loanRequest.subProductId);
      const usedVehicles = shouldOfferUsedVehiclesBySubproductId(loanRequest.subProductId);
      const { data: modelListAux } = await getVehicleModels(vehicleType, brandId, newVehicles, usedVehicles);
      setModelList(modelListAux);
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.modelListError, 'error');
    } finally {
      setVehicleModelLoading(false);
    }
  };

  const onChangeBrand = (value) => {
    const brandId = value.id;
    const brandName = value.name;
    setVehicleInfo({ brand: brandName });
    setBrand(brandId);
    setModel('none');
    setYear('none');
    setModelList([]);
    setVehicleYearsList(undefined);
    setMaximumLoanable(undefined);
    if (brandId !== 'none') {
      getModelsByBrand(brandId);
    }
  };

  const calculateMaximumLoanable = (vehiclePrice) => {
    let availableAmount = loanRequest.response.maxAmount;
    const vehicleMaxLoanable = (vehiclePrice * loanToValue) / 100;
    if (loanRequest.response.prePaidLoan) {
      availableAmount -= loanRequest.response.prePaidLoanAmount;
    }

    let maxLoanable = 0;
    if (availableAmount < vehicleMaxLoanable) {
      maxLoanable = availableAmount;
    } else {
      maxLoanable = vehicleMaxLoanable;
    }

    if (maxLoanable <= 0) {
      setVehicleNotLoanable(true);
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceLowerThanMin, 'info');
    } else {
      setVehicleNotLoanable(false);
      setMaximumLoanable(maxLoanable);
    }
  };

  const getVehicleListPrice = async (modelId) => {
    try {
      const res = await getVehiclePrice(getVehicleTypeBySubproductId(loanRequest.subProductId), modelId);
      if (res.data.list_price) {
        const vehiclePrice = (res.data.list_price * loanToValue) / 100;
        if (vehiclePrice < vehicleMinAmount) {
          setVehicleNotLoanable(true);
          showToast(i18n.InstallmentPlanPage.vehicleSelection.priceLowerThanMin, 'info');
        } else {
          setVehicleNotLoanable(false);
          const modelName = modelList.find((m) => m.codia === modelId).description;
          setVehicleInfo({
            ...vehicleInfo,
            model: modelName,
            codia: modelId,
            price: res.data.list_price,
            year: new Date().getFullYear(),
            age: -1,
          });
          calculateMaximumLoanable(res.data.list_price);
        }
      }
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceError, 'error');
    }
  };

  const getVehiclePriceByYear = async (modelId) => {
    try {
      const res = await getVehiclePriceByYearApi(getVehicleTypeBySubproductId(loanRequest.subProductId), modelId);
      if (res.data.length > 0) {
        setVehicleYearsList(res.data);
        setYear('none');
      } else {
        showToast(i18n.InstallmentPlanPage.vehicleSelection.priceYearError, 'error');
      }
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceYearError, 'error');
    }
  };

  const getVehicleListPriceAndYears = async (modelId) => {
    try {
      const vehicleType = getVehicleTypeBySubproductId(loanRequest.subProductId);
      const resVehiclePrice = await getVehiclePrice(vehicleType, modelId);
      const resVehiclePriceByYear = await getVehiclePriceByYearApi(vehicleType, modelId);

      let resVehiclePriceByYearAux = [];

      if (resVehiclePriceByYear.data.length > 0) {
        resVehiclePriceByYearAux = resVehiclePriceByYear.data;
      }

      if (resVehiclePrice.data.list_price) {
        resVehiclePriceByYearAux.push(
          {
            year: 'OKM',
            price: resVehiclePrice.data.list_price,
          },
        );
      }

      setVehicleYearsList(resVehiclePriceByYearAux);
      setYear('none');
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceYearError, 'error');
    }
  };

  const getVehiclePriceByModelId = (modelId) => {
    const modelAux = modelList.find((m) => m.codia === modelId);
    const offerNewVehicles = shouldOfferNewVehiclesBySubproductId(loanRequest.subProductId) && modelAux.list_price;
    const offerUsedVehicles = shouldOfferUsedVehiclesBySubproductId(loanRequest.subProductId) && modelAux.prices;
    if (offerNewVehicles && offerUsedVehicles) {
      getVehicleListPriceAndYears(modelId);
    } else if (offerNewVehicles) {
      getVehicleListPrice(modelId);
    } else if (offerUsedVehicles) {
      getVehiclePriceByYear(modelId);
    }
  };

  const onChangeModel = (value) => {
    const modelId = value.codia;
    const modelName = value.description;
    setModel(modelId);
    setYear('none');
    setVehicleInfo({ ...vehicleInfo, model: modelName, codia: modelId });
    setVehicleYearsList(undefined);
    if (modelId !== 'none') {
      getVehiclePriceByModelId(modelId);
    }
  };

  const getPercentageForCarYear = (vehicleYear) => {
    const thisYear = new Date().getFullYear();
    const age = thisYear - vehicleYear.year;
    if (percentageByAge[age] !== undefined) {
      return percentageByAge[age];
    }
    const numericKeys = Object.keys(percentageByAge)
      .map(Number)
      .filter((num) => !Number.isNaN(num));

    const maxKey = Math.max(...numericKeys);
    return percentageByAge[maxKey];
  };

  const onChangeYear = (e) => {
    const yearAux = e.target.value;
    setYear(yearAux);
    if (yearAux !== 'none') {
      const vehicleYear = vehicleYearsList.find((v) => v.year === yearAux);
      let vehicleYearAux = vehicleYear.year;
      let vehicleAgeAux = new Date().getFullYear() - yearAux;
      if (yearAux === 'OKM') {
        vehicleYearAux = new Date().getFullYear();
        vehicleAgeAux = -1;
      }
      const loanToValueYear = getPercentageForCarYear(vehicleYear);
      setLoanToValue(loanToValueYear);
      const vehiclePrice = (vehicleYear.price * loanToValueYear) / 100;
      if (vehiclePrice < vehicleMinAmount) {
        setVehicleNotLoanable(true);
        showToast(i18n.InstallmentPlanPage.vehicleSelection.priceLowerThanMin, 'info');
      } else {
        setVehicleNotLoanable(false);
        setVehicleInfo({
          ...vehicleInfo,
          price: vehicleYear.price,
          year: vehicleYearAux,
          age: vehicleAgeAux,
        });
        calculateMaximumLoanable(vehicleYear.price);
      }
    } else {
      setVehicleInfo({});
    }
  };

  const sendOffer = async ({ cellphone }) => {
    try {
      setSendOfferLoading(true);
      await sendVoucher(hash, cellphone);
      showToast(i18n.InstallmentPlanPage.offerSentSuccessfuly, 'success');
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSendOfferLoading(false);
    }
  };

  const choseInstallment = async () => {
    try {
      setSelectInstallmentLoading(true);
      const installment = selectedInstallment;
      installment.requestedAmount = requestedAmount;

      let vehicleInfoAux;
      if (isProductToPayVehicle(loanRequest)) {
        vehicleInfoAux = { ...vehicleInfo };
      }

      if (loanRequest.isOfferDual && loanRequest.parallelOfferSelected) {
        // es oferta dual la solicitud y ademas está seleccionado la oferta paralela
        installment.isDual = true;
      }

      await setInstallment(hash, installment, vehicleInfoAux, getCancelableLoanIdSelected(loanRequest));

      resolveNextStep(hash);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSelectInstallmentLoading(false);
    }
  };

  const resolveCancelableMaxAmountCalculated = async (lr) => {
    const res = await calculateMaxAmountAvailable(hash, getCancelableLoanIdSelected(lr));
    lr.newMaxAmountCalculated = res.data.maxAmount;
    lr.cancelableAmountCalculated = Math.ceil(res.data.cancelableAmount);
    setLoanRequest(lr);
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      const { data: lr } = await getLoanRequest(hash);

      // Verifica si tiene generado link de venta a distancia
      verifyHasDistanceSellingLink(lr);

      if (!lr.response.rejected) {
        if (isProductToPayVehicle(lr)) {
          const { data: minAmountForVehicle } = await getVehicleParameterMinAmount();
          setVehicleMinAmount(minAmountForVehicle);
          try {
            const { data } = await getCarLoanToValuePercentageByYear(hash);
            setPercentageByAge(data);
            const vehicleType = getVehicleTypeBySubproductId(lr.subProductId);
            const newVehicles = shouldOfferNewVehiclesBySubproductId(lr.subProductId);
            const usedVehicles = shouldOfferUsedVehiclesBySubproductId(lr.subProductId);
            const { data: brandListAux } = await getVehicleBrands(vehicleType, newVehicles, usedVehicles);
            setBrandList(brandListAux);
          } catch (error) {
            showToast(i18n.InstallmentPlanPage.vehicleSelection.brandListError, 'error');
          }
        }

        const [{ data: requiredDocuments }, { data: requiredInputFields }] = await Promise.all([getDocuments(hash), getRequiredInputFields(hash)]);

        const cardInput = requiredInputFields.find((field) => field.name === PTLLA_DEBIT_CARD);
        if (cardInput) {
          cardInput.userInputOptions.label = i18n.InstallmentPlanPage.debitCard;
          cardInput.name = PLLLA_DEBIT_CARD_FIELD_NAME;
          requiredDocuments.push(cardInput);
        }
        lr.requiredDocuments = requiredDocuments;
      }

      // Si es dual y aprobado busco el máximo dual
      if (lr.isOfferDual && !lr.response.rejected) {
        const res = await getMaxAmountLineDual(hash);
        lr.maxAmountDual = res.data.maxAmount;
        lr.parallelOfferSelected = true;
      }

      if (lr.showCancelablesFlex && lr.loanToBePrePaid?.length > 0) {
        lr.loanToBePrePaid.forEach((loan) => {
          loan.selected = true;
        });
        await resolveCancelableMaxAmountCalculated(lr);
      }

      setLoanRequest(lr);
      setLoanToValue(lr.loanToValue);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  const cleanInstallmentPlan = () => {
    setInstallments(undefined);
    setSelectedInstallment(undefined);
  };

  const selectParallelOffer = () => {
    const lr = { ...loanRequest };
    lr.parallelOfferSelected = true;
    lr.cancelOfferSelected = false;
    setCancelFlexEmpty(false);
    cleanInstallmentPlan();
    setLoanRequest(lr);
  };

  const selectCancelOffer = () => {
    const lr = { ...loanRequest };
    lr.parallelOfferSelected = false;
    lr.cancelOfferSelected = true;
    setCancelFlexEmpty(false);
    cleanInstallmentPlan();
    setLoanRequest(lr);
  };

  const handleChangeCancelFlex = async (loan) => {
    setCancelFlexLoading(true);
    const lr = { ...loanRequest };
    if (lr.showCancelablesFlex && lr.loanToBePrePaid?.length > 0) {
      lr.loanToBePrePaid.forEach((l) => {
        if (loan.loanSappId === l.loanSappId) {
          l.selected = !l.selected;
        }
      });

      await resolveCancelableMaxAmountCalculated(lr);
      setCancelFlexLoading(false);
    }
    cleanInstallmentPlan();
  };

  const doReload = () => {
    init();
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    doReload,
    installments,
    selectedInstallment,
    setSelectedInstallment,
    brandList,
    modelList,
    brand,
    model,
    year,
    onChangeBrand,
    vehicleModelLoading,
    onChangeModel,
    onChangeYear,
    maximumLoanable,
    sendOffer,
    sendOfferLoading,
    getInstallments,
    installmentsLoading,
    requestedAmount,
    advanceAmount,
    choseInstallment,
    selectInstallmentLoading,
    cleanInstallmentPlan,
    vehicleYearsList,
    selectParallelOffer,
    selectCancelOffer,
    handleChangeCancelFlex,
    cancelFlexLoading,
    cancelFlexEmpty,
  };
};

export default useInstallmentPlanController;
