import { Alert, Snackbar } from '@mui/material';
import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ToasterContext = createContext();

export const useToaster = () => useContext(ToasterContext);

export const ToasterProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [autoCloseTime, setAutoCloseTime] = useState(5000);
  const [anchor, setAnchor] = useState({ vertical: 'bottom', horizontal: 'center' });

  const showToast = (messageAux, severityAux, time = autoCloseTime, anchorAux = anchor) => {
    setMessage(messageAux);
    setSeverity(severityAux);
    setAutoCloseTime(time);
    setOpen(true);
    setAnchor(anchorAux);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ToasterContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={autoCloseTime}
        anchorOrigin={anchor}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </ToasterContext.Provider>
  );
};

ToasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
