import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Alert } from '@doit/pcnt-react-ui-library';
import { PREPAID_CARD } from '../../../../constants/commerceContants';
import i18n from '../../../../common/i18n';

export const ManualSuccessMessage = ({
  loanRequest, showManualSuccessMessage, isProductToPayVehicleType, isPledgeDocCompleted,
}) => (
  <Alert
    severity="info"
    style={{ textAlign: 'left' }}
    icon={(
      <img
        src="/images/clock.svg"
        alt="time"
        style={{ width: '20px' }}
      />
    )}
  >
    <Typography>
      {loanRequest.disbursementMode === PREPAID_CARD && (
        <>
          {i18n.FinalPage.Messages.Manual.Success.isPrepaid}
        </>
      )}
      {showManualSuccessMessage && (
        <>
          {i18n.FinalPage.Messages.Manual.Success.isNotProductToPayVehicleType}
        </>
      )}
      {loanRequest.disbursementMode !== PREPAID_CARD && isProductToPayVehicleType && !isPledgeDocCompleted && (
        <>
          {i18n.FinalPage.Messages.Manual.Success.isNotPledgeDocCompleted}
        </>
      )}
      {loanRequest.disbursementMode !== PREPAID_CARD && isProductToPayVehicleType && isPledgeDocCompleted && (
        <>
          {i18n.FinalPage.Messages.Manual.Success.isPledgeDocCompleted}
        </>
      )}
    </Typography>
  </Alert>
);

ManualSuccessMessage.propTypes = {
  loanRequest: PropTypes.shape({
    disbursementMode: PropTypes.string.isRequired,
  }).isRequired,
  showManualSuccessMessage: PropTypes.bool.isRequired,
  isProductToPayVehicleType: PropTypes.bool.isRequired,
  isPledgeDocCompleted: PropTypes.bool.isRequired,
};

export default ManualSuccessMessage;
