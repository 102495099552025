import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import { privateRoutes } from '../constants/routesConstants';

const PublicRoute = ({ children }) => {
  const { loggedUser } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  if (loggedUser) {
    const path = queryParams.get('redirect');
    if (path) {
      return <Navigate to={path} replace />;
    }
    return <Navigate to={privateRoutes.dashboard} replace />;
  }
  return children;
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
