import { makeStyles } from '@mui/styles';

const useCommonStyles = makeStyles((theme) => ({
  ctaContainer: {
    margin: '32px 0px',
  },

  [theme.breakpoints.down('md')]: {
    ctaContainer: {
      margin: '0px',
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      backgroundColor: 'white',
      width: 'fill-available',
      padding: '32px 24px',
      borderTop: '1px solid #e0e0e0',
      zIndex: 1000,
    },
  },
}));

export default useCommonStyles;
