/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
import { ROLE_SELLER, ROLE_MANAGER } from '../constants/rolesConstants';

const i18n = {
  Login: {
    title: 'Iniciar sesión',
    description: 'Ingresá tu usuario y contraseña',
    userInput: 'Usuario',
    passInput: 'Contraseña',
    rememberMe: 'Recordar mi usuario',
    forgotPass: 'Olvidé mi contraseña',
    loginCTA: 'Iniciar sesión',
    errors: {
      E001: 'Usuario y/o contraseña incorrectos',
      E002: 'Usuario no habilitado para operar',
      E003: 'Vendedor no habilitado para operar',
      E004: 'Usuario no habilitado para operar',
      E005: 'Comercio no habilitado para operar',
      E006: 'Casa Matriz no habilitada para operar',
      E007: 'Usuario no habilitado para esta aplicación',
      E008: 'Usuario y/o contraseña incorrectos',
      E009: 'Usuario no está dado de alta en el Comercio',
      E010: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Tipo Producto / Comercio)',
      E011: 'La configuración del Usuario o Comercio es incorrecta (No existe Tipo Producto)',
      E012: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Tipo Producto / Organismo)',
      E013: 'La configuración del Usuario o Comercio es incorrecta (No existe Organismo)',
      E014: 'La configuración del Usuario o Comercio es incorrecta (No existe Línea Comercializador)',
      E015: 'La configuración del Usuario o Comercio es incorrecta (No existe Línea)',
      E016: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Línea / Organismo)',
      E017: 'La configuración del Usuario o Comercio es incorrecta (No existe Comercio Categoría)',
      E020: 'Usuario sin casa matriz configurada',
      EMAIL_IS_NOT_VALID: 'No tenemos registrado un email válido parar tu usuario, por favor comunicate con tu asesor comercial o Mesa de Acuerdo.',
      EXPIRED_EXECUTE_USER_PASSWORD_RESET: 'El link de restablecer contraseña expiró. Volvé a iniciar el proceso.',
      CHANGE_PASSWORD_USER_INTERNET_CHANNEL_ERROR: 'No es posible modificar o recupear la contraseña de este usuario debido a su configuración de canal internet. Contactá a tu comercial de Credicuotas',
      GENERIC_ERROR: 'Ocurrió un error mientras se procesaba la solicitud. Por favor intente nuevamente más tarde o comuniquese con Credicuotas',
    },
    reviewInfoMessage: 'Revisa los datos ingresados y volvé a intentarlo',
    sessionExpired: 'Sesión expirada',
  },

  ForgotPassword: {
    title: [
      '¿Olvidaste tu contraseña?',
      'Revisá tu correo',
    ],
    description: [
      'Ingresá tu usuario y te enviaremos un mail con instrucciones para restrabecerla.',
      'Enviamos un mail a <b>${0}</b> para que puedas restablecer tu contraseña.',
      'Si no te llega el mail o esta no es tu casilla de correo, comunicate con tu asesor comercial o Mesa de Acuerdos.',
    ],
    userInput: 'Usuario',
    recoverCTA: 'Restablecer contraseña',
    cancelCTA: 'Cancelar',
    initSessionCTA: 'Iniciar sesión',
  },

  ExpiredPassword: {
    title: [
      'Actualizá tu contraseña',
      '¡Listo!',
    ],
    description: [
      'La nueva contraseña tiene que ser diferente a la actual, y debe contener 6 caracteres usando números y/o letras',
      'Restableciste tu contraseña con éxito',
    ],
    warningMessage: 'Tu contraseña expiró',
    newPassInput: 'Nueva contraseña',
    newPassRepeatInput: 'Repetir nueva contraseña',
    updateCTA: 'Actualizar contraseña',
    cancelCTA: 'Cancelar',
  },

  RestorePassword: {
    title: 'Contraseña restablecida',
    description: 'Te enviamos un email con la nueva contraseña para que puedas ingresar a la plataforma.',
  },

  schemaValidation: {
    user: {
      required: 'El usuario es obligatorio',
    },
    pass: {
      required: 'La contraseña es obligatoria',
    },
    newPass: {
      required: 'La nueva contraseña es obligatoria',
      minLength: 'La nueva contraseña debe ser de mínimo 6 caracteres',
      alphanumeric: 'Debe contener solo letras y/o números',
    },
    repeatNewPass: {
      required: 'La nueva contraseña es obligatoria',
      match: 'Las contraseñas no coinciden. Revisá que ambas estén escritas igual',
    },
    customerId: {
      required: 'El DNI es obligatorio',
      length: 'El DNI debe ser un número entre 6 y 9 dígitos',
    },
    product: {
      required: 'Seleccioná un producto',
    },
    subproduct: {
      required: 'Seleccioná un sub producto',
    },
    terms: {
      required: 'Seleccioná el plazo de pago',
    },
    customerTaxId: {
      required: 'Seleccioná una persona',
    },
    gender: {
      required: 'Seleccioná el género',
    },
    howMeetUs: {
      required: 'Seleccioná como nos conociste',
    },
    email: {
      format: 'El formato del email es inválido',
    },
    amount: {
      format: 'El formato del importe es inválido',
    },
    cellphone: {
      required: 'El celular es obligatorio',
      format: 'El formato del celular es inválido',
    },
  },

  SideMenu: {
    sections: {
      account: 'Cuenta',
    },
    options: {
      home: 'Inicio / Calculadora',
      newLoanRequest: 'Nueva solicitud',
      loanRequests: 'Solicitudes / Cuponera',
      renewers: 'Renovadores',
      liquidations: 'Liquidaciones',
      commission: 'Comisiones',
      sales: 'Panel de ventas',
      credicuotasClub: 'Club Credicuotas',
      news: 'Noticias',
      manageBranches: 'Gestionar Sucursales',
      closeSession: 'Cerrar sesión',
    },
    downloadApp: '¡Descargá la app!',
  },

  QuotationPage: {
    title: 'Nueva solicitud',
    productAndSubproductSection: 'Elegí el producto y sub producto',
    productLabel: 'Producto',
    productEmptyLabel: 'Seleccionar producto',
    subproductLabel: 'Sub Producto',
    subproductEmptyLabel: 'Seleccionar sub producto',
    termsLabel: 'Plazo de Pago',
    termsEmptyLabel: 'Seleccionar plazo de pago',
    customerDataSection: 'Completá los datos de la persona',
    customerIdLabel: 'DNI',
    customerIdHint: 'Sin espacios ni puntos',
    customerTaxIdLabel: 'Seleccioná una persona',
    customerIdNotFound: 'No se encontraron personas para ese DNI',
    genderLabel: 'Género',
    howMeetUsLabel: '¿Cómo nos conoció?',
    pepLabel: '¿Es una Persona Políticamente Expuesta?',
    pepHelp: 'Se consideran PEPs a los Funcionarios Públicos que ocupan o que ocuparon altos cargos en puestos jerárquicos, como así también a sus familiares directos.',
    soLabel: '¿Es un Sujeto Obligado?',
    soHelp: 'Un Sujeto Obligado es una persona que tiene obligaciones ante la UIF por la actividad que realiza.',
    cta: 'Continuar',
    quotationUnavailable: 'La generación de nuevas solicitudes está deshabilitada para esta configuración de comercio',
    overlayLoadingMessage: 'Estamos haciendo un cálculo personalizado para encontrar la mejor oferta',
  },

  InstallmentPlanPage: {
    title: 'Monto total disponible: <span style="color: #E72064; font-weight: 700; font-size: 24px">${0}</span>',
    dualOfferTitle: 'Montos disponibles',
    ctaReload: 'Actualizar oferta',
    requiredDocumentation: 'Documentación requerida',
    debitCard: 'Tarjeta de débito',
    selectInstallmentPlan: 'Seleccioná el plan de cuotas',
    offerDetail: 'Detalle de la oferta',
    installmentPlanDetail: 'Plan de cuotas disponible para ${0}',
    advancePaymentAmount: 'Monto de anticipo: <span style="font-size: 20px; font-weight: 600">${0}</span>',
    advancepaymentHint: 'El cliente deberá pagar por anticipado un monto para poder contar con el capital ofrecido',
    firstInstallmentExpirationDate: 'La primera cuota vence el día ${0}',
    vehicleSelection: {
      title: 'Seleccioná el modelo del vehículo',
      description: 'Ingresá el vehículo elegido por el cliente para calcular el máximo prestable.',
      brand: 'Marca',
      model: 'Modelo',
      year: 'Año',
      brandEmptyOption: 'Seleccioná una marca',
      modelEmptyOption: 'Seleccioná modelo y versión',
      yearEmptyOption: 'Seleccioná el año',
      maxAmount: 'Maximo prestable: ${0}',
      maxAmountHint: 'El cliente deberá seleccionar un monto igual o inferior al máximo prestable.',
      maxAmountTooltip: 'El máximo prestable es el mínimo entre el monto total disponible para el cliente y el valor del vehículo.',
      brandListError: 'Ocurrió un error al intentar obtener el listado de marcas',
      modelListError: 'Ocurrió un error al intentar obtener el listado de modelos y versiones',
      priceError: 'Ocurrió un error al intentar obtener el precio del vehiculo',
      priceYearError: 'Ocurrió un error al intentar obtener los precios por año del vehículo',
      priceLowerThanMin: 'Modelo no disponible para prestamo prendario',
    },
    sendOffer: {
      title: 'Enviarle la oferta al cliente',
      input: 'Celular',
      cta: 'Enviar oferta',
    },
    copyToClipboard: {
      title: 'Hola, soy *${0}*\n',
      description: 'te quiero avisar que Credicuotas te ofrece un disponible máximo de *${0}* en cuotas fijas en el comercio *${1}* con los siguientes planes de pago:\n',
      installment: '* *${0}* cuotas de *${1}*',
      installmentDiscount: ' (incluye descuento *${0}%*)',
      planCopied: 'Plan de cuotas copiado',
      ctaLabel: 'Copiar plan de cuotas',
    },
    inputAmount: {
      title: 'Calculá el plan de cuotas',
      description: 'Ingresá el importe que el cliente quiera financiar para obtener el plan de cuotas disponible.',
      input: 'Importe',
      hint: 'Sin espacios ni puntos',
      cta: 'Calcular',
    },
    installmentPlanModify: 'Modificar Monto y Plazo',
    installmentPlanBack: 'Atrás',
    installmentPlanSelectedCTA: 'Continuar',
    rejected: 'Rechazado',
    back: 'Volver',
    inputAmountGreaterThanMax: 'El monto ingresado no puede ser mayor al monto total disponible',
    vehicleInfoRequired: [
      'Debe seleccionar marca y modelo para continuar',
      'Debe seleccionar marca, modelo y año para continuar',
    ],
    firstInstallmentBenefit: {
      title: 'Información importante para el cliente',
      description: 'Si el cliente toma el préstamo hoy y paga a tiempo, su primera cuota será de <span style="font-size: 16px; font-weight: 700; color: #DA0067">${0}</span>.',
    },
    offerSentSuccessfuly: 'Oferta enviada correctamente',
    availableAmount: 'Monto total disponible: <span style="font-weight: 700">${0}</span>',
    parallelOffer: {
      title: 'Nuevo préstamo',
      description: 'El cliente obtiene un nuevo préstamo, manteniendo el préstamo vigente actual.',
      availableAmount: 'Monto total disponible:',
    },
    cancelOffer: {
      title: 'Renovación de préstamo',
      description: 'El cliente cancela todos los préstamos actuales y se genera uno nuevo.',
      availableAmount: 'Monto total disponible:',
      cancelAmount: 'Monto a cancelar: <b>${0}</b>',
    },
    cancelableFlex: {
      title: [
        'El cliente tiene la posibilidad de cancelar más de un préstamo',
        'El cliente tiene la posibilidad de cancelar un préstamo',
      ],
      description: [
        'Actualmente tiene ${0} préstamos con un saldo restante de ${1}. Puede pagar alguno de los préstamos o el total y se cancelará con el nuevo préstamo.',
        'Actualmente tiene un préstamo activo con un saldo restante de ${0}.',
      ],
      resumeInfo: 'Monto total disponible: <span style="font-weight: 700; color: #DA0067">${0}</span> Monto a cancelar: <span style="font-weight: 700">${1}</span>',
      selectLoan: 'Seleccioná los préstamos que el cliente quiera cancelar',
      loanDetail: 'Préstamo N° ${0} - ${1} cuotas de ${2} - <b>Monto a cancelar ${3}</b>',
      emptySelection: [
        'Tenés que seleccionar al menos un préstamo',
        'Tenés que seleccionar al menos un préstamo para calcular el plan de cuotas.',
      ],
    },
  },

  FinalPage: {
    approved: 'Aprobado',
    preApproved: 'Pre-Aprobado',
    creditNumber: 'Crédito N° ${0}',
    LoanData: {
      date: 'Fecha: <b>${0}</b>',
      name: 'Nombre: <b>${0}</b>',
      dni: 'DNI: <b>${0}</b>',
      requestedAmount: 'Monto solicitado',
      numberOfInstallments: 'Cantidad de cuotas',
      installmentAmount: 'Monto cuota',
    },
    Messages: {
      Manual: {
        Success: {
          isPrepaid: 'Recordale al cliente que podrá utilizar la tarjeta luego de activarla en autogestión.',
          isNotProductToPayVehicleType: 'El préstamo se desembolsará cuando se realice la llamada de bienvenida exitosamente.',
          isNotPledgeDocCompleted: 'Se realizará una bienvenida al cliente de parte de Credicuotas para verificar esta operación y así podrás proceder con la gestión del crédito prendario.',
          isPledgeDocCompleted: 'Se verificará la documentación del préstamo prendario. Cuando esté verificada, entrégasela a tu comercial.',
        },
        Warning: {
          title: 'No se completó el proceso de carga de la solicitud.',
          subtitle: 'Para <strong>LIQUIDAR</strong> el préstamo se requiere:',
          reqCustomerDataCompleted: 'Completar los datos de contacto del cliente.',
          reqFileOrFollowingImages: 'Enviar legajo físico a Credicuotas o subir las siguientes imágenes.',
          reqSign: 'Enviar legajo físico a Credicuotas o subir las siguientes imágenes.',
          waitCall: 'Esperar el llamado de Bienvenida exitoso al cliente.',
        },
      },
      AutomaticWelcome: {
        title: 'ATENCIÓN - BIENVENIDA AUTOMÁTICA',
        subtitle: 'Para finalizar la solicitud, es necesario una verificación. <br /> Por favor <b>indicale al cliente</b> que:',
        checkWhatsappMessages: '<b>Revise </b> sus mensajes de whatsapp.',
        answerQuestions: '<b>Conteste unas preguntas</b> en los próximos <b>10 minutos.</b>',
      },
      AutomaticLiq: {
        title: 'La verificación de identidad del cliente fue exitosa.',
        deliverProduct: 'Puede realizar la entrega del Producto al Cliente.',
        disburseAmount: 'El préstamo será desembolsado en la cuenta del cliente en minutos.',
      },
      rejected: 'El análisis crediticio rechazó la solicitud y no podrá ser completada.',
      warnIfPaperless: 'RECUERDE enviar el legajo físico para evitar inconvenientes en el proceso.',
    },
    CTA: {
      goHome: 'Ir al inicio',
      downloadVoucher: 'Descargar Cuponera',
      downloadDocs: 'Descargar documentación',
    },
  },

  NPSForm: {
    completedTitle: '¡Gracias por responder!',
    completedSubTitle: 'La tendremos en cuenta para mejorar tu experiencia con Credicuotas.',
    buttonAnswered: 'Entendido',
    buttonSend: 'Enviar',
    buttonOmit: 'Omitir',
    title: 'Tu opinión nos interesa',
    noLikely: 'Muy insatisfecho',
    veryLikely: 'Muy satisfecho',
  },

  NPS: {
    LABEL_QUESTION_1: '¿Cuál es tu nivel de satisfacción con la plataforma de ventas de préstamos?',
    LABEL_QUESTION_2: '¿Qué mejorarías de la plataforma teniendo en cuenta tu uso cotidiano?',
    LABEL_ANSWER_2: '',
  },

  StepHeader: {
    title: 'Datos de la solicitud',
    idCredit: 'Número de crédito: <b>${0}<b/>',
    product: 'Producto: <b>${0}</b>',
    customerName: 'Nombre y apellido: <b>${0}</b>',
    customerTaxId: 'CUIT/CUIL: <b>${0}</b>',
    requestedAmount: 'Solicitado a liquidar: <b>${0}</b>',
    cancelAmount: 'Cancelando: <b>${0}</b>',
    paymentPlan: 'Plan de pago: <b>${0} cuotas de ${1}</b>',
    vehicle: 'Vehículo: <b>${0} (${1})</b>',
    advanceAmount: 'Monto anticipo: <b>${0}</b>',
  },

  StepsLoanRequest: [
    'Datos de la persona',
    'Oferta',
    'Carga de datos',
    'Carga de documentación',
    'Firma de contrato',
  ],

  InstallmentCard: {
    installments: '${0} cuotas',
    cadecom: 'Beneficio CADECOM',
    discount: '<b>${0}%</b> más barato',
  },

  CustomerData: {
    comboBoxDefaulOption: 'Seleccione una opción',
    validateEmail: 'Validar email',
    validateCellphone: 'Validar celular',
    verificationCode: 'Código',
    verificationCodeNotVerified: 'Sin verificar',
    verificationCodeVerified: 'Verificado OK',
    verificationCodeIncorrect: 'Código incorrecto',
    sendCodeError: 'No se pudo enviar el código',
    reviewInputEmail: 'Revisá el mail ingresado y validalo nuevamente.',
    verificationCodeError: 'Error al validar el código',
    countdownSms: 'Se envió el código al cliente por SMS. <br/>Ingreselo a continuación. <br/>(Aguarde ${0} segundos para reenviar)',
    countdownEmail: 'Se envió el código al cliente por email. <br/>Ingreselo a continuación. <br/>(Aguarde ${0} segundos para reenviar)',
    dcNumber: 'Número de Tarjeta',
    dcName: 'Nombre y apellido',
    dcNameHint: 'Como aparece en la tarjeta',
    dcExpiration: 'Vencimiento',
    dcExpirationHint: 'MM/YY',
    dcCvv: 'Código de seg.',
    dcCvvHint: 'Los 3 dígitos del dorso',
    ctaValidateCard: 'Validar tarjeta',
    ctaValidateCardAndCollectAdvance: 'Validar tarjeta y cobrar anticipo',
    debitCardValidationInput: 'Por favor completá correctamente los campos correspondientes a la tarjeta indicados en rojo para poder validarla',
    debitCardValidated: 'Validamos exitosamente tu tarjeta de débito.',
    debitCardRegistrated: 'Registramos correctamente tu tarjeta de débito.',
    debitCardValidationCode1: 'No pudimos registrar los datos de tu tarjeta, aún así podés continuar con la solicitud. En el próximo paso te pediremos una foto de la misma.',
    debitCardValidationCode3: 'Esta tarjeta de débito ya fue utilizada para otra persona, utilice otra tarjeta  o solicite asistencia para continuar.',
    debitCardValidationCode5: 'Superaste la cantidad de intentos. Por favor probá con otra tarjeta.',
    debitCardValidationCodeUnknown: 'No pudimos validar tu tarjeta. Por favor revisa los datos cargados y volvé a intentarlo hasta poder validarla. De lo contrario, podes probar con otra tarjeta.',
    debitCardValidationError: 'No pudimos validar tu tarjeta porque el servicio está inactivo. Por favor volvé a intentarlo en unos minutos.',
    debitCardAlreadyValidated: 'La tarjeta ${0} cargada anteriormente fue validada correctamente',
    debitCardAlreadyLoadButInvalid: 'No pudimos registrar los datos de la tarjeta cargada anteriormente, aún así podés continuar con la solicitud. En el próximo paso te pediremos un foto de la misma.',
    debitCardDownPaymentInfo: [
      'Información para el cliente',
      'Se cobrarán en este momento ${0} en concepto de anticipo en la tarjeta de débito. Debe tener esos fondos en su cuenta para poder avanzar con el préstamo.',
    ],
    debitCardPaymentInfo: [
      'Información para el cliente',
      'Vamos a cobrar $35 para poder validar que la tarjeta está activa y usaremos este medio para debitar la cuota de todos los meses.',
    ],
    debitCardInputTitle: 'Ingresá los datos de la tarjeta',
    debitCardDownPaymentValidated: 'Validamos tu tarjeta y realizamos el adelanto exitosamente.',
    debitCardDownPaymentCode1: 'Detectamos un problema en el servicio de cobro de anticipos.',
    debitCardDownPaymentCode2or3: 'Las condiciones del préstamo no permiten cobrar un anticipo.',
    debitCardDownPaymentCode4: 'Ya se cobró un anticipo para este crédito.',
    debitCardDownPaymentCode5: 'La tarjeta utilizada para cobrar está asociada a otro cliente de Credicuotas.',
    debitCardDownPaymentCode6: 'El cobro del anticipo fue rechazado por la procesadora de pagos, por favor revise si los datos de tarjeta de débito están bien, si tiene fondos suficientes, si no ha superado su limite diario o si la tarjeta se encuentra bloqueada.',
    debitCardDownPaymentCode7: 'Hubo un error con el cobro del anticipo por parte de la procesadora de pagos, por favor revise si los datos de la tarjeta de débito están bien, si tiene fondos suficientes y si no ha superado su limite diario o si la tarjeta se encuentra bloqueada.',
    debitCardDownPaymentCode8: 'Tu tarjeta no tiene fondos suficientes. Por favor, probá con otra tarjeta.',
    cta: 'Continuar',
    disbursementPrePaidCardMailRequired: 'El email es obligatorio para desembolso en tarjeta prepaga',
    disbursementPrePaidCardZipCodeRequired: 'El codigo postal es obligatorio para desembolso en tarjeta prepaga',
    debitCardInputRequired: 'Los datos de la tarjeta son obligatorios',
    requiredEmptyFields: 'Completá los campos marcados en rojo',
    disbursementMode: {
      inputCBU: 'Escribí el CBU (22 dígitos) o ALIAS donde recibís tu sueldo/ingresos',
      inputCBUHintAction: 'HACÉ CLICK ACÁ PARA VER LA LISTA DE TUS CUENTAS',
      comboCBU: 'Seleccioná Cuenta donde recibís tu sueldo/ingreso',
      comboCBUHintAction: 'HACÉ CLICK ACÁ PARA INGRESAR UN CBU O ALIAS MANUALMENTE',
    },
  },

  DocumentUploadPage: {
    title: 'Cargá la documentación',
    description: 'Elegí cómo cargar la documentación del cliente.',
    selectOption: 'Elegí una opción*',
    tabOptions: [
      'Enviar link de validación al cliente',
      'Validar al cliente desde acá',
    ],
    tab1: {
      description: 'Cargá el celular del cliente para que continue con su validación y finalice el proceso del préstamo.',
      resendDescription: 'En caso de no haber recibido el link, volvé a ingresar el número de celular y reenviá el link.',
      countdown: 'Vas a poder reenviar el link en ${0} segundos.',
      steps: [
        { icon: '/images/step-sms.svg', description: 'Abre el link en su celular' },
        { icon: '/images/step-doc.svg', description: 'Sube la documentación' },
        { icon: '/images/step-identity.svg', description: 'Valida su identidad' },
        { icon: '/images/step-sign.svg', description: 'Firma el contrato' },
      ],
      cellphoneInput: 'Celular',
      cellphoneInputHint: 'Sin el 0 ni el 15',
      sendMode: 'Método de envío',
      modeOptions: [
        { id: '1', label: 'SMS' },
        { id: '2', label: 'Whatsapp' },
      ],
      ctaSendLink: 'Enviar link',
      ctaResendSendLink: 'Reenviar link',
      loadDocumentsByCustomer: [
        'Información importante',
        'Esta solicitud se esta finalizando desde un celular. Cuando el cliente haya firmado el contrato clickeá <b>"Actualizar"</b> para visualizar la pantalla final.',
      ],
      refresh: 'Actualizar',
    },
    tab2: {
      qrOption: 'Escaneá el código QR con tu celular para continuar con la carga de la documentación del cliente.',
      linkOption: 'Enviar un link a tu celular para continuar con la carga de documentación del cliente.',
      continueHereUAT: 'Continuar acá (Solo UAT)',
      linkOptionDescription: 'Cargá el número de tu celular para continuar con la validación y finalizar el proceso del préstamo.',
      cellphoneInput: 'Celular del vendedor',
      cellphoneInputHint: 'Sin el 0 ni el 15',
      sendMode: 'Método de envío',
      modeOptions: [
        { id: '1', label: 'SMS' },
        { id: '2', label: 'Whatsapp' },
      ],
      ctaSendLink: 'Enviar link',
      messageLinkSent: 'El mensaje fue enviado exitosamente',
    },
    uploadDocumentTitle: 'Tenés que subir los siguientes archivos para terminar la solicitud.',
    uploadImageButton: 'Subir foto',
    retryImageButton: 'Reintentar foto',
    continueCTA: 'Continuar',
    backCTA: 'Atrás',
    biometricTitle: 'Tenés que hacer una foto del rostro',
    biometricTips: [
      'Buscá un lugar bien iluminado.',
      'Colocá tu dispositivo a la altura de la cara.',
      'Centrá la cara en el lugar indicado.',
    ],
    biometricRear: 'Cámara trasera',
    biometricFront: 'Cámara frontal',
    RQDNIfrente: 'Subí una foto del frente del DNI',
    RQDNIdorso: 'Subí una foto del dorso del DNI',
    imageLoadedTitle: 'Revisá que todo esté bien',
    imageLoadedDescription: 'Antes de subir la foto revisá que todo se vea bien iluminado y enfocado.',
    imageToLoadDescription: 'Asegurate que esté bien iluminada y enfocada.',
    back: 'Atrás',
  },

  BiometricResultPage: {
    title: 'Solicitud anulada',
    description: [
      'Esta solicitud fue anulada de forma automática.',
      'El cliente no ha superado la validación de identidad.',
    ],
    tipsTitle: 'Volvé a intentarlo teniendo en cuentas estas recomendaciones:',
    tips: [
      { img: '/images/biometria-tip-1.svg', description: 'Asegurate de haber cargado el DNI del cliente.' },
      { img: '/images/biometria-tip-2.svg', description: 'Los datos cargados deben corresponder a la persona que realiza la validación de identidad.' },
      { img: '/images/biometria-tip-3.svg', description: 'Solicitá al cliente que no utilice anteojos, barbijo o cualquier cosa que tape su cara.' },
    ],
    date: 'Fecha: <b>${0}</b>',
    name: 'Nombre: <b>${0}</b>',
    dni: 'DNI: <b>${0}</b>',
    amount: 'Monto solicitado',
    numberOfInstallments: 'Cantidad de cuotas',
    installmentAmount: 'Monto cuota',
    back: 'Ir al inicio',
  },

  EditUserModal: {
    title: 'Editá los datos del perfil',
    inputBranch: 'Sucursal',
    inputProfile: 'Perfil',
    inputName: 'Nombre',
    inputSurname: 'Apellido',
    inputIdentifier: 'Nro. de DNI',
    inputIdentifierHint: 'Sin espacios ni puntos',
    inputPhone: 'Celular',
    inputPhoneHint: 'Sin el 0 ni el 15',
    inputEmail: 'Email',
    primaryButton: 'Guardar cambios',
    secondaryButton: 'Cancelar',
    roles: [
      { id: ROLE_SELLER, label: 'Vendedor' },
      { id: ROLE_MANAGER, label: 'Admin' },
    ],
  },
  premiumCustomerModal: {
    title: 'Cliente premium',
    subtitle: 'La tasa y las cuotas son más bajas',
  },
  ManageBranchesPage: {
    title: 'Gestionar sucursales',
    description: 'Podés agregar perfiles en la sucursal.',
    sectionTitles: [
      'Elegí la sucursal y el tipo de nuevo perfil',
      'Completá los datos del nuevo perfil',
      'Perfiles agregados recientemente',
    ],
    inputBranch: 'Sucursal',
    inputProfile: 'Perfil',
    inputName: 'Nombre',
    inputSurname: 'Apellido',
    inputIdentifier: 'Nro. de DNI',
    inputIdentifierHint: 'Sin espacios ni puntos',
    inputPhone: 'Celular',
    inputPhoneHint: 'Sin el 0 ni el 15',
    inputEmail: 'Email',
    cta: 'Agregar vendedor',
    roles: [
      { id: ROLE_SELLER, label: 'Vendedor' },
      { id: ROLE_MANAGER, label: 'Admin' },
    ],
    tableHeaders: [
      'Nombre y apellido',
      'DNI',
      'Celular',
      'Email',
      'Perfil',
      'Sucursal',
      'Estado',
    ],
    emptySellerList: [
      'Todavía no agregaste perfiles',
      'Cargá perfiles para poder visualizarlos.',
    ],
    ctaEdit: 'Editar',
    ctaCancel: 'Cancelar',
    userCreated: 'Usuario creado correctamente',
    userEdited: 'Usuario editado correctamente',
    userDeleted: 'Usuario eliminado correctamente',
  },

  SignaturePage: {
    title: 'Firma de contrato',
    description: 'Para continuar tenés que firmar con tu dedo dentro del recuadro. Esta firma será incluida en el contrato.',
    sendAgreementTitle: 'Enviar contrato al cliente',
    sendAgreementDescription: 'Enviá el link del contrato al celular del cliente.',
    sendAgreementCTA: 'Enviar contrato',
    cellphoneInput: 'Celular',
    cellphoneInputHint: 'Sin el 0 ni el 15',
    agreementSentMessage: 'Se envio el contrato por SMS al cliente',
    repeatSignature: 'Repetir firma',
    signPlaceholder: 'Hace click para firmar',
    signCTA: 'Confirmar',
  },

  LoanRequestsPage: {
    title: 'Solicitudes',
    filter: 'Filtrar solicitudes',
    filterLabel: 'Seleccioná un filtro*',
    filterOptions: [
      { id: 'none', label: 'Selecciona un campo para filtrar' },
      { id: '0', label: 'Documento de identidad' },
      { id: '1', label: 'Número de crédito' },
      { id: '2', label: 'Más filtros' },
    ],
    filterCTA: 'Filtrar',
    noFilter: [
      'Todavía no agregaste ningún filtro',
      'Seleccioná un filtro para poder visualizar información.',
    ],
    noResult: [
      'No hay resultados para mostrar',
      'Verificá los datos ingresados y reintentá.',
    ],
    dniInput: 'Ingresá el DNI',
    loanInput: 'Ingresá el número de crédito',
    statusList: 'Estado',
    sellerList: 'Vendedor',
    branchList: 'Sucursal',
    dateFrom: 'Fecha inicial',
    dateTo: 'Fecha final',
    branchListError: 'Error al recuperar las sucursales',
    sellerListError: 'Error al recuperar los vendedores',
    filterError: 'Error al recuperar las solicitudes',
    sendOfferCTA: 'Enviar oferta al cliente',
    downloadLoanRequestsCTA: 'Descargar Excel',
    tableHeaders: [
      'Fecha de alta',
      'DNI',
      'Nombre',
      'ID Crédito',
      'Producto',
      'Monto',
      'Estado',
    ],
    actions: [
      'Retomar solicitud',
      'Chatear con Whatsapp Web',
      'Descargar documentación',
      'Cuponera de pagos',
      'Estado de cuenta',
      'Enviar cuponera de pago por SMS',
      'Enviar recordatorio con la oferta actual',
      'Generar venta a distancia',
    ],
    filters: {
      description: 'Estás viendo:',
      doc: 'DNI',
      idLoan: 'Crédito',
      dateFrom: 'Fecha inicial',
      dateTo: 'Fecha final',
      status: 'Estado',
      branchIdList: 'Sucursal',
      sellerList: 'Vendedor',
      delete: 'Borrar filtros',
    },
    expireIn: 'Expira ${0}',
    branch: 'Sucursal: <b>${0}</b>',
    seller: 'Vendedor: <b>${0}</b>',
    cellphone: 'Celular: <b>${0}</b>',
    email: 'Mail: <b>${0}</b>',
    orderId: 'Nro de orden ecommerce: <b>${0}</b>',
    offer: 'Oferta: <b>${0}</b>',
    requestedAmount: 'Monto solicitado: <b>${0}</b>',
    installments: 'Cantidad de cuotas: <b>${0}</b>',
    installmentAmount: 'Monto de cuota: <b>${0}</b>',
    hasNotDocumentsCompleted: 'Documentación <span style="color: red" }}>(Incompleta)</span>',
    earlyCancellation: 'Cancelacion anticipada: <b>${0}</b>',
    notEditable: 'No se permite trabajar con solicitudes de otras sucursales',
    continueLoanError: 'Error al abrir la solicitud',
    expiredLoan: 'Existe una solicitud posterior en progreso. Por favor genere una nueva.',
    loanPaymentLink: 'La solicitud tiene un link de pago generado.',
    paymentVoucherError: 'Ocurrió un error al generar cuponera de pago',
    accountStatusError: 'Ocurrió un error al generar estado de cuenta',
    paymentVoucherSent: 'Se envio la cuponera de pago por SMS al cliente',
    physicalFile: 'Legajo físico: ${0}',
    physicalFileDate: 'Recibido el ${0}',
    physicalFileDatePending: 'Pendiente',
    isPapperless: 'Solicitud libre de papeles',
  },

  DistanceSelling: {
    title: 'Generar venta a distancia',
    description: 'Estás por generar un link de venta a distancia para enviárselo al cliente. Cuando ingrese podrá cargar la documentación y completar la solicitud desde cualquier dispositivo.',
    requirementTitle: 'Documentos requeridos:',
    requirements: [
      'DNI',
      'Selfie',
      'Firma',
    ],
    amount: 'Monto del préstamo',
    productDescription: 'Descripción del producto',
    cta: 'Generar',
    amountError: 'El monto ingresado es mayor al máximo disponible',
    loanRequestNoApply: 'No se puede generar un link para esta solicitud',
    generatedTitle: 'Generaste la venta a distancia',
    generatedDescription: 'Compartile el link al cliente para que pueda completar la solicitud y hacer efectiva la compra.',
    loanAmount: 'Monto del préstamo: ${0}',
    edit: 'Editar',
    send: 'Enviar',
    copy: 'Copiar',
    link: '<b>Link de venta a distancia:</b> ${0}',
    close: 'Cerrar',
    back: 'Volver',
    cancel: 'Cancelar',
    linkSent: 'SMS enviado correctamente',
    linkCopied: 'Link copiado',
  },

  LoanRequestSendOfferModal: {
    title: 'Enviar recordatorio de oferta',
    description: [
      'Se enviará un recordatorio de oferta al cliente <span style="text-transform: capitalize"><b>${0}</b></span>. ¿Está seguro que desea continuar con esta operación?',
      'Se enviarán los recordatorios de oferta a <b>${0} clientes</b>. ¿Está seguro que desea continuar con esta operación?',
    ],
    cta: 'Enviar',
    sent: [
      'El recordatorio está siendo procesado para enviarse a la brevedad',
      'Los recordatorios están siendo procesados para enviarse a la brevedad',
    ],
    cancel: 'Cancelar',
    successTitle: 'Envío en proceso',
    successDescription: 'El recordatorio está siendo procesado para enviarse a la brevedad.',
    close: 'Cerrar',
  },

  RedirectPage: {
    expiredLoan: 'Solicitud expirada',
    unknownLoan: 'No se pudo reconocer su solicitud',
    notExistLoan: 'No existe la solicitud',
  },

  SettlementsPage: {
    title: 'Liquidaciones',
    tabs: [
      'Liquidaciones realizadas',
      'Créditos liquidados',
    ],
    getSettlementsError: 'Error al recuperar las liquidaciones',
    settlementsCompletedTableHeaders: [
      'Id lote',
      'Fecha de liquidación',
      'Método de liquidación',
      'Total liquidado',
      'Detalle',
    ],
    downloadSettlementError: 'Error al abrir ventana con detalle del lote',
    dateFrom: 'Fecha alta desde',
    dateTo: 'Fecha alta hasta',
    dniInput: 'Ingresá el documento',
    loanInput: 'Ingresá el número de crédito',
    searchCTA: 'Buscar',
    downloanCTA: 'Descargar Excel',
    searchError: 'Error al recuperar los créditos',
    loanListTableHeaders: [
      'DNI',
      'Crédito número',
      'Estado',
      'Nombre',
      'Fecha alta',
      'Fecha activo',
      'Total liquidado',
      'Métodode pago',
      'Anular',
    ],
    exportError: 'Error al exportar',
    Dialog: {
      title: '¿Anular préstamo?',
      description: 'Se enviará una solicitud para anular el siguiente crédito:',
      loan: 'Préstamo: <b>N°${0}</b>',
      customerName: 'Nombre del cliente: <b>${0}</b>',
      customerId: 'DNI: <b>${0}</b>',
      amount: 'Monto: <b>${0}</b>',
      cancelCTA: 'Cancelar',
      confirmCTA: 'Anular',
      successTitle: 'Solicitud enviada',
      successDescription: 'Registramos el pedido de anulación del préstamo.',
      closeCTA: 'Cerrar',
    },
    cancelAlreadyError: 'El pedido ya fue ejecutado anteriormente',
    cancelError: 'Error al anular el crédito',
    prevPage: 'Anterior',
    nextPage: 'Siguiente',
  },

  HomePage: {
    callToActions: {
      newLoanRequest: 'Nueva solicitud',
      loanRequests: 'Solicitudes',
      renewers: 'Renovadores',
      getRenewersError: 'Error al recuperar los renovadores',
    },
    calculator: {
      title: 'Calculadora de oferta',
      subtitle: 'El valor que se calcule es un valor simulado y es una aproximación al real.',
      productSection: {
        title: 'Elegí el producto y sub producto',
        product: {
          label: 'Producto',
          helper: 'Seleccionar producto',
        },
        subProduct: {
          label: 'Sub Producto',
          helper: 'Seleccionar sub producto',
        },
      },
      amountSection: {
        title: 'Ingresá el importe',
        subtitle: 'ingresá el importe que el cliente quiera financiar para obtener un plan de cuotas aproximado.',
        amountInputLabel: 'Importe',
        calculateCTA: 'Calcular',
        vehiclePrice: 'Máximo prestable: ${0}',
      },
      brandAndModelRequired: 'Debes seleccionar marca y modelo',
      requiredAmountGreaterThanVehiclePrice: 'El monto ingresado debe ser menor o igual al maximo permitido',
    },
    commercialPolicies: {
      title: 'Políticas comerciales',
      subtitle: 'Acá podes ver todas las políticas comerciales.',
      items: [
        'El cliente debe presentar el DNI original en formato físico.',
        'Debe presentar el DNI vigente (el último ejemplar).',
        'No aceptamos DNI extrajero con residencia temporal.',
        'El cliente debe ser mayor de edad, de 21 a 80 años.',
        'Es obligatorio que el titular reconozca el préstamos en la llamada de bienvenida o validación por Whatsapp.',
        'El titular del préstamo debe tener las facultades necesarias de salud y educación para leer, comprender y firmar un contrato.',
        'No aceptamos casos con patologías neurológicas, problemas graves visuales que afecten su lectura y comprensión del contrato, o analfabetismo.',
        'Aceptamos financiacion compartida solo con tarjetas de crédito. No aceptamos financiacion compartida con otras financieras que operan en el comercio.',
      ],
    },
    clientService: {
      title: 'Centro de atención al cliente',
      subtitle: 'Podés comunicarte de <b>Lunes a Viernes de 9:00 hs a 18:00 hs.</b>',
      whatsappNumber: '+54 11 6457 5091',
      telephoneNumber: '0800 888 2733',
    },
    generatingRenewerReport: 'Espere por favor estamos generando el reporte',
    generatingRenewerReportError: 'Error al descargar el reporte de renovadores',
  },

  InstallAppPage: {
    title: '¡Ahora vender es más fácil!',
    description: [
      'Escaneá el código QR para instalar la app de comercios.',
      'Instalá la app y hacé todo desde tu celular.',
    ],
    installing: 'Instalando App..',
    cta: [
      'Descargar',
      'En otro momento',
      'Continuar desde la App',
    ],
  },

  ErrorHandler: {
    message: 'Ocurrió un error',
  },

  PushNotificationSubscription: {
    title: 'Activá las notificaciones',
    description: 'Podrás recibir todas las novedades sobre tus ventas y operaciones, y tambien de nuestras promos!',
    cta: [
      'Activar notificaciones',
      'En otro momento',
    ],
  },

  BiometricIncompatibleBrowser: {
    title: 'Tu navegador no es compatible',
    description: [
      'Si tu dispositivo es <b>Android, abrí Google Chrome.</b><br />Si estás en un dispoitivo de <b>Apple, abrí Safari.</b>',
      'Podés copiar el siguiente link y pegarlo en el navegador que vayas a utilizar para continuar con la solicitud del crédito.',
    ],
    linkCopied: 'Link copiado al portapapeles.',
    cta: [
      'Copiar',
      'Cancelar',
    ],
  },

  BiometricDisableCamera: {
    title: 'Habilitá el acceso a tu cámara',
    description: [
      'Necesitamos que le des permiso a la cámara para poder tomar las fotos.',
      'Entrá en la Configuración del navegador, buscá los permisos del sitio de comercios credicuotas y seleccioná Permitir Cámara.',
    ],
    cta: 'Cancelar',
  },

  // loading svg
  loadingSVG1: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG2: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG3: 'M55.2 46.9c-.4-.8-.9-1.4-1.6-1.9-.7-.5-1.5-.9-2.3-1.2-.9-.3-1.8-.5-2.7-.6l-3.8-.5c-.9-.1-1.6-.3-2-.5-.4-.2-.7-.5-.7-1s.2-.9.7-1.2c.5-.3 1.4-.4 2.6-.4 1.1 0 2.1.1 3 .3.9.2 1.6.4 2.3.6.6.2 1.1.4 1.5.6.4.2.6.4.7.5.1 0 .2-.1.4-.3.2-.1.4-.3.7-.6l.6-.9c.2-.3.3-.7.3-1.2 0-.6-.2-1.1-.5-1.5-.3-.4-.7-.7-1.3-1-.9-.5-2-.8-3.3-1-.4-.1-.8-.1-1.3-.2V34c0-.9-.8-1.7-1.7-1.7h-3c-.9 0-1.7.8-1.7 1.7v1c-.5.1-.9.2-1.3.3-1.3.4-2.3.9-3.1 1.5-.8.7-1.4 1.4-1.8 2.3-.4.9-.6 1.8-.6 2.9 0 1.2.2 2.2.7 3.1.4.8 1 1.5 1.8 2s1.7.9 2.7 1.2c1 .3 2 .5 3.1.6l2.2.2c1 .1 1.8.3 2.3.5.5.2.7.6.7 1.2 0 .5-.3.9-.9 1.1-.6.2-1.5.3-2.6.3-1 0-2-.1-2.9-.2-.9-.1-1.7-.3-2.4-.5s-1.3-.4-1.8-.7c-.5-.2-.9-.4-1.1-.6l-2.4 4.3c.2.2.6.4 1.2.7.6.3 1.3.6 2.2.9.9.3 2 .5 3.3.8.2 0 .4.1.7.1v.9c0 .9.8 1.7 1.7 1.7h3c.9 0 1.7-.8 1.7-1.7V57c1.9-.3 3.5-.9 4.7-1.8 1.8-1.3 2.7-3.1 2.7-5.5 0-1.1-.3-2.1-.7-2.8z',
};

export default i18n;
