import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useSplashController from '../../hooks/controllers/useSplashController';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    display: 'block',
    maxWidth: '50%',
    maxHeight: '100%',
    clipPath: 'inset(0 50% 0 50%)',
    transition: 'clip-path 1s ease-in-out',
    '&.expanded': {
      clipPath: 'inset(0 0 0 0)',
    },
  },
}));

const SplashPage = () => {
  const classes = useStyles();
  const { expanded, onAnimationEnd, fadeOut } = useSplashController();

  return (
    <>
      <Box className={classes.container}>
        <img
          src="/images/splash.png"
          alt="Imagen"
          className={
            `${classes.image} ${expanded ? 'expanded' : ''} ${fadeOut ? 'animate__animated animate__fadeOut' : ''}`
          }
          onTransitionEnd={onAnimationEnd}
        />
      </Box>
    </>
  );
};

export default SplashPage;
