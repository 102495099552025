import { createContext, useContext, useState } from 'react';
import { usePWAInstall } from 'react-use-pwa-install';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useLocalStorage } from '@uidotdev/usehooks';
import { INCENTIVE_INSTALL_APP_NEXT_DATE } from '../constants/localStorageVariablesConstants';

const InstallAppContext = createContext();

export const useInstallApp = () => useContext(InstallAppContext);

export const InstallAppProvider = ({ children }) => {
  const install = usePWAInstall();
  const [installing, setInstalling] = useState(false);
  const [installed, setInstalled] = useState(false);
  const [incentiveInstallAppNextDate, setIncentiveInstallAppNextDate] = useLocalStorage(INCENTIVE_INSTALL_APP_NEXT_DATE);

  const omit = () => {
    setIncentiveInstallAppNextDate(new Date(dayjs().add(7, 'days')));
  };

  const doInstall = async () => {
    try {
      const accept = await install();
      if (accept) {
        window.dataLayer.push({ event: 'instalar_app' });
        setInstalling(true);
        setTimeout(() => {
          setInstalling(false);
          setInstalled(true);
        }, 4000);
      }
    } catch (error) {
      setInstalling(false);
    }
  };

  return (
    <InstallAppContext.Provider
      value={{
        install,
        incentiveInstallAppNextDate,
        omit,
        doInstall,
        installing,
        installed,
      }}
    >
      {children}
    </InstallAppContext.Provider>
  );
};

InstallAppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
