import { fetch, fetchMultipart, instance } from './axiosInstance';

export const resolveIdentification = (customerId) =>
  fetch({
    url: '/workflow/resolvecustomeridentification',
    method: 'get',
    params: {
      dni: customerId,
    },
  });

export const getContactOrigins = () =>
  fetch({
    url: '/workflow/contactorigins',
    method: 'get',
  });

export const checkExistingPenLoanRequest = (startWorkflowDto) =>
  fetch({
    url: '/workflow/checkExistingPreLoanRequest',
    method: 'post',
    data: startWorkflowDto,
  });

export const startWorkflow = (startWorkflowDto) =>
  fetch({
    url: '/workflow/start',
    method: 'post',
    data: startWorkflowDto,
  });

export const getLoanRequest = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}`,
    method: 'get',
  });

export const getLoanRequestWithDocumentData = (hash) =>
  fetch({
    url: `/workflow/loanRequestWithDocumentData/${hash}`,
    method: 'get',
  });

export const npsAlreadyAnswered = (nps) =>
  fetch({
    url: `/nps/${nps}/alreadyAnswered`,
    method: 'get',
  });

export const getNPS = (nps) =>
  fetch({
    url: `/nps/${nps}`,
    method: 'get',
  });

export const skipNPS = (nps, npsResult) =>
  fetch({
    url: `/nps/${nps}/skip`,
    method: 'post',
    data: npsResult,
  });

export const saveNPSResult = (nps, npsResult) =>
  fetch({
    url: `/nps/${nps}`,
    method: 'post',
    data: npsResult,
  });

export const getDocuments = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/documents`,
    method: 'get',
  });

export const getRequiredInputFields = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/requiredInputFields`,
    method: 'get',
  });

export const getUserInputFields = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/userinputs`,
    method: 'get',
  });

export const resolveConditionalComboData = (hash, varName, conditioningValue) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/userinput/combodata/${varName}/${conditioningValue}`,
    method: 'get',
  });

export const sendVerificationCode = (hash, varName, value) =>
  fetch({
    url: `/workflow/loanRequest/'${hash}'/userInputVerification/sendcode`,
    method: 'post',
    data: {
      userInputName: varName,
      userInputValue: value,
    },
  });

export const verifyCode = (hash, varName, code, value) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/userInputVerification/verifycode`,
    method: 'post',
    data: {
      userInputName: varName,
      userInputVerificationCode: code,
      userInputValue: value,
    },
  });

export const validateDebitCardDownPayment = (hash, debitCardDTO) =>
  fetch({
    url: `/workflow/checkDebitCardDownPayment/${hash}`,
    method: 'post',
    data: debitCardDTO,
  });

export const validateDebitCard = (hash, debitCardDTO) =>
  fetch({
    url: `/workflow/checkDebitCard/${hash}`,
    method: 'post',
    data: debitCardDTO,
  });

export const setCustomerData = (hash, customerData) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/customerdata`,
    method: 'post',
    data: {
      hashkey: hash,
      customerDataMap: customerData,
    },
  });

export const getQrMobileUrlBack = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/getQrMobileUrlBack?newOnboarding=true`,
    method: 'post',
  });

export const getVehicleParameterMinAmount = () =>
  fetch({
    url: '/workflow/vehicles/parameters/minAmount',
    method: 'get',
  });

export const sendSMSWithDisclaimerURL = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/sendSmsDisclaimerUrl`,
    method: 'post',
  });

export const signAgreement = (hash, signature) =>
  fetchMultipart({
    url: `/workflow/sign/${hash}`,
    method: 'post',
    data: {
      signLoanOwner: signature,
    },
  });

export const getUrlForUpload = (hash, currentDocumentName, file) =>
  fetchMultipart({
    url: `/workflow/loanRequest/${hash}/userupload/${currentDocumentName}?uploadMethod=WEB`,
    method: 'post',
    data: { docFile: file },
  });

export const sendMsgUploadDocuments = (hash, cellphone) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/sendmsguploaddocuments/${cellphone}?newOnboarding=true`,
    method: 'post',
  });

export const sendSMSUploadImages = (hash, cellphone) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/sendSMSUploadImages/${cellphone}?newOnboarding=true`,
    method: 'post',
  });

export const sendSmsContinueLinkToCustomer = (hash, cellphone) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/sendSmsContinueLinkToCustomer/${cellphone}`,
    method: 'post',
  });

export const sendWhatsappContinueLinkToCustomer = (hash, cellphone) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/sendWhatsappContinueLinkToCustomer/${cellphone}`,
    method: 'post',
  });

export const sendVoucher = (hash, cellphone) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/sendVoucher`,
    method: 'post',
    data: {
      cellNumber: cellphone,
      preCustomerData: {
        PtllaTelCelular: cellphone,
      },
    },
  });

export const getInstallmentsApi = (hash, reqAmount, cancelableLoanIdList) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/installments/${reqAmount}`,
    method: 'get',
    params: {
      cancelableLoanIdList,
    },
  });

export const getInstallmentsWithOriginalLine = (hash, reqAmount, lineId) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/installments/${reqAmount}/originalLine/${lineId}`,
    method: 'get',
  });

export const setInstallment = (hash, installmentSelected, vehicleInfo, cancelableLoanIdList) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/installments`,
    method: 'put',
    data: {
      installmentAmountSelected: installmentSelected.amount,
      installmentsNumberSelected: installmentSelected.installments,
      idFinanceLine: installmentSelected.idLinea,
      requestedAmount: installmentSelected.requestedAmount,
      vehicleInfo,
      dual: installmentSelected.isDual,
    },
    params: {
      cancelableLoanIdList,
    },
  });

export const getRedirectionByMinHash = (minHash) =>
  fetch({
    url: `/workflow/redirection/${minHash}`,
    method: 'get',
    headers: {
      ...instance.defaults.headers,
      minHashAuthorization: minHash,
    },
  });

export const getCalculatorParameters = () =>
  fetch({
    url: '/calculator/parameters',
    method: 'get',
  });

export const getInstallmentsForCalculator = (dto) =>
  fetch({
    url: `/calculator/installments/${dto.branchId}/${dto.subProductId}/${dto.amount}/${dto.term}/${dto.province}`,
    method: 'get',
  });

export const getMaxAmountLineDual = (hash) =>
  fetch({
    url: `/workflow/loanRequest/offer/${hash}/dual`,
    method: 'get',
  });

export const getInstallmentsDual = (hash, reqAmount, cancelableLoanIdList) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/installments/${reqAmount}/dual`,
    method: 'get',
    params: {
      cancelableLoanIdList,
    },
  });

export const getInstallmentsWithOriginalLineDual = (hash, reqAmount, lineId) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/installments/${reqAmount}/originalLine/${lineId}/dual`,
    method: 'get',
  });

export const calculateMaxAmountAvailable = (hash, cancelableLoanIdList) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/maxAmountCalculated`,
    method: 'get',
    params: {
      cancelableLoanIdList,
    },
  });

export const logClientError = (message) =>
  fetch({
    url: '/workflow/log-client-error',
    method: 'post',
    data: message,
  });
