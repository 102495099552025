import { fetch } from './axiosInstance';

export const getVehicleBrands = (vehicleType, newVehicles, usedVehicles) =>
  fetch({
    url: `/vehicles/${vehicleType}/brands`,
    method: 'get',
    params: {
      newVehicles,
      usedVehicles,
    },
  });

export const getVehicleModels = (vehicleType, brandId, newVehicles, usedVehicles) =>
  fetch({
    url: `/vehicles/${vehicleType}/brands/${brandId}/models`,
    method: 'get',
    params: {
      newVehicles,
      usedVehicles,
    },
  });

export const getVehiclePrice = (vehicleType, identifierId) =>
  fetch({
    url: `/vehicles/${vehicleType}/identifiers/${identifierId}/listPrice`,
    method: 'get',
  });

export const getVehiclePriceByYearApi = (vehicleType, identifierId) =>
  fetch({
    url: `/vehicles/${vehicleType}/identifiers/${identifierId}/yearsPrice`,
    method: 'get',
  });

export const getCarLoanToValuePercentageByYear = (hash) =>
  fetch({
    url: `/workflow/vehicles/parameters/car/loanToValuePerYear/${hash}`,
    method: 'get',
  });
