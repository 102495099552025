import { useEffect, useState } from 'react';
import { hasPermissionGrantedOrDenied, requestPermission } from '../utils/firebase';
import { useAuth } from '../contexts/AuthContext';
import { isPRDEnvironment } from '../utils/environmentUtils';

const usePushNotifications = () => {
  const { loggedUser, saveLoggedUser, getLoggedUserUserName } = useAuth();
  const [showSubscription, setShowSubscription] = useState(false);

  const init = async () => {
    try {
      // Por ahora apagado para PROD
      if (!isPRDEnvironment()) {
        if (!hasPermissionGrantedOrDenied() && !loggedUser.notShowPrompt) {
          setShowSubscription(true);
        }
      }
    } catch (error) {
      // No hacer nada
    }
  };

  const handleSubscribe = async () => {
    setShowSubscription(false);
    const accept = await requestPermission(getLoggedUserUserName());
    const user = { ...loggedUser, notShowPrompt: !accept };
    saveLoggedUser(user);
  };

  const handleOmit = () => {
    setShowSubscription(false);
    const user = { ...loggedUser, notShowPrompt: true };
    saveLoggedUser(user);
  };

  useEffect(() => { init(); }, []);

  return { showSubscription, handleSubscribe, handleOmit };
};

export default usePushNotifications;
