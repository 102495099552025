import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Grid, Tooltip, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { Button, Divider } from '@doit/pcnt-react-ui-library';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import i18n from '../../../common/i18n';
import InstallmentCard from './InstallmentCard';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter, capitalize } from '../../../utils/formatterUtils';
import useNavigation from '../../../hooks/useNavigation';
import FirstInstallmentBenefit from './FirstInstallmentBenefit';
import {
  hasFirstInstallmentBenefit, hasDiscount, getDiscountPercentage,
} from '../../../utils/installmentUtils';
import { useAuth } from '../../../contexts/AuthContext';
import { useToaster } from '../../../contexts/ToasterContext';

const InstallmentPlanList = ({
  installments, selectedInstallment, setSelectedInstallment, requestedAmount, advanceAmount, onSubmit, loading, calculator,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToQuotation } = useNavigation();
  const [firstInstallmentDate, setFirstInstallmenteDate] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const { showToast } = useToaster();
  const { loggedUser } = useAuth();

  const copyInstallmenPlan = () => {
    let plan = '';

    plan += fillMessageWith(i18n.InstallmentPlanPage.copyToClipboard.title, capitalize(loggedUser.principal.infoLogin.nombre));
    plan += fillMessageWith(i18n.InstallmentPlanPage.copyToClipboard.description,
      moneyFormatter(requestedAmount), loggedUser.principal.infoLogin.nombrecomercio);

    installments.forEach((installment) => {
      plan += fillMessageWith(i18n.InstallmentPlanPage.copyToClipboard.installment, installment.installments, moneyFormatter(installment.amount));
      if (hasDiscount(installment)) {
        plan += fillMessageWith(i18n.InstallmentPlanPage.copyToClipboard.installmentDiscount, getDiscountPercentage(installment));
      }
      plan += '\n';
    });

    copyToClipboard(plan);
    showToast(i18n.InstallmentPlanPage.copyToClipboard.planCopied, 'success');
  };

  const setDateUTFFirstInstallmentDueDateFromUNIX = () => {
    let date = new Date(installments[0].firstInstallmentDueDate);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    date = `08/${month}/${date.getUTCFullYear()}`;
    setFirstInstallmenteDate(date);
  };

  useEffect(() => {
    setDateUTFFirstInstallmentDueDateFromUNIX();
  });

  return (
    <>
      {/* DETALLE PLAN DE CUOTA */}
      <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
      {!calculator && (
        <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>{i18n.InstallmentPlanPage.offerDetail}</Typography>
      )}
      <Typography
        align="left"
        sx={{
          fontSize: '20px',
          fontWeight: 700,
          marginTop: '16px',
          marginBottom: calculator ? '8px' : '',
        }}
      >
        {fillMessageWith(i18n.InstallmentPlanPage.installmentPlanDetail, moneyFormatter(requestedAmount))}
      </Typography>
      {advanceAmount && (
        <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
          <Typography align="left" sx={{ fontSize: '16px' }}>
            {parse(fillMessageWith(i18n.InstallmentPlanPage.advancePaymentAmount, moneyFormatter(advanceAmount)))}
          </Typography>
          <Tooltip title={i18n.InstallmentPlanPage.advancepaymentHint}>
            <HelpOutline sx={{ marginLeft: '10px', color: '#424242' }} />
          </Tooltip>
        </Box>
      )}

      <Box sx={{ marginTop: '8px' }}>
        {!calculator && (
          <>
            <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px', marginBottom: '16px' }}>
              {fillMessageWith(
                i18n.InstallmentPlanPage.firstInstallmentExpirationDate,
                firstInstallmentDate,
              )}
            </Typography>
            {hasFirstInstallmentBenefit(installments) && (
              <FirstInstallmentBenefit amount={selectedInstallment?.firstInstallmentAmountWithDiscount} />
            )}
            <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '16px' }}>
              {i18n.InstallmentPlanPage.selectInstallmentPlan}
            </Typography>
          </>
        )}
        <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '16px' }}>
          <Button
            variant="secondary"
            onClick={copyInstallmenPlan}
            endIcon={<img src="/images/copy-icon-v2.svg" alt="copy" />}
          >
            {i18n.InstallmentPlanPage.copyToClipboard.ctaLabel}
          </Button>
        </Typography>
      </Box>

      {/* LISTADO DE CUOTAS */}
      <Grid container>
        {installments?.map((installment) => (
          <Grid item xs={6} md="auto" key={installment.installments} sx={{ padding: '8px 4px' }}>
            <InstallmentCard
              installment={installment}
              selected={installment.installments === selectedInstallment?.installments}
              onSelect={calculator ? undefined : setSelectedInstallment}
            />
          </Grid>
        ))}
      </Grid>
      {!calculator && (
        <Box sx={{ textAlign: 'right', marginTop: '52px' }}>
          {!isMobile && (
            <Button
              variant="tertiary"
              color="grey"
              onClick={goToQuotation}
              loading={loading}
              fullWidth={isMobile}
              sx={{ marginBottom: { xs: '24px', md: '0px' } }}
            >
              {i18n.InstallmentPlanPage.installmentPlanBack}
            </Button>
          )}
          <Button
            variant="primary"
            color="grey"
            disabled={!selectedInstallment}
            onClick={onSubmit}
            loading={loading}
            fullWidth={isMobile}
            sx={{ marginBottom: { xs: '24px', md: '0px' } }}
          >
            {i18n.InstallmentPlanPage.installmentPlanSelectedCTA}
          </Button>
          {isMobile && (
            <Button
              variant="tertiary"
              color="grey"
              onClick={goToQuotation}
              loading={loading}
              fullWidth={isMobile}
              sx={{ marginBottom: { xs: '24px', md: '0px' } }}
            >
              {i18n.InstallmentPlanPage.installmentPlanBack}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

InstallmentPlanList.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedInstallment: PropTypes.shape(),
  setSelectedInstallment: PropTypes.func.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  advanceAmount: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  calculator: PropTypes.bool,
};

InstallmentPlanList.defaultProps = {
  selectedInstallment: undefined,
  advanceAmount: undefined,
  loading: false,
  calculator: false,
};

export default InstallmentPlanList;
