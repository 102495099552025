import { Typography } from '@mui/material';
import { Alert } from '@doit/pcnt-react-ui-library';
import i18n from '../../../../common/i18n';

export const ImportantSuccessMessage = () => (
  <Alert severity="warning" style={{ textAlign: 'left' }}>
    <Typography>
      {i18n.FinalPage.Messages.warnIfPaperless}
    </Typography>
  </Alert>
);

export default ImportantSuccessMessage;
