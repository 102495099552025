import { useEffect } from 'react';
import {
  Grid, useMediaQuery, useTheme,
} from '@mui/material';
import Loading from '../../components/commons/Loading';
import useBiometric from '../../hooks/useBiometric';
import Card from '../../components/commons/Card';
import { DISABLE_CAMERA_STEP, INCOMPATIBLE_BROWSER_STEP } from '../../constants/biometricConstants';
import BiometricIncompatibleBrowser from './components/BiometricIncompatibleBrowser';
import BiometricNoHit from './components/BiometricNoHit';
import BiometricDisableCamera from './components/BiometricDisableCamera';

const BiometricResultPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const {
    loading, processCallbackAndGetResult, noHit, loanRequest, stepResult, loanRequestHash,
  } = useBiometric();

  const init = () => {
    processCallbackAndGetResult();
  };

  useEffect(init, []);

  const getContent = () => (
    <>
      {stepResult ? (
        <>
          {stepResult === DISABLE_CAMERA_STEP && (
            <BiometricDisableCamera hash={loanRequestHash} />
          )}
          {stepResult === INCOMPATIBLE_BROWSER_STEP && (
            <BiometricIncompatibleBrowser hash={loanRequestHash} />
          )}
        </>
      ) : (
        <BiometricNoHit loanRequest={loanRequest} />
      )}
    </>
  );

  if (loading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  if (noHit || stepResult) {
    return (
      <>
        {isMobile ? (
          <Grid container sx={{ backgroundColor: '#FFF' }}>
            <Grid item xs={12}>
              {getContent()}
            </Grid>
          </Grid>
        ) : (
          <>
            <Card sx={{ padding: '0px' }}>
              {getContent()}
            </Card>
          </>
        )}
      </>
    );
  }

  return null;
};

export default BiometricResultPage;
