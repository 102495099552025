export const LOGIN_CREDICUOTAS_LOGO = '/images/credicuotas-magenta.svg';
export const LOGIN_BACKGROUND = '/images/login-background.jpg';

export const MENU_ICON_EMPTY = '/images/menu-icono-vacio.png';
export const MENU_ICON_HOME = '/images/menu-icono-inicio.svg';
export const MENU_ICON_ACCOUNT = '/images/menu-icono-cuenta.svg';
export const MENU_ICON_NEW_LOAN = '/images/menu-icono-nueva-solicitud.svg';
export const MENU_ICON_RENEWERS = '/images/menu-icono-renovadores.svg';
export const MENU_ICON_LOANS = '/images/menu-icono-solicitudes.svg';
export const LOGOUT_ICON = '/images/logout-icon.svg';

export const REQUIRED_DOCUMENTS = {
  RQDNIfrente: '/images/dni_frente.svg',
  RQDNIdorso: '/images/dni_dorso.svg',
  RQFoto: '/images/selfie.svg',
  DebitCard: '/images/tarjeta_debito.svg',
  UploadDocument: '/images/upload_document.svg',
};
