import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import i18n from '../../common/i18n';
import { useToaster } from '../../contexts/ToasterContext';
import { useAuth } from '../../contexts/AuthContext';
import { calculatorSchemaValidation } from '../../forms/homeSchemaValidations';
import {
  VEHICLE_CAR_SUBPRODUCT_ID, VEHICLE_MOTO_SUBPRODUCT_ID,
} from '../../constants/commerceContants';
import {
  getVehicleBrands, getVehicleModels, getVehiclePrice, getVehiclePriceByYearApi,
} from '../../api/vehicleApiService';
import { getCalculatorParameters, getInstallmentsForCalculator } from '../../api/workflowApiService';
import useRenewerReport from '../useRenewerReport';
import { getApiErrorMessage } from '../../services/errorApiService';
import {
  getVehicleTypeBySubproductId, shouldOfferNewVehiclesBySubproductId,
  shouldOfferUsedVehiclesBySubproductId,
} from '../../utils/loanRequestUtils';

const useHomeController = () => {
  const { showToast } = useToaster();
  const {
    getLoggedUserProductList,
    getLoggedUserBranchId,
    getLoggedUserProvince,
  } = useAuth();
  const { generateReport } = useRenewerReport();

  const [loading, setLoading] = useState(true);

  const [calculatorPercentage, setCalculatorPercentage] = useState();
  const [loadingCalculate, setLoadingCalculate] = useState(false);
  const [productList, setProductList] = useState();
  const [subproductList, setSubproductList] = useState([]);
  const [termList, setTermList] = useState();

  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [year, setYear] = useState();
  const [vehicleModelLoading, setVehicleModelLoading] = useState();
  const [vehiclePrice, setVehiclePrice] = useState();
  const [vehicleYearsList, setVehicleYearsList] = useState();

  const [installmentList, setInstallmentList] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();

  const [subproductSelected, setSubproductSelected] = useState();

  const methods = useForm({
    resolver: yupResolver(calculatorSchemaValidation),
  });

  const onSelectProduct = (productId, calcMethods) => {
    const subproductListAux = productList.find((productAux) => productId === productAux.idProducto)?.subproductos || [];
    setSubproductList(subproductListAux);
    calcMethods.resetField('subproduct', { defaultValue: 'none' });
    setTermList(undefined);
    setBrandList([]);
    setModelList([]);
    setBrand('none');
    setModel('none');
    setYear('none');
    setVehiclePrice(undefined);
    setVehicleYearsList(undefined);
  };

  const onSelectSubproduct = async (subproductId, calcMethods) => {
    const termsListAux = subproductList.find((subproductAux) => subproductId === subproductAux.idSubProducto)?.plazoParaPago || [];
    if (termsListAux.length === 1) {
      calcMethods.resetField('terms', { defaultValue: termsListAux[0] });
    }
    setTermList(termsListAux);
    setSubproductSelected(subproductId);
    setBrandList([]);
    setModelList([]);
    setBrand('none');
    setModel('none');
    setYear('none');
    setVehiclePrice(undefined);
    setVehicleYearsList(undefined);
    if (subproductId === VEHICLE_MOTO_SUBPRODUCT_ID || subproductId === VEHICLE_CAR_SUBPRODUCT_ID) {
      try {
        const vehicleType = getVehicleTypeBySubproductId(subproductId);
        const newVehicles = shouldOfferNewVehiclesBySubproductId(subproductId);
        const usedVehicles = shouldOfferUsedVehiclesBySubproductId(subproductId);
        const { data: brandListAux } = await getVehicleBrands(vehicleType, newVehicles, usedVehicles);
        setBrandList(brandListAux);
      } catch (error) {
        showToast(i18n.InstallmentPlanPage.vehicleSelection.brandListError, 'error');
      }
    }
  };

  const handleCalculate = async (data) => {
    try {
      setLoadingCalculate(true);
      if (Number(data.subproduct) === VEHICLE_MOTO_SUBPRODUCT_ID || Number(data.subproduct) === VEHICLE_CAR_SUBPRODUCT_ID) {
        if (!brand || !model) {
          showToast(i18n.HomePage.calculator.brandAndModelRequired, 'info');
          return;
        }
        if (data.amount > vehiclePrice) {
          showToast(i18n.HomePage.calculator.requiredAmountGreaterThanVehiclePrice, 'info');
          return;
        }
      }

      const dto = {
        amount: data.amount,
        branchId: getLoggedUserBranchId(),
        subProductId: data.subproduct,
        term: data.terms,
        province: getLoggedUserProvince(),
      };

      const { data: installmentListAux } = await getInstallmentsForCalculator(dto);
      setInstallmentList(installmentListAux);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setLoadingCalculate(false);
    }
  };

  const downloadRenewers = async () => {
    generateReport();
  };

  const getModelsByBrand = async (brandId) => {
    try {
      setVehicleModelLoading(true);
      const vehicleType = getVehicleTypeBySubproductId(subproductSelected);
      const newVehicles = shouldOfferNewVehiclesBySubproductId(subproductSelected);
      const usedVehicles = shouldOfferUsedVehiclesBySubproductId(subproductSelected);
      const { data: modelListAux } = await getVehicleModels(vehicleType, brandId, newVehicles, usedVehicles);
      setModelList(modelListAux);
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.modelListError, 'error');
    } finally {
      setVehicleModelLoading(false);
    }
  };

  const onChangeBrand = (e) => {
    const brandId = e.target.value;
    setBrand(brandId);
    setModelList([]);
    setModel('none');
    setYear('none');
    setVehiclePrice(undefined);
    setVehicleYearsList(undefined);
    if (brandId !== 'none') {
      getModelsByBrand(brandId);
    }
  };

  const getVehicleListPrice = async (modelId) => {
    try {
      const res = await getVehiclePrice(getVehicleTypeBySubproductId(subproductSelected), modelId);
      if (res.data.list_price) {
        setVehiclePrice(res.data.list_price * calculatorPercentage);
      }
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceError, 'error');
    }
  };

  const getVehiclePriceByYear = async (modelId) => {
    try {
      const res = await getVehiclePriceByYearApi(getVehicleTypeBySubproductId(subproductSelected), modelId);
      if (res.data.length > 0) {
        setVehicleYearsList(res.data);
        setYear('none');
      } else {
        showToast(i18n.InstallmentPlanPage.vehicleSelection.priceYearError, 'error');
      }
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceYearError, 'error');
    }
  };

  const getVehicleListPriceAndYears = async (modelId) => {
    try {
      const vehicleType = getVehicleTypeBySubproductId(subproductSelected);
      const resVehiclePrice = await getVehiclePrice(vehicleType, modelId);
      const resVehiclePriceByYear = await getVehiclePriceByYearApi(vehicleType, modelId);

      let resVehiclePriceByYearAux = [];

      if (resVehiclePriceByYear.data.length > 0) {
        resVehiclePriceByYearAux = resVehiclePriceByYear.data;
      }

      if (resVehiclePrice.data.list_price) {
        resVehiclePriceByYearAux.push(
          {
            year: 'OKM',
            price: resVehiclePrice.data.list_price,
          },
        );
      }

      setVehicleYearsList(resVehiclePriceByYearAux);
      setYear('none');
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceYearError, 'error');
    }
  };

  const getVehiclePriceByModelId = (modelId) => {
    const modelAux = modelList.find((m) => m.codia === modelId);
    const offerNewVehicles = shouldOfferNewVehiclesBySubproductId(subproductSelected) && modelAux.list_price;
    const offerUsedVehicles = shouldOfferUsedVehiclesBySubproductId(subproductSelected) && modelAux.prices;
    if (offerNewVehicles && offerUsedVehicles) {
      getVehicleListPriceAndYears(modelId);
    } else if (offerNewVehicles) {
      getVehicleListPrice(modelId);
    } else if (offerUsedVehicles) {
      getVehiclePriceByYear(modelId);
    }
  };

  const onChangeModel = (e) => {
    const modelId = e.target.value;
    setModel(modelId);
    setYear('none');
    setVehiclePrice(undefined);
    setVehicleYearsList(undefined);
    if (modelId !== 'none') {
      getVehiclePriceByModelId(modelId);
    }
  };

  const onChangeYear = (e) => {
    setYear(e.target.value);
    if (e.target.value !== 'none') {
      const vehicleYear = vehicleYearsList.find((v) => v.year === e.target.value);
      setVehiclePrice(vehicleYear.price * calculatorPercentage);
    } else {
      setVehiclePrice(undefined);
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      setProductList(getLoggedUserProductList());
      const { data: calculatorPercentageAux } = await getCalculatorParameters();
      setCalculatorPercentage(calculatorPercentageAux);
    } catch (error) {
      showToast('ocurrió un error', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    downloadRenewers,
    methods,
    handleCalculate,
    loadingCalculate,
    productList,
    onSelectProduct,
    subproductList,
    onSelectSubproduct,
    termList,
    brandList,
    onChangeBrand,
    brand,
    vehicleModelLoading,
    modelList,
    model,
    onChangeModel,
    vehiclePrice,
    installmentList,
    selectedInstallment,
    setSelectedInstallment,
    vehicleYearsList,
    year,
    onChangeYear,
  };
};

export default useHomeController;
