import PropTypes from 'prop-types';
import { Box, Grid, styled } from '@mui/material';
import Loading from '../../../components/commons/Loading';
import { LOGIN_BACKGROUND, LOGIN_CREDICUOTAS_LOGO } from '../../../constants/imageConstants';

const RootGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: `url("${LOGIN_BACKGROUND}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '120vh',

  [theme.breakpoints.down('md')]: {
    backgroundImage: 'none',
    height: '100%',
  },
}));

const LoginLayoutComponent = ({ loading, children }) => (
  <RootGrid container justifyContent="center">
    <Grid item xs={11} sm={10} md={4}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ textAlign: 'left' }}>
            <img src={LOGIN_CREDICUOTAS_LOGO} alt="Credicuotas" style={{ marginTop: '64px', marginBottom: '28px' }} />
          </Box>
          {children}
        </>
      )}
    </Grid>
    <Grid item md={6} />
  </RootGrid>
);

LoginLayoutComponent.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LoginLayoutComponent.defaultProps = {
  loading: false,
};

export default LoginLayoutComponent;
