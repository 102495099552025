/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getLoanRequest, getUserInputFields, resolveConditionalComboData, sendVerificationCode,
  setCustomerData,
  verifyCode,
} from '../../api/workflowApiService';
import { defaultComboBoxOption } from '../../forms/customerDataSchemaValidations';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorMessage } from '../../services/errorApiService';
import { ERROR_SENDING_EMAIL } from '../../constants/apiErrorCodeConstants';
import i18n from '../../common/i18n';
import { DISBURSEMENT_MODE_PREPAID_CARD } from '../../constants/commerceContants';
import useNavigation from '../useNavigation';

const useCustomerDataController = () => {
  const { hash } = useParams();
  const { goToUploadDocuments, verifyHasDistanceSellingLink } = useNavigation();

  const { showToast } = useToaster();
  const [globalLoading, setGlobalLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState();
  const [inputGroups, setInputGroups] = useState();

  const [mustLoadDebitCardInfo, setMustLoadDebitCardInfo] = useState();
  const [hasDebitCardValid, setHasDebitCardValid] = useState();
  const [userFilled, setUserFilled] = useState({});
  const [ctaLoading, setCtaLoading] = useState(false);

  const resolveToShowConditional = (input) =>
    !input.userInputOptions.conditionalUserInputName
    || (input.userInputOptions.conditionalUserInputName && input.userInputOptions.isConditionalToShow);

  const resolveToShowUserInputIfAlreadyHasValue = (input) =>
    !(input.userInputOptions.mustHideIfAlreadyHasValue && input.userInputOptions.defaultValue);

  const onSelectOption = async (input, inputGroup, value, groupIndex, methods) => {
    input.userInputOptions.comboData.forEach((option) => {
      option.selected = option.id === value;
    });
    await updateAndResolveIfOtherFieldsDependsOnMe(input, inputGroup, methods);
    const inputGroupsAux = [...inputGroups];
    inputGroupsAux[groupIndex] = inputGroup;
    setInputGroups(inputGroupsAux);
  };

  const getComboDataSelectedAndSetIfSingleOption = (input) => {
    let comboSelected;

    input.userInputOptions.comboData.forEach((option) => {
      if (option.selected) {
        comboSelected = option;
      }
    });

    if (!comboSelected && input.userInputOptions.comboData.length === 1 && input.userInputOptions.required) {
      [comboSelected] = input.userInputOptions.comboData;
    }

    if (!comboSelected && input.userInputOptions.defaultValue) {
      [comboSelected] = input.userInputOptions.comboData.find((c) => c.id === input.userInputOptions.defaultValue);
    }

    return comboSelected;
  };

  const updateAndResolveIfOtherFieldsDependsOnMe = async (input, group, methods) => {
    const comboItemSelected = getComboDataSelectedAndSetIfSingleOption(input);
    if (comboItemSelected) {
      userFilled[input.name] = comboItemSelected;
    }

    // si hay campos que dependen de mi los resuelvo
    if (input.userInputOptions.inputsThatDependsOnMe && input.userInputOptions.inputsThatDependsOnMe.length > 0) {
      // recorro todos los campos que están en el mismo grupo
      for (const variable of group.variables) {
        // si existe una variable que está dentro de los campos que dependen de mi
        if (input.userInputOptions.inputsThatDependsOnMe.includes(variable.name)) {
          // reseteo el valor del campo condicional
          userFilled[variable.name] = undefined;
          if (variable.userInputOptions.type === 'COMBO') {
            methods?.resetField(variable.name, { defaultValue: defaultComboBoxOption.id });
          } else if (variable.userInputOptions.type === 'TYPEAHEAD') {
            methods?.resetField(variable.name, { defaultValue: defaultComboBoxOption });
          }

          // el valor a usar depende de si viene por default o si se selecciona en la pantalla
          const valueToUse = comboItemSelected?.id || input.userInputOptions.defaultValue;

          // si mi valor no es undefined (el del combo que acabo de modificar)
          // y (el campo condicional no depende de un valor o tiene un valor que lo condiciona y coincide con  mi valor),
          // lo muestro
          if ((userFilled[input.name] || input.userInputOptions.defaultValue)
            && (
              !variable.userInputOptions.conditionalUserInputValue
              || (variable.userInputOptions.conditionalUserInputValue
                && variable.userInputOptions.conditionalUserInputValue === valueToUse))) {
            variable.userInputOptions.isConditionalToShow = true;
          } else {
            variable.userInputOptions.isConditionalToShow = false;
          }

          if (input.name === 'PtllaFormasDePago') {
            setMustLoadDebitCardInfo(false);
          }

          // si tengo que mostrar el campo condicional y es un combo, voy al backend a resolver la lista de opciones
          if (variable.userInputOptions.isConditionalToShow) {
            if (variable.userInputOptions.type === 'COMBO'
              || variable.userInputOptions.type === 'TYPEAHEAD'
              || variable.userInputOptions.type === 'RADIO') {
              try {
                const { data } = await resolveConditionalComboData(hash, variable.name, valueToUse);
                variable.userInputOptions.comboData = data;
                updateAndResolveIfOtherFieldsDependsOnMe(variable, group);
              } catch (error) {
                showToast(getApiErrorMessage(error), 'error');
              }
            } else if (variable.userInputOptions.type === 'TEXT') {
              // Resuelvo si se le aplica un valor automaticamente que proviene de la opcion seleccionada del combo que lo condiciona
              // para simplificar y como solo tengo este caso de uso lo hago solo con TEXT
              let autoCompleteValue = null;
              if (userFilled[input.name].descripcion !== 'CAPITAL FEDERAL') {
                // fix horrible. El tema es que en sapp el codigo postal de Cpaital Federal esta en 1000
                // en paralelo se esta haciendo un fix en el SP de sapp para que cuando es Capital no devuelva el cod postal 1000
                autoCompleteValue = userFilled[input.name].valueToAutoCompleteConditiningInput;
              }
              userFilled[variable.name] = autoCompleteValue;
              methods?.setValue(variable.name, autoCompleteValue);
            } else if (variable.userInputOptions.type === 'CARD') {
              if (variable.userInputOptions.defaultValue) {
                if (variable.userInputOptions.defaultValue !== 'INVALID_CARD') {
                  setHasDebitCardValid(true);
                }
              } else {
                setMustLoadDebitCardInfo(true);
              }
            }
          }
        }
      }
    }

    // se actualiza
    setUserFilled(userFilled);
  };

  const sendValidationCode = async (input, methods) => {
    try {
      const value = methods.watch(input.name);
      await sendVerificationCode(hash, input.name, value);
      return true;
    } catch (error) {
      if (getApiErrorMessage(error) === ERROR_SENDING_EMAIL) {
        showToast(`${i18n.CustomerData.sendCodeError}. ${i18n.CustomerData.reviewInputEmail}`, 'error');
      } else {
        showToast(`${i18n.CustomerData.sendCodeError}. ${getApiErrorMessage(error)}`, 'error');
      }
      return false;
    }
  };

  const verificateCode = async (code, input, methods) => {
    try {
      if (code && code.length === 4) {
        const value = methods.watch(input.name);
        const { data: { result } } = await verifyCode(hash, input.name, code, value);
        methods.setValue(`${input.name}VerificationCode`, result);
      }
    } catch (error) {
      showToast(`${i18n.CustomerData.verificationCodeError}. ${getApiErrorMessage(error)}`, 'error');
    }
  };

  const doSubmit = async (data) => {
    try {
      setCtaLoading(true);
      // valido que si el desembolso es tarjeta prepaga, ingrese un mail valido.
      if (userFilled.PtllaModoDesembolso && userFilled.PtllaModoDesembolso.id === DISBURSEMENT_MODE_PREPAID_CARD) {
        if (data.PtllaMail === undefined || data.PtllaMail === null) {
          showToast(i18n.CustomerData.disbursementPrePaidCardMailRequired, 'info');
        }
        if (data.PtllaCodPostal === undefined || data.PtllaCodPostal === null) {
          showToast(i18n.CustomerData.disbursementPrePaidCardZipCodeRequired, 'info');
        }
      }

      const dataAux = { ...data };
      Object.entries(dataAux).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
          dataAux[key] = value.id;
        }
      });

      await setCustomerData(hash, dataAux);
      goToUploadDocuments(hash);
    } catch (error) {
      console.log(error);
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setCtaLoading(false);
    }
  };

  const onError = (errors) => {
    if (errors.PtllaTarjetaDebito && Object.keys(errors).length === 1) {
      showToast(i18n.CustomerData.debitCardInputRequired, 'error');
    } else {
      showToast(i18n.CustomerData.requiredEmptyFields, 'error');
    }
  };

  const changeCBUComboToText = (input, methods) => {
    if (input.userInputOptions.type === 'COMBO' || input.userInputOptions.type === 'RADIO') {
      input.userInputOptions.type = 'TEXT';
      input.userInputOptions.hintAction = i18n.CustomerData.disbursementMode.inputCBUHintAction;
      input.userInputOptions.label = i18n.CustomerData.disbursementMode.inputCBU;
      methods.setValue(input.name, '');
    } else {
      input.userInputOptions.type = 'COMBO';
      input.userInputOptions.hintAction = i18n.CustomerData.disbursementMode.comboCBUHintAction;
      input.userInputOptions.label = i18n.CustomerData.disbursementMode.comboCBU;
      methods?.resetField(input.name, { defaultValue: defaultComboBoxOption.id });
    }

    const inputGroupsAux = [...inputGroups];

    inputGroupsAux.forEach((g) => {
      if (g.name === input.group) {
        g.variables.forEach((v) => {
          if (v.name === input.name) {
            v.userInputOptions = input.userInputOptions;
          }
        });
      }
    });
    setInputGroups(inputGroupsAux);
  };

  const doHintAction = (input, methods) => {
    const functionToCall = hintActionFunctions[input.name];
    if (functionToCall) {
      functionToCall(input, methods);
    }
  };

  const hintActionFunctions = {
    PtllaCBUDesembolsoYCobro: changeCBUComboToText,
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      window.scrollTo(0, 0);
      const { data: lr } = await getLoanRequest(hash);
      setLoanRequest(lr);

      // Verifica si tiene generado link de venta a distancia
      verifyHasDistanceSellingLink(lr);

      const { data: { groups } } = await getUserInputFields(hash);
      for (const group of groups) {
        for (const variable of group.variables) {
          if (variable.userInputOptions.type === 'COMBO' || variable.userInputOptions.type === 'TYPEAHEAD'
            || variable.userInputOptions.type === 'RADIO') {
            await updateAndResolveIfOtherFieldsDependsOnMe(variable, group);
          } else if (variable.userInputOptions.type === 'CARD') {
            // verifica si ya fue validada alguna tarjeta para no tratar el formulario CARD como obligatorio para continuar
            if (variable.name === 'PtllaTarjetaDebito' && variable.userInputOptions.defaultValue) {
              setMustLoadDebitCardInfo(false);
              if (variable.userInputOptions.defaultValue !== 'INVALID_CARD') {
                setHasDebitCardValid(true);
              }
            }
          }
        }
      }
      setInputGroups(groups);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    inputGroups,
    resolveToShowConditional,
    resolveToShowUserInputIfAlreadyHasValue,
    onSelectOption,
    sendValidationCode,
    verificateCode,
    mustLoadDebitCardInfo,
    hasDebitCardValid,
    userFilled,
    doSubmit,
    ctaLoading,
    onError,
    doHintAction,
  };
};

export default useCustomerDataController;
