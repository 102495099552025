import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Alert } from '@doit/pcnt-react-ui-library';
import parse from 'html-react-parser';
import i18n from '../../../../common/i18n';

export const ManualWarningMessage = ({ loanRequest, documents, onlyRequiredAndNotSettedFilterFunction }) => (
  <Alert severity="warning" style={{ textAlign: 'left' }}>
    <Typography>
      {i18n.FinalPage.Messages.Manual.Warning.title}
    </Typography>
    <Typography>
      {parse(i18n.FinalPage.Messages.Manual.Warning.subtitle)}
    </Typography>
    <Typography>
      <ol style={{ paddingLeft: '16px' }}>
        {!loanRequest.customerDataCompleted && (
        <li>
          {i18n.FinalPage.Messages.Manual.Warning.reqCustomerDataCompleted}
        </li>
        )}
        {!loanRequest.documentsCompleted && !loanRequest.isPaperless && (
        <li>
            {i18n.FinalPage.Messages.Manual.Warning.reqFileOrFollowingImages}
        </li>
        )}
        {loanRequest.isPaperless && !loanRequest.hasSigned && (
        <li>
            {i18n.FinalPage.Messages.Manual.Warning.reqSign}
          <ul>
            {documents.map((doc) => (
              onlyRequiredAndNotSettedFilterFunction(doc) && (
              <li key={doc.userInputOptions.label}>{doc.userInputOptions.label}</li>
              )
            ))}
          </ul>
        </li>
        )}
        <li>
          {i18n.FinalPage.Messages.Manual.Warning.waitCall}
        </li>
      </ol>
    </Typography>
  </Alert>
);

ManualWarningMessage.propTypes = {
  loanRequest: PropTypes.shape({
    customerDataCompleted: PropTypes.bool.isRequired,
    documentsCompleted: PropTypes.bool.isRequired,
    isPaperless: PropTypes.bool.isRequired,
    hasSigned: PropTypes.bool.isRequired,
  }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({
    userInputOptions: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  onlyRequiredAndNotSettedFilterFunction: PropTypes.func.isRequired,
};

export default ManualWarningMessage;
