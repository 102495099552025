import { useState, Fragment } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@doit/pcnt-react-ui-library';
import { LOGIN_CREDICUOTAS_LOGO, LOGOUT_ICON } from '../../../constants/imageConstants';
import { menuConfig } from '../../../utils/menuUtils';
import MenuAvatar from './MenuAvatar';
import useLogout from '../../../hooks/useLogout';
import { useAuth } from '../../../contexts/AuthContext';
import i18n from '../../../common/i18n';
import { useInstallApp } from '../../../contexts/InstallAppContext';
import useNavigation from '../../../hooks/useNavigation';

const SideMenuMobile = ({ onSelect }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const { loggedUser } = useAuth();
  const { doLogout } = useLogout();
  const { install } = useInstallApp();
  const { goToInstallApp } = useNavigation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Box sx={{ textAlign: 'left', backgroundColor: '#fff' }} pl={1} pt={1}>
        <IconButton
          onClick={handleDrawerToggle}
          className="iconButton"
        >
          <Menu />
          <Box sx={{ textAlign: 'left' }} pl={1} pt={1}>
            <img src={LOGIN_CREDICUOTAS_LOGO} alt="ICONO" style={{ width: '50%' }} />
          </Box>
        </IconButton>
      </Box>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        anchor="left"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left' }}>
          <List className="menuHamburguesa">
            {menuConfig.map((menu, index) => (
              <Fragment key={menu.key}>
                {menu.section && (
                  <ListItem key={menu.section.title} sx={{ padding: '8px 16px' }}>
                    {menu.section.icon && (
                      <ListItemIcon sx={{
                        minWidth: '24px', width: '24px', height: '24px', marginRight: '8px',
                      }}
                      >
                        <img src={menu.section.icon} alt="icon" />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={menu.section.title} className="menuHamburguesa" />
                  </ListItem>
                )}
                {menu.options.map((option) => (
                  <Fragment key={option.label}>
                    {option.isAllowed(loggedUser) ? (
                      <ListItemButton
                        key={option.label}
                        sx={{ padding: '8px 16px' }}
                        onClick={() => onSelect(option)}
                        selected={option.path === location.pathname}
                      >
                        {option.icon && (
                          <ListItemIcon sx={{
                            minWidth: '24px', width: '24px', height: '24px', marginRight: '8px',
                          }}
                          >
                            <img src={option.icon} alt="icon" />
                          </ListItemIcon>
                        )}
                        <ListItemText>{option.label}</ListItemText>
                      </ListItemButton>
                    ) : null}
                  </Fragment>
                ))}
                {index < menuConfig.length - 1 ? <Divider /> : null}
                {index === menuConfig.length - 1 && install && (
                  <ListItem sx={{ padding: '24px 32px' }}>
                    <Button
                      className="yellow-btn"
                      endIcon={<KeyboardArrowRightIcon />}
                      sx={{ fontSize: '14px' }}
                      onClick={goToInstallApp}
                    >
                      {i18n.SideMenu.downloadApp}
                    </Button>
                  </ListItem>
                )}
              </Fragment>
            ))}
            <Divider />
            <ListItem sx={{ padding: '8px 16px' }}>
              <MenuAvatar />
            </ListItem>
            <ListItemButton onClick={doLogout} sx={{ padding: '8px 16px' }}>
              <ListItemIcon sx={{
                minWidth: '24px', width: '24px', height: '24px', marginRight: '8px',
              }}
              >
                <img src={LOGOUT_ICON} alt="icon" />
              </ListItemIcon>
              <ListItemText sx={{ fontWeight: '600', color: '#757575' }}>
                {i18n.SideMenu.options.closeSession}
              </ListItemText>
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

SideMenuMobile.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default SideMenuMobile;
