import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { Button } from '@doit/pcnt-react-ui-library';
import { defaultComboBoxOption } from '../../../forms/customerDataSchemaValidations';

const CustomerDataComboBox = ({
  input, inputGroup, index, methods, onSelectOption, userFilled, onHintAction,
}) => (
  <Grid item xs={12} md="auto">
    <FormControl fullWidth error={!!(methods.formState?.errors && methods.formState?.errors[input.name])}>
      <InputLabel id={`${input.name}-select-label`}>
        {input.userInputOptions.required ? `${input.userInputOptions.label} *` : input.userInputOptions.label}
      </InputLabel>
      <Controller
        name={input.name}
        control={methods.control}
        defaultValue={userFilled[input.name]?.id || input.userInputOptions.defaultValue || 'none'}
        render={({ field }) => (
          <Select
            {...field}
            labelId={`${input.name}-select-label`}
            id={`${input.name}-select`}
            value={field.value || input.userInputOptions.defaultValue || 'none'}
            label={input.userInputOptions.required ? `${input.userInputOptions.label} *` : input.userInputOptions.label}
            sx={{ minWidth: '216px' }}
            onChange={(e) => {
              field.onChange(e.target.value);
              onSelectOption(input, inputGroup, e.target.value, index, methods);
            }}
          >
            <MenuItem value={defaultComboBoxOption.id}>{defaultComboBoxOption.label}</MenuItem>
            {input.userInputOptions.comboData.map((option) => (
              <MenuItem value={option.id} key={option.id}>{`${option.descripcion} ${option.subDescripcion || ''}`}</MenuItem>
            ))}
          </Select>
        )}
      />
      {methods.formState.errors && methods.formState.errors[input.name] && (
        <FormHelperText>{methods.formState.errors[input.name].message}</FormHelperText>
      )}
    </FormControl>
    {(input.userInputOptions.hint || (input.userInputOptions.hintAction && userFilled && userFilled[input.name]?.showHint)) && (
      <Typography
        align="left"
        sx={{
          fontSize: '12px',
          marginTop: '4px',
        }}
      >
        {`${input.userInputOptions.hint}.`}
        {input.userInputOptions.hintAction && (
          <Button
            variant="tertiary"
            color="grey"
            className="minimalist"
            sx={{ fontSize: '12px', padding: '0px', marginLeft: '2px' }}
            onClick={() => onHintAction(input)}
          >
            {input.userInputOptions.hintAction}
          </Button>
        )}
      </Typography>
    )}
  </Grid>
);

CustomerDataComboBox.propTypes = {
  input: PropTypes.shape().isRequired,
  inputGroup: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  methods: PropTypes.shape().isRequired,
  onSelectOption: PropTypes.func.isRequired,
  userFilled: PropTypes.shape().isRequired,
  onHintAction: PropTypes.func.isRequired,
};

export default CustomerDataComboBox;
