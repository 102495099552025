import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import clsx from 'clsx';
import { Card, CardActionArea, CardContent } from '@doit/pcnt-react-ui-library';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { moneyFormatter, moneyFormatterWithoutDecimals } from '../../../utils/formatterUtils';
import { fillMessageWith } from '../../../utils/functionsUtils';

const useStyles = makeStyles((theme) => ({
  default: {
    border: '1px solid #E0E0E0',
    boxShadow: 'none',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  selected: {
    padding: '0px',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.main}0D`,
  },
}));

const DualOfferCard = ({
  labels, selected, onSelect, amount, cancelAmount,
}) => {
  const classes = useStyles();
  return (
    <>
      <Card className={clsx(classes.default, {
        [classes.selected]: selected,
      })}
      >
        <CardActionArea onClick={onSelect} sx={{ height: '100%' }}>
          <CardContent sx={{ height: 'fill-available' }}>
            <Box>
              <Box sx={{ textAlign: 'right' }}>
                <img src={selected ? '/images/check-circle-selected.svg' : '/images/check-circle.svg'} alt="check" className={classes.check} />
              </Box>
              <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>{labels.title}</Typography>
              <Typography sx={{ marginTop: '12px', fontSize: '14px' }}>{labels.description}</Typography>
              <Typography sx={{ marginTop: '16px', fontSize: '20px', fontWeight: 600 }}>{labels.availableAmount}</Typography>
              <Typography
                sx={{
                  marginTop: '8px', fontSize: '24px', color: '#DA0067', fontWeight: 700,
                }}
              >
                {labels.cancelAmount && cancelAmount === 0 ? '$-' : moneyFormatter(amount)}
              </Typography>
              {labels.cancelAmount && (
                <Typography sx={{ fontSize: '14px', marginTop: '12px' }}>
                  {parse(fillMessageWith(
                    labels.cancelAmount,
                    cancelAmount > 0 ? moneyFormatterWithoutDecimals(cancelAmount) : '$-',
                  ))}
                </Typography>
              )}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

DualOfferCard.propTypes = {
  labels: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  amount: PropTypes.number,
  cancelAmount: PropTypes.number,
};

DualOfferCard.defaultProps = {
  selected: false,
  amount: undefined,
  cancelAmount: undefined,
};

export default DualOfferCard;
