import PropTypes from 'prop-types';
import { Button, FileUpload } from '@doit/pcnt-react-ui-library';
import {
  Box, Typography,
} from '@mui/material';
import { PLEDGE_DOCS } from '../../../constants/commerceContants';
import i18n from '../../../common/i18n';

const LoadDocumentInput = ({
  documentToUpload, setDocumentToUpload, image, setImage, onLoadDocument, uploadImageLoading,
}) => {
  const resolveLoadImageMessageTitle = () => {
    let message = i18n.DocumentUploadPage[documentToUpload.name] || documentToUpload.userInputOptions.label;
    if (image) {
      message = i18n.DocumentUploadPage.imageLoadedTitle;
    }
    return message;
  };

  const resolveLoadImageMessageDescription = () => {
    const message = image ? i18n.DocumentUploadPage.imageLoadedDescription : i18n.DocumentUploadPage.imageToLoadDescription;
    return message;
  };

  const isPledgeDocument = () => (PLEDGE_DOCS.includes(documentToUpload.name));

  return (
    <>
      <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{resolveLoadImageMessageTitle()}</Typography>
      <Typography align="left" sx={{ fontSize: '14px' }}>{resolveLoadImageMessageDescription()}</Typography>
      <Box className="upload-document" sx={{ marginTop: '32px' }}>
        <FileUpload
          icon={<img src="/images/upload-icon.svg" alt="upload" width="56px" />}
          buttonLabel={image ? i18n.DocumentUploadPage.retryImageButton : i18n.DocumentUploadPage.uploadImageButton}
          maxFileSize={1024 * 1024 * 15} // 15 MB
          camera={isPledgeDocument() ? 'null' : 'user'}
          fileTypes={isPledgeDocument() ? { 'image/*': [], 'application/pdf': [] } : { 'image/*': [] }}
          onFileChange={(images) => setImage(images[0])}
        />
      </Box>
      <Box sx={{ marginTop: '64px' }}>
        {image && (
          <Button
            variant="contained"
            color="grey"
            fullWidth
            sx={{ marginBottom: '12px' }}
            onClick={() => onLoadDocument(image)}
            loading={uploadImageLoading}
          >
            {i18n.DocumentUploadPage.continueCTA}
          </Button>
        )}
        <Button fullWidth variant="tertiary" color="grey" onClick={() => { setDocumentToUpload(undefined); setImage(undefined); }}>
          {i18n.DocumentUploadPage.backCTA}
        </Button>
      </Box>
    </>
  );
};

LoadDocumentInput.propTypes = {
  documentToUpload: PropTypes.shape(),
  setDocumentToUpload: PropTypes.func.isRequired,
  image: PropTypes.shape(),
  setImage: PropTypes.func.isRequired,
  onLoadDocument: PropTypes.func.isRequired,
  uploadImageLoading: PropTypes.bool,
};

LoadDocumentInput.defaultProps = {
  documentToUpload: undefined,
  image: undefined,
  uploadImageLoading: false,
};

export default LoadDocumentInput;
