import { useEffect, useState } from 'react';
import {
  checkExistingPenLoanRequest, getContactOrigins, resolveIdentification, startWorkflow,
} from '../../api/workflowApiService';
import { useAuth } from '../../contexts/AuthContext';
import { useToaster } from '../../contexts/ToasterContext';
import i18n from '../../common/i18n';
import { BRANCH_CASH_ID, WC } from '../../constants/commerceContants';
import { ERROR_CODE_SAPP_NO_PEN_CREDITS } from '../../constants/apiErrorCodeConstants';
import { getApiErrorCode } from '../../services/errorApiService';
import useNavigation from '../useNavigation';
import { useOverlayLoading } from '../../contexts/OverlayLoadingContext';

const useQuotationController = () => {
  const {
    getLoggedUserProductList,
    getLoggedUserCommerceId,
    getLoggedUserHeadOfficeId,
    getLoggedUserBranchId,
    getLoggedUserProvince,
    getLoggedUserBranchCategory,
    getLoggedUserBranchSubcategory,
    getLoggedUserEmail,
    getLoggedUserUserName,
    getLoggedUserBranchIsPaperLess,
    getLoggedUserBranchHasBiometric,
  } = useAuth();
  const { setOverlayLoading } = useOverlayLoading();

  const { showToast } = useToaster();
  const { goToInstallmentPlan, continueExistingLoanRequest } = useNavigation();

  const [globalLoading, setGlobalLoading] = useState(true);
  const [loadingCustomers, setLoadingCustomers] = useState(false);

  const [productList, setProductList] = useState();
  const [subproductList, setSubproductList] = useState([]);
  const [termList, setTermList] = useState();

  const [customerList, setCustomerList] = useState();
  const [howMeetUsList, setHowMeetUsList] = useState();

  const onSelectProduct = (productId, methods) => {
    const subproductListAux = productList.find((productAux) => productId === productAux.idProducto)?.subproductos || [];
    setSubproductList(subproductListAux);
    methods.resetField('subproduct', { defaultValue: 'none' });
    setTermList(undefined);
  };

  const onSelectSubproduct = (subproductId, methods) => {
    const termsListAux = subproductList.find((subproductAux) => subproductId === subproductAux.idSubProducto)?.plazoParaPago || [];
    if (termsListAux.length === 1) {
      methods.resetField('terms', { defaultValue: termsListAux[0] });
    }
    setTermList(termsListAux);
  };

  const resolveCustomerIdentification = async ({ target: { value: customerId } }, methods) => {
    try {
      if (customerId) {
        setLoadingCustomers(true);
        const { data: customerListAux } = await resolveIdentification(customerId);

        methods.resetField('customerTaxId', { defaultValue: 'none' });
        if (customerListAux.length === 1) {
          // Si tiene un unico cuit lo selecciono por defecto
          methods.setValue('customerTaxId', customerListAux[0].cuit);
        } else if (customerListAux.length === 0) {
          // Si no tiene ningun cuit, lo notifico
          showToast(i18n.QuotationPage.customerIdNotFound, 'info');
        }
        setCustomerList(customerListAux);
      }
    } catch (error) {
      showToast(error?.data?.cause, 'error');
    } finally {
      setLoadingCustomers(false);
    }
  };

  const generateNewLoanRequest = async (startWorkflowDto) => {
    try {
      const { data: loanRequest } = await startWorkflow(startWorkflowDto);
      goToInstallmentPlan(loanRequest.hashKey);
    } catch (error) {
      showToast(error?.data?.cause, 'error');
    }
  };

  const doSubmit = async (data) => {
    setOverlayLoading(true, i18n.QuotationPage.overlayLoadingMessage);
    const selectedCustomer = customerList.find((customer) => customer.cuit === data.customerTaxId);

    const startWorkflowDto = {
      customerIdentification: data.customerTaxId,
      sex: data.gender,
      birthDate: selectedCustomer.fechanacimiento,
      idCommerce: getLoggedUserHeadOfficeId(),
      idBranch: getLoggedUserBranchId(),
      idProduct: data.product,
      idSubProduct: data.subproduct,
      provinceCode: getLoggedUserProvince(),
      categoryCode: getLoggedUserBranchCategory(),
      subCategoryCode: getLoggedUserBranchSubcategory(),
      term: data.terms,
      customerName: selectedCustomer.nombrecompleto,
      sellerEmail: getLoggedUserEmail(),
      preCustomerData: {}, // esto era mail y telefono que ya no se muestra en pantalla
      sellerUsername: getLoggedUserUserName(),
      contactOrigin: data.howMeetUs,
      branchIsPaperless: getLoggedUserBranchIsPaperLess(),
      branchHasBiometric: getLoggedUserBranchHasBiometric(),
      requestFrom: WC,
      pep: data.pep,
      so: data.so,
    };

    try {
      const { data: loanRequest } = await checkExistingPenLoanRequest(startWorkflowDto);
      continueExistingLoanRequest(loanRequest);
    } catch (error) {
      if (getApiErrorCode(error) === ERROR_CODE_SAPP_NO_PEN_CREDITS) {
        await generateNewLoanRequest(startWorkflowDto);
      } else {
        showToast(error?.response?.data?.cause, 'error');
      }
    } finally {
      setOverlayLoading(false);
    }
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      setProductList(getLoggedUserProductList());

      const commerceId = getLoggedUserCommerceId();
      if (commerceId === BRANCH_CASH_ID) {
        const { data: { commerceContactOrigins } } = await getContactOrigins();
        if (commerceContactOrigins) {
          setHowMeetUsList(commerceContactOrigins.split(','));
        }
      }
    } catch (error) {
      showToast(error?.data?.cause, 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    productList,
    onSelectProduct,
    subproductList,
    onSelectSubproduct,
    termList,
    resolveCustomerIdentification,
    loadingCustomers,
    customerList,
    howMeetUsList,
    doSubmit,
  };
};

export default useQuotationController;
