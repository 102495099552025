import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Alert } from '@doit/pcnt-react-ui-library';
import i18n from '../../../../common/i18n';

export const AutomaticLiqMessage = ({ loanRequest }) => (
  <Alert severity="info" style={{ textAlign: 'left' }}>
    <Typography>
      {i18n.FinalPage.Messages.AutomaticLiq.title}
    </Typography>
    <Typography>
      {loanRequest.disbursementMode === null
        ? i18n.FinalPage.Messages.AutomaticLiq.deliverProduct
        : i18n.FinalPage.Messages.AutomaticLiq.disburseAmount}
    </Typography>
  </Alert>
);

AutomaticLiqMessage.propTypes = {
  loanRequest: PropTypes.shape({
    disbursementMode: PropTypes.string,
  }).isRequired,
};

export default AutomaticLiqMessage;
