const moneyFormatterConst = new Intl.NumberFormat('es-AR', {
  // style: '$currency',
  currency: 'ARS',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const moneyFormatterWithoutDecimalsConst = new Intl.NumberFormat('es-AR', {
  // style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
export const moneyFormatterWithoutDecimals = (element) => (
  `$${moneyFormatterWithoutDecimalsConst.format(element)}`
);

export const moneyFormatter = (element) => {
  const hasDecimals = element % 1 !== 0;
  return hasDecimals ? `$${moneyFormatterConst.format(element)}` : `$${moneyFormatterWithoutDecimalsConst.format(element)}`;
};

export const numberFormatter = new Intl.NumberFormat('es-AR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const dateFormatter = (date) => {
  const options = {
    timeZone: 'UTC',
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};

export const dateTimeFormatter = (date) => {
  const options = {
    timeZone: 'America/Argentina/Buenos_Aires',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};

export const getMonthName = (date) => {
  const formatter = new Intl.DateTimeFormat('en-US', { timeZone: 'UTC' });
  return new Date(formatter.format(new Date(date))).toLocaleString('default', { month: 'long' });
};

export const amountFormatter = (value) => { // 1234 -> 12.34
  let response = value;
  const lengthResponse = response.length;
  if (lengthResponse < 3) {
    response.padStart(3, '0');
  }
  response = `${value.substring(0, lengthResponse - 2)}.${value.substring(lengthResponse - 2)}`;
  return response;
};

export const capitalize = (str) => str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();

export const capitalizeEachWord = (str) => {
  const words = str.split(' ');

  for (let i = 0; i < words.length; i += 1) {
    if (words[i].length > 0) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
    }
  }

  return words.join(' ');
};
