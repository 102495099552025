import { Typography } from '@mui/material';
import { Alert } from '@doit/pcnt-react-ui-library';
import parse from 'html-react-parser';
import i18n from '../../../../common/i18n';

export const WelcomeAutomaticMessage = () => (
  <Alert
    severity="info"
    style={{ textAlign: 'left' }}
  >
    <Typography>
      <strong>
        {i18n.FinalPage.Messages.AutomaticWelcome.title}
      </strong>
    </Typography>
    <Typography>
      {parse(i18n.FinalPage.Messages.AutomaticWelcome.subtitle)}
    </Typography>
    <Typography>
      <ul style={{ paddingLeft: '20px' }}>
        <li>
          {parse(i18n.FinalPage.Messages.AutomaticWelcome.checkWhatsappMessages)}
        </li>
        <li>
          {parse(i18n.FinalPage.Messages.AutomaticWelcome.answerQuestions)}
        </li>
      </ul>
    </Typography>
  </Alert>
);

export default WelcomeAutomaticMessage;
