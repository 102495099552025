import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@doit/pcnt-react-ui-library';
import {
  Box, Divider, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { LOGIN_CREDICUOTAS_LOGO, LOGOUT_ICON } from '../../../constants/imageConstants';
import MenuAvatar from './MenuAvatar';
import { useAuth } from '../../../contexts/AuthContext';
import { MENU_WIDTH_SIZE, menuConfig } from '../../../utils/menuUtils';
import i18n from '../../../common/i18n';
import useLogout from '../../../hooks/useLogout';
import { useInstallApp } from '../../../contexts/InstallAppContext';
import useNavigation from '../../../hooks/useNavigation';

const SideMenu = ({ onSelect }) => {
  const location = useLocation();
  const { loggedUser } = useAuth();
  const { doLogout } = useLogout();
  const { install } = useInstallApp();
  const { goToInstallApp } = useNavigation();

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open
      sx={{
        width: MENU_WIDTH_SIZE,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: MENU_WIDTH_SIZE,
          boxSizing: 'border-box',
        },
      }}
    >
      <Box sx={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <img src={LOGIN_CREDICUOTAS_LOGO} alt="ICONO" style={{ width: '60%' }} />
      </Box>
      <Box display="flex" flexDirection="column" height="100vh">
        {menuConfig.map((menu, index) => (
          <Box key={menu.section || Math.random()}>
            {menu.section && (
              <ListItem sx={{ paddingLeft: '32px' }}>
                {menu.section.icon && (
                  <ListItemIcon sx={{
                    minWidth: '24px', width: '24px', height: '24px', marginRight: '8px',
                  }}
                  >
                    <img src={menu.section.icon} alt="icon" />
                  </ListItemIcon>
                )}
                <ListItemText>{menu.section.title}</ListItemText>
              </ListItem>
            )}
            {menu.options.map((option) => (
              <Fragment key={option.label}>
                {option.isAllowed(loggedUser) ? (
                  <ListItemButton
                    key={option.label}
                    sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                    onClick={() => onSelect(option)}
                    selected={option.path === location.pathname}
                  >
                    <ListItem>
                      {option.icon && (
                        <ListItemIcon sx={{
                          minWidth: '24px', width: '24px', height: '24px', marginRight: '8px',
                        }}
                        >
                          <img src={option.icon} alt="icon" />
                        </ListItemIcon>
                      )}
                      <ListItemText>{option.label}</ListItemText>
                    </ListItem>
                  </ListItemButton>
                ) : null }
              </Fragment>
            ))}
            {index < menuConfig.length - 1 ? <Divider /> : null}
            {index === menuConfig.length - 1 && install && (
              <ListItem sx={{ paddingLeft: '32px', paddingTop: '24px', paddingRight: '32px' }}>
                <Button
                  className="yellow-btn"
                  endIcon={<KeyboardArrowRightIcon />}
                  sx={{ fontSize: '14px' }}
                  onClick={goToInstallApp}
                >
                  {i18n.SideMenu.downloadApp}
                </Button>
              </ListItem>
            )}
          </Box>
        ))}
      </Box>
      <ListItem sx={{ paddingLeft: '32px', paddingTop: '42px' }}>
        <MenuAvatar />
      </ListItem>
      <ListItem sx={{ padding: '0px', marginBottom: '16px' }}>
        <ListItemButton sx={{ paddingLeft: '32px' }} onClick={doLogout}>
          <ListItemIcon sx={{
            minWidth: '24px', width: '24px', height: '24px', marginRight: '8px',
          }}
          >
            <img src={LOGOUT_ICON} alt="icon" />
          </ListItemIcon>
          <ListItemText sx={{ fontWeight: '600', color: '#757575' }}>{i18n.SideMenu.options.closeSession}</ListItemText>
        </ListItemButton>
      </ListItem>
    </Drawer>
  );
};

SideMenu.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default SideMenu;
