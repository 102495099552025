import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { Button, CardContent, Divider } from '@doit/pcnt-react-ui-library';
import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { capitalizeEachWord, moneyFormatter } from '../../../utils/formatterUtils';
import useNavigation from '../../../hooks/useNavigation';

const BiometricNoHit = ({ loanRequest }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goHome } = useNavigation();
  return (
    <>
      <CardContent sx={{ backgroundColor: '#FAE6E8' }}>
        <img src="/images/rejected-icon.svg" alt="Anulada" />
        <Typography sx={{ fontSize: '32px', fontWeight: 600, marginTop: '32px' }}>{i18n.BiometricResultPage.title}</Typography>
        <Typography sx={{ fontSize: '16px', marginTop: '16px', color: '#D0021B' }}>{i18n.BiometricResultPage.description[0]}</Typography>
        <Typography sx={{ fontSize: '16px', marginTop: '4px', color: '#D0021B' }}>{i18n.BiometricResultPage.description[1]}</Typography>
      </CardContent>
      <CardContent sx={{ padding: { xs: '24px 24px', md: '24px 80px' } }}>
        <Grid container justifyContent="space-around" sx={{ backgroundColor: '#F5F5F5', padding: '24px' }}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px', marginBottom: '24px' }}>{i18n.BiometricResultPage.tipsTitle}</Typography>
          </Grid>
          {i18n.BiometricResultPage.tips.map((tip) => (
            <Grid item xs={12} md={3} key={tip} sx={{ marginTop: { xs: '8px', md: '' } }}>
              <img src={tip.img} alt="tip" />
              <Typography sx={{ fontSize: '12px', marginTop: '8px' }}>{tip.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardContent sx={{ padding: { xs: '0px 24px 0px 24px', md: '24px 80px' } }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="left" sx={{ fontSize: '16px' }}>
              {parse(fillMessageWith(i18n.BiometricResultPage.date, dayjs().format('DD/MM/YYYY')))}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '24px', display: 'flex' }}>
            <Typography align="left" sx={{ fontSize: '16px' }}>
              {parse(fillMessageWith(i18n.BiometricResultPage.name, loanRequest?.customerName ? capitalizeEachWord(loanRequest?.customerName) : ''))}
            </Typography>
            <Typography align="left" sx={{ fontSize: '16px', marginLeft: '24px' }}>
              {parse(fillMessageWith(i18n.BiometricResultPage.dni, loanRequest?.customerId?.substring(2, 10)))}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {isMobile && (
        <Box sx={{ padding: '16px 24px 16px 24px' }}>
          <Divider />
        </Box>
      )}
      <CardContent sx={{ padding: { xs: '0px 24px 0px 24px', md: '24px 80px' }, paddingTop: { xs: '', md: '0px' } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: '' },
            justifyContent: { md: 'space-between' },
          }}
        >
          <Box sx={{ display: { xs: 'flex', md: 'block' } }}>
            <Typography align="left" sx={{ fontSize: '16px', marginTop: '8px' }}>
              {i18n.BiometricResultPage.amount}
              {isMobile && ': '}
            </Typography>
            <Typography
              align="left"
              sx={{
                fontSize: { xs: '16px', md: '24px' },
                marginTop: '8px',
                fontWeight: 700,
                marginLeft: { xs: '4px', md: '0px' },
              }}
            >
              {moneyFormatter(loanRequest?.requestedAmount)}
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'block' } }}>
            <Typography align={isMobile ? 'left' : 'center'} sx={{ fontSize: '16px', marginTop: '8px' }}>
              {i18n.BiometricResultPage.numberOfInstallments}
              {isMobile && ': '}
            </Typography>
            <Typography
              align={isMobile ? 'left' : 'center'}
              sx={{
                fontSize: { xs: '16px', md: '24px' },
                marginTop: '8px',
                fontWeight: 700,
                marginLeft: { xs: '4px', md: '0px' },
              }}
            >
              {loanRequest?.installmentsNumberSelected}
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'block' } }}>
            <Typography align={isMobile ? 'left' : 'right'} sx={{ fontSize: '16px', marginTop: '8px' }}>
              {i18n.BiometricResultPage.installmentAmount}
              {isMobile && ': '}
            </Typography>
            <Typography
              align={isMobile ? 'left' : 'right'}
              sx={{
                fontSize: { xs: '16px', md: '24px' },
                marginTop: '8px',
                fontWeight: 700,
                marginLeft: { xs: '4px', md: '0px' },
              }}
            >
              {moneyFormatter(loanRequest?.installmentAmountSelected)}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="secondary"
          color="grey"
          sx={{ marginTop: '64px' }}
          onClick={goHome}
        >
          {i18n.BiometricResultPage.back}
        </Button>
      </CardContent>
    </>
  );
};

BiometricNoHit.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default BiometricNoHit;
