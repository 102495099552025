/* eslint-disable react/jsx-indent */
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, FormControl, Grid, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from '../../../common/i18n';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import { sendLinkSchemaValidation } from '../../../forms/documentUploadSchemaValidations';
import ComboBoxControlled from '../../../components/commons/ComboBoxControlled';
import useNavigation from '../../../hooks/useNavigation';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { PLEDGE_DOCS } from '../../../constants/commerceContants';
import useCountDown from '../../../hooks/useCountDown';

const LoanDocumentSendContinueLink = ({
  loanRequest, onSendLink, loading, requiredDocuments,
}) => {
  const { hash } = useParams();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const methods = useForm({
    resolver: yupResolver(sendLinkSchemaValidation),
  });

  const { goToCustomerData, navigation } = useNavigation();

  const TIME = 60;
  const {
    secondsLeft, start: startTimer, isActive: isTimerActive,
  } = useCountDown(TIME);

  const handleSubmit = (data) => {
    onSendLink(data);
    startTimer();
  };

  const hasPledgeDocuments = () => (
    // si tiene al menos un documento
    requiredDocuments.find((document) => PLEDGE_DOCS.includes(document.name)) !== undefined
  );

  return (
    <FormControl sx={{ width: '100%', marginTop: '32px' }}>
      <Typography align="left" sx={{ fontSize: '14px', marginBottom: '24px' }}>
        {i18n.DocumentUploadPage.tab1.description}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#F5F5F5',
          padding: { xs: '12px 8px', md: '24px' },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {i18n.DocumentUploadPage.tab1.steps.map((step, index) => (
          <Box
            key={step.description}
            sx={{
              display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <img src={step.icon} alt={`paso_${index}`} />
            <Typography sx={{ fontSize: '12px', marginLeft: '8px' }}>{step.description}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ marginTop: '24px' }}>
        {loanRequest.loadDocumentsByCustomer && (
          <>
            <Typography align="left" sx={{ fontSize: '14px' }}>
              {i18n.DocumentUploadPage.tab1.resendDescription}
            </Typography>
            {isTimerActive && (
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px', fontWeight: 600 }}>
                {fillMessageWith(i18n.DocumentUploadPage.tab1.countdown, secondsLeft)}
              </Typography>
            )}
          </>
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)} style={{ textAlign: 'left' }}>
            <Grid container columnGap="24px" rowGap="24px" sx={{ marginTop: '24px' }}>
              <Grid item xs={12} md="auto">
                <TextInputControlled
                  name="cellphone"
                  label={i18n.DocumentUploadPage.tab1.cellphoneInput}
                  helperText={i18n.DocumentUploadPage.tab1.cellphoneInputHint}
                  variant="outlined"
                  fullWidth={isMobile}
                  mandatory
                  disabled={isTimerActive}
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <ComboBoxControlled
                  name="mode"
                  label={i18n.DocumentUploadPage.tab1.sendMode}
                  options={i18n.DocumentUploadPage.tab1.modeOptions}
                  mandatory
                  disabled={isTimerActive}
                />
              </Grid>
              {loanRequest.loadDocumentsByCustomer && (
                <Grid item xs={12} md="auto">
                  <Button
                    type="submit"
                    variant="secondary"
                    fullWidth={isMobile}
                    loading={loading}
                    disabled={isTimerActive}
                    sx={{ marginTop: { xs: '', md: '8px' } }}
                  >
                    {i18n.DocumentUploadPage.tab1.ctaResendSendLink}
                  </Button>
                </Grid>
              )}
            </Grid>
            {loanRequest.loadDocumentsByCustomer ? (
              <>
                <Alert severity="info" sx={{ marginTop: '26px' }}>
                  <Typography align="left" sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {i18n.DocumentUploadPage.tab1.loadDocumentsByCustomer[0]}
                  </Typography>
                  <Typography align="left" sx={{ fontSize: '14px' }}>{parse(i18n.DocumentUploadPage.tab1.loadDocumentsByCustomer[1])}</Typography>
                </Alert>
                <Box sx={{ marginTop: '32px', textAlign: 'right' }}>
                  <Button
                    variant="primary"
                    color="grey"
                    fullWidth={isMobile}
                    onClick={() => navigation(`/r/${hash}`)}
                    loading={loading}
                  >
                    {i18n.DocumentUploadPage.tab1.refresh}
                  </Button>
                </Box>
              </>
            ) : (
              <Box sx={{ marginTop: '24px', textAlign: 'right' }}>
                {!isMobile && (
                    <Button variant="tertiary" color="grey" onClick={() => goToCustomerData(hash)}>
                    {i18n.DocumentUploadPage.back}
                    </Button>
                )}
                  <Button
                    type="submit"
                    variant="secondary"
                    fullWidth={isMobile}
                    loading={loading}
                    disabled={hasPledgeDocuments()}
                  >
                    {i18n.DocumentUploadPage.tab1.ctaSendLink}
                  </Button>
                {isMobile && (
                    <Button
                      variant="tertiary"
                      color="grey"
                      onClick={() => goToCustomerData(hash)}
                      fullWidth
                      sx={{ marginTop: '12px' }}
                    >
                      {i18n.DocumentUploadPage.back}
                    </Button>
                )}
              </Box>
            )}
          </form>
        </FormProvider>
      </Box>
    </FormControl>
  );
};

LoanDocumentSendContinueLink.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  onSendLink: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  requiredDocuments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

LoanDocumentSendContinueLink.defaultProps = {
  loading: false,
};

export default LoanDocumentSendContinueLink;
