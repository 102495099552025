import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog, Grid, IconButton, Typography,
} from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import usePushNotifications from '../../../hooks/usePushNotifications';

const PushNotificationSubscription = () => {
  const { showSubscription, handleSubscribe, handleOmit } = usePushNotifications();

  return (
    <Dialog
      open={showSubscription}
      onClose={handleOmit}
    >
      <Grid container sx={{ padding: '32px', paddingTop: '24px' }}>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <IconButton sx={{ zIndex: 100 }} onClick={handleOmit}><Close /></IconButton>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Box sx={{ display: 'block' }}>
            <img src="/images/notifications-icon.svg" alt="notificaciones" />
          </Box>
          <Typography sx={{ fontSize: '24px', fontWeight: 700, marginTop: '24px' }}>
            {i18n.PushNotificationSubscription.title}
          </Typography>
          <Typography sx={{ fontSize: '14px', marginTop: '4px' }}>
            {i18n.PushNotificationSubscription.description}
          </Typography>
          <Grid container sx={{ marginTop: '90px' }} direction={{ xs: 'row', md: 'row-reverse' }} spacing={1}>
            <Grid item xs={12} md={6}>
              <Button variant="primary" color="grey" fullWidth onClick={handleSubscribe}>
                {i18n.PushNotificationSubscription.cta[0]}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="secondary"
                color="grey"
                fullWidth
                onClick={() => { handleOmit(); }}
              >
                {i18n.PushNotificationSubscription.cta[1]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PushNotificationSubscription;
