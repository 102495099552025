import { useMediaQuery, useTheme } from '@mui/material';
import Loading from '../../components/commons/Loading';
import useInstallmentPlanController from '../../hooks/controllers/useInstallmentPlanController';
import InstallmentPlanDesktop from './components/InstallmentPlanDesktop';
import InstallmentPlanMobile from './components/InstallmentPlanMobile';
import InstallmentPlanReject from './components/InstallmentPlanReject';

const InstallmentPlanPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const {
    globalLoading,
    loanRequest,
    doReload,
    installments,
    selectedInstallment,
    setSelectedInstallment,
    brandList,
    modelList,
    brand,
    model,
    year,
    onChangeBrand,
    vehicleModelLoading,
    onChangeModel,
    onChangeYear,
    maximumLoanable,
    sendOffer,
    sendOfferLoading,
    getInstallments,
    installmentsLoading,
    requestedAmount,
    advanceAmount,
    choseInstallment,
    selectInstallmentLoading,
    cleanInstallmentPlan,
    vehicleYearsList,
    selectParallelOffer,
    selectCancelOffer,
    handleChangeCancelFlex,
    cancelFlexLoading,
    cancelFlexEmpty,
  } = useInstallmentPlanController();

  if (globalLoading) {
    return <Loading />;
  }

  /* **************** RECHAZADO **************** */
  if (loanRequest && loanRequest.response.rejected) {
    return (
      <InstallmentPlanReject loanRequest={loanRequest} doReload={doReload} />
    );
  }

  if (loanRequest) {
    return (
      <>
        {isMobile ? (
          <InstallmentPlanMobile
            loanRequest={loanRequest}
            doReload={doReload}
            installments={installments}
            selectedInstallment={selectedInstallment}
            setSelectedInstallment={setSelectedInstallment}
            brandList={brandList}
            modelList={modelList}
            brand={brand}
            model={model}
            year={year}
            vehicleYearsList={vehicleYearsList}
            onChangeBrand={onChangeBrand}
            vehicleModelLoading={vehicleModelLoading}
            onChangeModel={onChangeModel}
            onChangeYear={onChangeYear}
            maximumLoanable={maximumLoanable}
            sendOffer={sendOffer}
            sendOfferLoading={sendOfferLoading}
            getInstallments={getInstallments}
            installmentsLoading={installmentsLoading}
            requestedAmount={requestedAmount}
            advanceAmount={advanceAmount}
            choseInstallment={choseInstallment}
            selectInstallmentLoading={selectInstallmentLoading}
            onChangeAmount={cleanInstallmentPlan}
            selectParallelOffer={selectParallelOffer}
            selectCancelOffer={selectCancelOffer}
            onChangeCancelFlex={handleChangeCancelFlex}
            cancelFlexLoading={cancelFlexLoading}
            cancelFlexEmpty={cancelFlexEmpty}
          />
        ) : (
          <InstallmentPlanDesktop
            loanRequest={loanRequest}
            doReload={doReload}
            installments={installments}
            selectedInstallment={selectedInstallment}
            setSelectedInstallment={setSelectedInstallment}
            brandList={brandList}
            modelList={modelList}
            brand={brand}
            model={model}
            year={year}
            vehicleYearsList={vehicleYearsList}
            onChangeBrand={onChangeBrand}
            vehicleModelLoading={vehicleModelLoading}
            onChangeModel={onChangeModel}
            onChangeYear={onChangeYear}
            maximumLoanable={maximumLoanable}
            sendOffer={sendOffer}
            sendOfferLoading={sendOfferLoading}
            getInstallments={getInstallments}
            installmentsLoading={installmentsLoading}
            requestedAmount={requestedAmount}
            advanceAmount={advanceAmount}
            choseInstallment={choseInstallment}
            selectInstallmentLoading={selectInstallmentLoading}
            onChangeAmount={cleanInstallmentPlan}
            selectParallelOffer={selectParallelOffer}
            selectCancelOffer={selectCancelOffer}
            onChangeCancelFlex={handleChangeCancelFlex}
            cancelFlexLoading={cancelFlexLoading}
            cancelFlexEmpty={cancelFlexEmpty}
          />
        )}
      </>
    );
  }

  return <></>;
};

export default InstallmentPlanPage;
