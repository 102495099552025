import * as yup from 'yup';
import i18n from '../common/i18n';

export const sendOfferSchemaValidation = yup.object().shape({
  cellphone: yup.string()
    .matches(/^[0-9]+$/, i18n.ManageBranchesPage.inputPhoneHint)
    .length(10, '')
    .required(i18n.ManageBranchesPage.inputPhoneHint),
});

export const inputAmountSchemaValidation = yup.object().shape({
  amount: yup.string()
    .matches(/^[0-9]+$/, i18n.schemaValidation.amount.format)
    .required(''),
});
