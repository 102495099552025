import { useState } from 'react';
import { useLocation } from 'react-router';
import { useLocalStorage } from '@uidotdev/usehooks';
import { generateBiometricOnboardingData, getStepBiometricData, validateBiometricResult } from '../api/biometricApiService';
import { useToaster } from '../contexts/ToasterContext';
import { getApiErrorMessage } from '../services/errorApiService';
import useNavigation from './useNavigation';
import { IS_FRONT_CAMERA } from '../constants/localStorageVariablesConstants';
import { DISABLE_CAMERA_STEP, INCOMPATIBLE_BROWSER_STEP } from '../constants/biometricConstants';

const useBiometric = () => {
  const { showToast } = useToaster();
  const queryParams = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(false);
  const [noHit, setNoHit] = useState(false);
  const [stepResult, setStepResult] = useState();
  const [loanRequest, setLoanRequest] = useState();
  const [loanRequestHash, setLoanRequestHash] = useState();
  const [flagIsCameraFront, setFlagIsCameraFront] = useLocalStorage(IS_FRONT_CAMERA);

  const { goToUploadDocuments } = useNavigation();

  const createHiddenInput = (name, value) => {
    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = name;
    i.value = value;
    return i;
  };

  const doOnboarding = async (hash, isFrontCamera) => {
    try {
      setLoading(true);
      const { data: onboardingData } = await generateBiometricOnboardingData(hash, isFrontCamera);
      const f = document.createElement('form');
      const method = onboardingData.doGet ? 'get' : 'post';
      let { url } = onboardingData;
      setFlagIsCameraFront(isFrontCamera);
      // the google analytics id
      if (queryParams) {
        url += ((url.toString()).includes('?') ? '&' : '?') + queryParams.toString();
      }

      f.setAttribute('method', method);
      f.setAttribute('action', url);
      f.appendChild(createHiddenInput('idapp', 'App Cliente'));
      f.appendChild(createHiddenInput('seckey', onboardingData.seckey));
      f.appendChild(createHiddenInput('country', 'ARG'));
      f.appendChild(createHiddenInput('idmaqc_service', onboardingData.idmaqcService));
      f.appendChild(createHiddenInput('profile_services', onboardingData.profileServices));
      f.appendChild(createHiddenInput('services', onboardingData.services));
      f.appendChild(createHiddenInput('externaltxid', onboardingData.externalId));
      f.appendChild(createHiddenInput('dni', onboardingData.customerId));
      f.appendChild(createHiddenInput('sexo', onboardingData.sex));
      document.body.appendChild(f);

      // si esta emulado, coloco en el formulario los queryparams, para que viajen en el submit,
      // ya que se pierden solo si estan en el url action del form
      if (onboardingData.doGet) {
        const queryString = url.split('?')[1];
        const params = new URLSearchParams(queryString);
        params.forEach((value, key) => {
          f.appendChild(createHiddenInput(key, value));
        });
      }

      f.submit();
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const processCallbackAndGetResult = async () => {
    try {
      setLoading(true);
      const idtx = queryParams.get('idtx');
      let externalID = queryParams.get('externalID');
      const step = queryParams.get('step');
      if (idtx) {
        const { data: result } = await validateBiometricResult(externalID, idtx, flagIsCameraFront);
        const hash = result.referenceId;

        if (result.decision === 'HIT') {
          goToUploadDocuments(hash);
        } else if (result.decision === 'NO_HIT') {
          setLoanRequest(result.loanRequest);
          setNoHit(true);
          setLoading(false);
        } else if (result.decision === 'ERR_RNP') {
          goToUploadDocuments(hash);
        }
      } else if (step) {
        externalID = queryParams.get('externaltrxid');
        const { data } = await getStepBiometricData(externalID, flagIsCameraFront);
        const hash = data.referenceId;

        if (step === DISABLE_CAMERA_STEP || step === INCOMPATIBLE_BROWSER_STEP) {
          setLoanRequestHash(hash);
          setStepResult(step);
        } else {
          goToUploadDocuments(hash, { selfieOn: data.selfieOn });
        }
      }
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    doOnboarding,
    processCallbackAndGetResult,
    noHit,
    loanRequest,
    stepResult,
    loanRequestHash,
  };
};

export default useBiometric;
