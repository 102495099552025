import { Alert } from '@doit/pcnt-react-ui-library';
import { Typography } from '@mui/material';
import i18n from '../../../../common/i18n';

export const RejectedMessage = () => (
  <Alert severity="warning" style={{ textAlign: 'left' }}>
    <Typography>
      {i18n.FinalPage.Messages.rejected}
    </Typography>
  </Alert>
);

export default RejectedMessage;
