import PropTypes from 'prop-types';
import {
  Autocomplete,
  CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { Divider } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';

const InstallmentPlanPledgeSection = ({
  brandList, modelList, brand, model, year, onChangeBrand, vehicleModelLoading, onChangeModel, onChangeYear, maximumLoanable, vehicleYearsList,
}) => (
  <>
    <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.InstallmentPlanPage.vehicleSelection.title}</Typography>
    <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '32px' }}>
      {i18n.InstallmentPlanPage.vehicleSelection.description}
    </Typography>
    <Grid container columnGap="24px" rowGap="20px">
      <Grid item xs={12} md={4}>
        <Autocomplete
          disablePortal
          options={brandList}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => onChangeBrand(value)}
          disableClearable
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          noOptionsText=""
          renderInput={(params) => (
            <TextField {...params} label={i18n.InstallmentPlanPage.vehicleSelection.brand} />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ textAlign: 'left' }}>{option.name}</li>
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          key={brand}
          disablePortal
          options={modelList}
          getOptionKey={(option) => option.codia}
          getOptionLabel={(option) => option.description}
          onChange={(e, value) => onChangeModel(value)}
          disableClearable
          isOptionEqualToValue={(option, value) => option.codia === value?.codia}
          noOptionsText=""
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n.InstallmentPlanPage.vehicleSelection.model}
              InputProps={{
                ...params.InputProps,
                startAdornment: vehicleModelLoading && <InputAdornment position="start"><CircularProgress size={20} /></InputAdornment>,
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ textAlign: 'left' }}>{option.description}</li>
          )}
        />
      </Grid>
      {vehicleYearsList && (
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="year-select-label">{i18n.InstallmentPlanPage.vehicleSelection.year}</InputLabel>
            <Select
              key={model}
              labelId="year-select-label"
              id="year-select"
              value={year || 'none'}
              label={i18n.InstallmentPlanPage.vehicleSelection.year}
              onChange={onChangeYear}
            >
              <MenuItem value="none">{i18n.InstallmentPlanPage.vehicleSelection.yearEmptyOption}</MenuItem>
              {vehicleYearsList?.map((v) => (
                <MenuItem value={v.year} key={v.year}>{v.year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
    {maximumLoanable && (
      <>
        <Typography
          align="left"
          sx={{
            fontSize: '16px', fontWeight: 600, marginTop: '41px', display: 'flex',
          }}
        >
          {fillMessageWith(i18n.InstallmentPlanPage.vehicleSelection.maxAmount, moneyFormatter(maximumLoanable))}
          <Tooltip title={i18n.InstallmentPlanPage.vehicleSelection.maxAmountTooltip}>
            <HelpOutline sx={{ marginLeft: '10px', color: '#424242' }} />
          </Tooltip>
        </Typography>
        <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>
          {i18n.InstallmentPlanPage.vehicleSelection.maxAmountHint}
        </Typography>
      </>
    )}
    <Divider sx={{ marginTop: '32px', marginBottom: '32px' }} />
  </>
);

InstallmentPlanPledgeSection.propTypes = {
  brandList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  vehicleYearsList: PropTypes.arrayOf(PropTypes.shape()),
  brand: PropTypes.number,
  model: PropTypes.number,
  year: PropTypes.number,
  onChangeBrand: PropTypes.func.isRequired,
  vehicleModelLoading: PropTypes.bool,
  onChangeModel: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  maximumLoanable: PropTypes.number,
};

InstallmentPlanPledgeSection.defaultProps = {
  brand: undefined,
  model: undefined,
  year: undefined,
  vehicleModelLoading: false,
  maximumLoanable: undefined,
  vehicleYearsList: undefined,
};

export default InstallmentPlanPledgeSection;
