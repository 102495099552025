import { fetch } from './axiosInstance';

export const generateBiometricOnboardingData = (hash, isFrontCamera) =>
  fetch({
    url: `/workflow/4id/${hash}?newOnboarding=true&isFrontCamera=${isFrontCamera}`,
    method: 'get',
  });

export const validateBiometricResult = (externalId, txid, flagIsCameraFront) =>
  fetch({
    url: `/workflow/4id/externalid/${externalId}/txid/${txid}?flagIsCameraFront=${flagIsCameraFront}`,
    method: 'post',
  });

export const getStepBiometricData = (externalId, flagIsCameraFront) =>
  fetch({
    url: `/workflow/4id/externalid/${externalId}?flagIsCameraFront=${flagIsCameraFront}`,
    method: 'get',
  });
