import { useEffect, useState } from 'react';
import {
  Navigate, Route, Routes, useLocation,
} from 'react-router';
import { useIdle } from '@uidotdev/usehooks';
import LoggedRoute from './LoggedRoute';
import DashboardLayoutComponent from '../pages/dashboard/components/DashboardLayoutComponent';
import { privateRoutes } from '../constants/routesConstants';
import RestrictedRoute from './RestrictedRoute';
import { menuRestrictions } from '../utils/menuUtils';
import QuotationPage from '../pages/dashboard/quotationPage';
import InstallmentPlanPage from '../pages/loanRequest/installmentPlanPage';
import CustomerDataPage from '../pages/loanRequest/customerDataPage';
import FinalPage from '../pages/loanRequest/finalPage';
import ManageBranchesPage from '../pages/commerceAffiliation/manageBranchesPage';
import DocumentUploadPage from '../pages/loanRequest/documentUploadPage';
import SignaturePage from '../pages/loanRequest/signaturePage';
import BiometricResultPage from '../pages/loanRequest/biometricResultPage';
import LoanRequestsPage from '../pages/dashboard/loanRequestsPage';
import RedirectPage from '../pages/loanRequest/redirectPage';
import useWebChat from '../hooks/useWebChat';
import SettlementsPage from '../pages/dashboard/SettlementsPage';
import useLogout from '../hooks/useLogout';
import { authenticate } from '../api/authenticationApiService';
import HomePage from '../pages/dashboard/HomePage';
import PushNotificationMessage from '../components/commons/PushNotificationMessage';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoutes = () => {
  useWebChat();
  const { loggedUser, saveLoggedUser } = useAuth();
  const INACTIVITY_TIMEOUT = useIdle(1000 * 60 * 60 * 5); // 5 horas
  const { doLogout } = useLogout();
  const [expired, setExpired] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Si deja la pantalla abierta sin actividad durante 5 horas, se cierra la sesion
    if (INACTIVITY_TIMEOUT) {
      setExpired(true);
      doLogout();
    }
  }, [INACTIVITY_TIMEOUT]);

  const doAuthenticate = async () => {
    const { data } = await authenticate();
    if (!loggedUser) {
      saveLoggedUser(data);
    }
  };

  useEffect(() => {
    // En cada cambio de ruta, se chequea si el usuario esta autenticado
    // para chequear si esta o no expirada la session en el backend
    doAuthenticate();
  }, [location]);

  return (
    <>
      <PushNotificationMessage />
      <Routes>
        <Route path="/" element={<LoggedRoute expired={expired}><DashboardLayoutComponent /></LoggedRoute>}>
          <Route
            path={privateRoutes.home}
            element={<RestrictedRoute condition={menuRestrictions[privateRoutes.home]} element={<HomePage />} />}
          />
          <Route
            path={privateRoutes.newLoanRequest}
            element={<RestrictedRoute condition={menuRestrictions[privateRoutes.newLoanRequest]} element={<QuotationPage />} />}
          />
          <Route
            path={privateRoutes.loanRequests}
            element={<RestrictedRoute condition={menuRestrictions[privateRoutes.loanRequests]} element={<LoanRequestsPage />} />}
          />
          <Route
            path={privateRoutes.liquidations}
            element={<RestrictedRoute condition={menuRestrictions[privateRoutes.liquidations]} element={<SettlementsPage />} />}
          />
          <Route
            path={privateRoutes.manageBranches}
            element={<RestrictedRoute condition={menuRestrictions[privateRoutes.manageBranches]} element={<ManageBranchesPage />} />}
          />
          <Route path={privateRoutes.redirect[0]} element={<RedirectPage />} />
          <Route path={privateRoutes.installmentPlan} element={<InstallmentPlanPage />} />
          <Route path={privateRoutes.customerData} element={<CustomerDataPage />} />
          <Route path={privateRoutes.documentUpload} element={<DocumentUploadPage />} />
          <Route path={privateRoutes.biometricResult} element={<BiometricResultPage />} />
          <Route path={privateRoutes.signature} element={<SignaturePage />} />
          <Route path={privateRoutes.final} element={<FinalPage />} />
          <Route path="/" element={<Navigate to={privateRoutes.home} replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default ProtectedRoutes;
