import parse from 'html-react-parser';
import {
  Button, CardContent, Divider,
} from '@doit/pcnt-react-ui-library';
import {
  Box, Grid, Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import i18n from '../../common/i18n';
import { fillMessageWith } from '../../utils/functionsUtils';
import Card from '../../components/commons/Card';
import Loading from '../../components/commons/Loading';
import useFinalController from '../../hooks/controllers/useFinalController';
import { ManualSuccessMessage } from './components/messages/manualSuccessMessage';
import { ManualWarningMessage } from './components/messages/manualWarningMessage';
import { ImportantSuccessMessage } from './components/messages/importantSuccessMessage';
import { RejectedMessage } from './components/messages/rejectedMessage';
import { WelcomeAutomaticMessage } from './components/messages/welcomeAutomaticMessage';
import { AutomaticLiqMessage } from './components/messages/automaticLiqMessage';
import { moneyFormatterWithoutDecimals, dateFormatter, capitalizeEachWord } from '../../utils/formatterUtils';
// import NpsForm from './components/NpsForm';

const FinalPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const {
    globalLoading,
    loanRequest,
    documents,
    isApproved,
    isProductToPayVehicleType,
    isPledgeDocCompleted,
    showDanger,
    goHome,
    downloadVoucher,
    downloadDocs,
    onlyRequiredAndNotSettedFilterFunction,
    showManualSuccessMessage,
    // showSurvey,
    // setShowSurvey,
    // surveyInfo,
  } = useFinalController();

  const getContent = () => (
    <>
      {/* HEADER */}
      <CardContent style={{ backgroundColor: showDanger ? '#F3D34A' : '#E6F9F0' }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <img
              src={showDanger ? '/images/icono-danger.svg' : '/images/icono-success.svg'}
              alt="icon"
              style={{ width: '80px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '32px', fontWeight: 700 }}>
              {isApproved ? i18n.FinalPage.approved : i18n.FinalPage.preApproved}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 700 }}>
              {fillMessageWith(i18n.FinalPage.creditNumber, loanRequest.sappCreditId)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      {/* LOAN DATA */}
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={10} md={8}>
            <Grid container>
              <Grid item xs="auto" display="flex" alignItems="left" key="Fecha">
                <Typography>
                  {parse(fillMessageWith(i18n.FinalPage.LoanData.date, dateFormatter(loanRequest.date)))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} md={8}>
            <Grid container columnSpacing={4}>
              <Grid item xs="auto" display="flex" alignItems="left" key="Nombre">
                <Typography align="left">
                  {parse(fillMessageWith(i18n.FinalPage.LoanData.name, capitalizeEachWord(loanRequest.customerName)))}
                </Typography>
              </Grid>
              <Grid item xs="auto" display="flex" alignItems="left" key="DNI">
                <Typography align="right">
                  {parse(fillMessageWith(i18n.FinalPage.LoanData.dni, loanRequest.customerId.substring(2, 10)))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} md={8}>
            <Divider sx={{ marginBottom: '16px' }} />
            <Grid container columnSpacing={8}>
              <Grid item xs={12} md={4} key="Monto-Solicitado" display="flex" alignItems="left">
                <Box display={isMobile ? 'contents' : 'block'} sx={{ marginTop: { xs: '16px', md: '' } }}>
                  <Typography>
                    {i18n.FinalPage.LoanData.requestedAmount}
                  </Typography>
                  <Typography style={{ fontWeight: 700 }}>
                    {isMobile && ': '}
                    {moneyFormatterWithoutDecimals(loanRequest.requestedAmount)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} key="Cantidad-Cuotas" display="flex" alignItems="center">
                <Box display={isMobile ? 'inline-flex' : 'block'} sx={{ marginTop: { xs: '16px', md: '' } }}>
                  <Typography>
                    {i18n.FinalPage.LoanData.numberOfInstallments}
                  </Typography>
                  <Typography style={{ fontWeight: 700 }}>
                    {isMobile && ': '}
                    {loanRequest.installmentsNumberSelected}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} key="Monto-Cuota" display="flex" alignItems="right">
                <Box display={isMobile ? 'inline-flex' : 'block'} sx={{ marginTop: { xs: '16px', md: '' } }}>
                  <Typography>
                    {i18n.FinalPage.LoanData.installmentAmount}
                  </Typography>
                  <Typography style={{ fontWeight: 700 }}>
                    {isMobile && ': '}
                    {moneyFormatterWithoutDecimals(loanRequest.installmentAmountSelected)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      {/* MESSAGES */}
      { (showManualSuccessMessage || showDanger || loanRequest.saleCompletionRejected
        || loanRequest.saleCompletionWelcomeAutomatic || loanRequest.saleCompletionAutomatic
        || (loanRequest.customerDataCompleted && loanRequest.documentsCompleted && !loanRequest.isPaperless)) && (
        <CardContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={10} md={8}>
              {/* MENSAJE Manual con Éxito */}
              {!showDanger && loanRequest.saleCompletionManual && (
                <ManualSuccessMessage
                  loanRequest={loanRequest}
                  showManualSuccessMessage={showManualSuccessMessage}
                  isProductToPayVehicleType={isProductToPayVehicleType}
                  isPledgeDocCompleted={isPledgeDocCompleted}
                />
              )}

              {/* MENSAJE Manual con Warning */}
              {showDanger && loanRequest.saleCompletionManual && (
                <ManualWarningMessage
                  loanRequest={loanRequest}
                  documents={documents}
                  onlyRequiredAndNotSettedFilterFunction={onlyRequiredAndNotSettedFilterFunction}
                />
              )}

              {/* MENSAJE RECHAZADO */}
              {loanRequest.saleCompletionRejected && <RejectedMessage />}

              {/* MENSAJE Bienvenida Automatica */}
              {loanRequest.saleCompletionWelcomeAutomatic && <WelcomeAutomaticMessage />}

              {/* MENSAJE LIQ Automatica */}
              {loanRequest.saleCompletionAutomatic && (
                <AutomaticLiqMessage loanRequest={loanRequest} />
              )}

              {/* MENSAJE exito Importante */}
              {loanRequest.customerDataCompleted && loanRequest.documentsCompleted && !loanRequest.isPaperless && (
                <ImportantSuccessMessage />
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}

      {/* ACTIONS */}
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <Button variant="secondary" fullWidth color="grey" onClick={goHome}>
              {i18n.FinalPage.CTA.goHome}
            </Button>
          </Grid>
          {loanRequest.isPaperless && loanRequest.collectionModeId !== 3 && (
            <Grid item xs={12} md={4}>
              <Button variant="primary" fullWidth color="grey" onClick={downloadVoucher}>
                {i18n.FinalPage.CTA.downloadVoucher}
              </Button>
            </Grid>
          )}
          {!loanRequest.isPaperless && (
            <Grid item xs={12} md={4}>
              <Button variant="primary" fullWidth color="grey" onClick={downloadDocs}>
                {i18n.FinalPage.CTA.downloadDocs}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </>
  );

  if (globalLoading) {
    return <Loading />;
  }

  if (loanRequest) {
    return (
      <>
        {isMobile ? (
          <Grid container sx={{ backgroundColor: '#FFF' }}>
            <Grid item xs={12}>
              {getContent()}
            </Grid>
          </Grid>
        ) : (
          <Card style={{ padding: '0px' }}>
            {getContent()}
          </Card>
        )}

        {/* ********** NPS ********** */}
        {/* <Dialog
          open={showSurvey}
          onClose={() => setShowSurvey(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <NpsForm
            surveyInfo={surveyInfo}
            onFinish={() => setShowSurvey(false)}
          />
        </Dialog> */}
      </>
    );
  }

  return <></>;
};

export default FinalPage;
