import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import useNavigation from '../useNavigation';
import { useInstallApp } from '../../contexts/InstallAppContext';
import i18n from '../../common/i18n';
import { useToaster } from '../../contexts/ToasterContext';
import { INCENTIVE_INSTALL_APP_NEXT_DATE } from '../../constants/localStorageVariablesConstants';

const useSplashController = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const queryParams = new URLSearchParams(useLocation().search);
  const { goToLoginPage, goToInstallApp } = useNavigation();
  const { showToast } = useToaster();
  const [incentiveInstallAppNextDate] = useLocalStorage(INCENTIVE_INSTALL_APP_NEXT_DATE);

  const { install } = useInstallApp();

  const [expanded, setExpanded] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const onAnimationEnd = () => {
    setTimeout(() => {
      setFadeOut(true);
    }, 1000);
    setTimeout(() => {
      const showIncentive = incentiveInstallAppNextDate ? new Date() > new Date(incentiveInstallAppNextDate) : true;
      if (showIncentive) {
        if (isMobile) {
          if (install) {
            goToInstallApp();
          } else {
            goToLoginPage();
          }
        } else {
          goToInstallApp();
        }
      } else {
        goToLoginPage();
      }
    }, 2000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (queryParams.has('expired')) {
      showToast(i18n.Login.sessionExpired, 'error');
      queryParams.delete('expired');
    }
    setTimeout(() => {
      setExpanded(true);
    }, 100);
  }, []);

  return {
    expanded,
    onAnimationEnd,
    fadeOut,
  };
};

export default useSplashController;
