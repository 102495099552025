import Axios from 'axios';
import qs from 'qs';
import { getEnv, CREDICUOTAS_COMMERCE_BACKEND_URL } from './env';
import { LOGGED_USER } from '../constants/localStorageVariablesConstants';

const urlBackend = getEnv(CREDICUOTAS_COMMERCE_BACKEND_URL);

export const instance = Axios.create({
  timeout: 60000,
  baseURL: `${urlBackend}`,
  withCredentials: true,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const fetch = (config) =>
  instance.request(config)
    .then((res) => res)
    .catch((err) => {
      if (err?.response?.status === 401 || err?.response?.status === 503) {
        if (localStorage.getItem(LOGGED_USER) !== null) {
          localStorage.removeItem(LOGGED_USER);
          window.location = '/splash?expired';
          return;
        }
      }
      throw err;
    });

export const instanceMultipart = Axios.create({
  timeout: 60000,
  baseURL: `${urlBackend}`,
  withCredentials: true,
  headers: { 'content-type': 'multipart/form-data' },
});

export const fetchMultipart = (config) => instanceMultipart.request(config)
  .then((res) => res)
  .catch((err) => {
    if (err?.response?.status === 401 || err?.response?.status === 503) {
      if (localStorage.getItem(LOGGED_USER) !== null) {
        localStorage.removeItem(LOGGED_USER);
        window.location = '/login?expired';
      }
    }
    throw err;
  });

export default fetch;
