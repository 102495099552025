import PropTypes from 'prop-types';
import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import useNavigation from '../../../hooks/useNavigation';
import useCommonStyles from '../../../common/styles/useCommonStyles';

const BiometricDisableCamera = ({ hash }) => {
  const classes = useCommonStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToUploadDocuments } = useNavigation();
  return (
    <Grid container sx={{ padding: '40px 24px', height: '100vh' }}>
      <Grid item xs={12}>
        <img src="/images/camera-icon.svg" alt="Habilitar camara" />
        <Typography sx={{ marginTop: '24px', fontSize: '24px', fontWeight: 600 }}>{i18n.BiometricDisableCamera.title}</Typography>
        <Typography sx={{ marginTop: '12px', fontSize: '14px' }}>{i18n.BiometricDisableCamera.description[0]}</Typography>
        <Typography sx={{ marginTop: '32px', fontSize: '14px', fontWeight: 600 }}>{i18n.BiometricDisableCamera.description[1]}</Typography>
        <Box className={classes.ctaContainer}>
          <Button variant="secondary" color="grey" onClick={() => goToUploadDocuments(hash)} fullWidth={isMobile}>
            {i18n.BiometricDisableCamera.cta}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

BiometricDisableCamera.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default BiometricDisableCamera;
