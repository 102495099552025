import { Alert, Snackbar } from '@mui/material';
import i18n from '../common/i18n';
import { logClientError } from '../api/workflowApiService';

/* eslint-disable no-shadow */
const useErrorHandler = () => {
  const logError = (error, info) => {
    try {
      const { userAgent } = window.navigator;
      const url = window.location.href;
      const stackTrace = info.componentStack;
      const message = `\nCLIENT_CONSOLE_ERROR: ${error} \n- USER_AGENT: ${userAgent} \n- URL: ${url} \n- STACK_TRACE: ${stackTrace}`;
      logClientError(message);
    } catch (error) {
      // do nothing
    }
  };

  const handleClose = () => {
    window.location = '/';
  };

  const consoleErrorFallbackComponent = () => (
    <Snackbar
      open
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error">
        {i18n.ErrorHandler.message}
      </Alert>
    </Snackbar>
  );

  return {
    logError,
    consoleErrorFallbackComponent,
  };
};

export default useErrorHandler;
