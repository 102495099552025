import {
  Box, IconButton, Snackbar, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react';
import { onMessageListener } from '../../utils/firebase';

const useStyles = makeStyles(() => ({
  notification: {
    padding: '16px',
    backgroundColor: '#FDF2F7',
    border: '1px solid #DA0067',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: 10000,
  },
}));

const PushNotificationMessage = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [notification, setNotification] = useState();

  onMessageListener().then((payload) => {
    setNotification(payload.data);
    setTimeout(() => setOpen(false), 7000);
  });

  const handleClick = () => {
    if (notification.redirect) {
      window.open(notification.redirect, '_blank');
    }
  };

  return (
    <>
      {notification && (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ cursor: notification.redirect ? 'pointer' : 'default' }}
        >
          <Box className={`${classes.notification} animate__animated animate__slideInDown`} onClick={handleClick}>
            <NotificationsNoneOutlinedIcon sx={{ color: '#DA0067', marginRight: '10px' }} />
            <Box>
              <Typography align="left" sx={{ fontSize: '14px', fontWeight: 700 }}>{notification.title}</Typography>
              <Typography align="left" sx={{ fontSize: '14px' }}>{notification.body}</Typography>
            </Box>
            <IconButton onClick={() => setOpen(false)} sx={{ padding: '0px', marginLeft: '15px' }}><CloseOutlinedIcon /></IconButton>
          </Box>
        </Snackbar>
      )}
    </>
  );
};

export default PushNotificationMessage;
