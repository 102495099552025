import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../common/i18n';
import TextInputControlled from '../../components/commons/TextInputControlled';
import useLoginController from '../../hooks/controllers/useLoginController';
import { loginSchemaValidation } from '../../forms/loginSchemaValidations';
import CheckboxControlled from '../../components/commons/CheckboxControlled';
import { LOGIN_ERROR_CODE_E001, LOGIN_ERROR_CODE_E008 } from '../../constants/apiErrorCodeConstants';
import LoginLayoutComponent from './components/LoginLayoutComponent';
import PasswordInputControlled from '../../components/commons/PasswordInputControlled';

const LoginPage = () => {
  const {
    loading, rememberedUsername, doLogin, authenticationError, goToForgotPassword,
  } = useLoginController();
  const methods = useForm({
    resolver: yupResolver(loginSchemaValidation),
  });

  return (
    <LoginLayoutComponent>
      <Typography variant="h1" align="left" sx={{ marginBottom: '12px' }}>
        {i18n.Login.title}
      </Typography>
      <Typography align="left" sx={{ marginBottom: '28px' }}>
        {i18n.Login.description}
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(doLogin)}>
          {authenticationError && (
            <Alert severity="error" sx={{ marginBottom: '28px' }}>
              <Typography align="left" sx={{ fontSize: '14px' }}><b>{authenticationError.description}</b></Typography>
              {(authenticationError.code === LOGIN_ERROR_CODE_E001 || authenticationError.code === LOGIN_ERROR_CODE_E008) && (
                <Typography align="left" sx={{ fontSize: '14px' }}>{i18n.Login.reviewInfoMessage}</Typography>
              )}
            </Alert>
          )}
          <TextInputControlled
            name="user"
            label={i18n.Login.userInput}
            defaultValue={rememberedUsername}
            variant="outlined"
            fullWidth
            mandatory
            sx={{ marginBottom: '12px' }}
          />
          <PasswordInputControlled
            name="pass"
            label={i18n.Login.passInput}
            variant="outlined"
            fullWidth
            mandatory
          />
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: '24px' }}>
            <CheckboxControlled name="remember" label={i18n.Login.rememberMe} defaultValue={!!rememberedUsername} />
            <Button variant="tertiary" onClick={goToForgotPassword}>{i18n.Login.forgotPass}</Button>
          </Box>
          <Button loading={loading} variant="contained" type="submit" fullWidth sx={{ marginTop: '24px' }}>
            {i18n.Login.loginCTA}
          </Button>
        </form>
      </FormProvider>
    </LoginLayoutComponent>
  );
};

export default LoginPage;
