import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import QRCode from 'react-qr-code';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import { useInstallApp } from '../../contexts/InstallAppContext';
import { CREDICUOTAS_COMMERCE_WEB_URL, getEnv } from '../../api/env';
import i18n from '../../common/i18n';
import useNavigation from '../../hooks/useNavigation';

const InstallAppPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToLoginPage } = useNavigation();
  const {
    omit, doInstall, installing, installed,
  } = useInstallApp();

  const handleOmit = () => {
    omit();
    goToLoginPage();
  };

  const renderMobile = () => (
    <Grid container justifyContent="center" sx={{ backgroundColor: 'white' }}>
      <Grid item xs={12} sm={12}>
        <img src="/images/install-app-mobile.png" alt="mobile" style={{ width: '100%', display: 'block' }} />
      </Grid>
      <Grid item xs={10} sm={10} sx={{ height: '100vh' }}>
        <Typography
          sx={{
            textAlign: 'center', fontSize: '32px', fontWeight: 700, marginTop: '24px',
          }}
        >
          {i18n.InstallAppPage.title}
        </Typography>
        <Typography sx={{ marginTop: '4px', fontSize: '16px', textAlign: 'center' }}>{i18n.InstallAppPage.description[1]}</Typography>
        {installing ? (
          <Alert severity="info" sx={{ marginTop: '24px' }}>
            {i18n.InstallAppPage.installing}
          </Alert>
        ) : (
          <Box
            sx={{
              position: 'fixed', bottom: '0px', left: '0px', padding: '16px', width: 'fill-available',
            }}
          >
            {installed ? (
              <Button
                variant="primary"
                color="grey"
                fullWidth
                endIcon={<KeyboardArrowRightIcon />}
                style={{ marginTop: '40px' }}
                onClick={goToLoginPage}
              >
                {i18n.InstallAppPage.cta[2]}
              </Button>
            ) : (
              <>
                <Button
                  variant="primary"
                  color="grey"
                  fullWidth
                  endIcon={<DownloadIcon />}
                  style={{ marginTop: '40px' }}
                  onClick={doInstall}
                >
                  {i18n.InstallAppPage.cta[0]}
                </Button>
                <Button
                  variant="secondary"
                  color="grey"
                  fullWidth
                  style={{ marginTop: '8px' }}
                  onClick={handleOmit}
                >
                  {i18n.InstallAppPage.cta[1]}
                </Button>
              </>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );

  const renderDesktop = () => (
    <Grid container sx={{ height: '100vh', backgroundColor: 'white' }}>
      <Grid item md={5} alignContent="center" sx={{ height: '100%' }}>
        <img src="/images/install-app-desktop.png" alt="phone" style={{ height: '100%', display: 'block' }} />
      </Grid>
      <Grid item md={6} alignContent="center">
        <Typography sx={{ textAlign: 'center', fontSize: '40px', fontWeight: 700 }}>{i18n.InstallAppPage.title}</Typography>
        <Typography
          style={{
            textAlign: 'center', fontSize: '20px', marginTop: '8px', marginBottom: '32px',
          }}
        >
          {i18n.InstallAppPage.description[0]}
        </Typography>
        <QRCode size={200} value={getEnv(CREDICUOTAS_COMMERCE_WEB_URL)} />
        <Button
          variant="secondary"
          color="grey"
          fullWidth
          onClick={handleOmit}
          style={{ marginTop: '52px' }}
        >
          {i18n.InstallAppPage.cta[1]}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      {isMobile ? renderMobile() : renderDesktop()}
    </>
  );
};

export default InstallAppPage;
