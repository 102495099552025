import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Divider } from '@doit/pcnt-react-ui-library';
import InstallmentPlanHeaderSection from './InstallmentPlanHeaderSection';
import StepHeader from './StepHeader';
import InstallmentPlanRequiredDocuments from './InstallmentPlanRequiredDocuments';
import { getAvailableAmount, getPrePaidAmount, isProductToPayVehicle } from '../../../utils/loanRequestUtils';
import InstallmentPlanPledgeSection from './InstallmentPlanPledgeSection';
import InstallmentPlanSendOffer from './InstallmentPlanSendOffer';
import InstallmentPlanInputAmountForm from './InstallmentPlanInputAmountForm';
import InstallmentPlanList from './InstallmentPlanList';
import PremiumCustomerModal from './PremiumCustomerModal';
import StepsLoanRequest from './StepsLoanRequest';
import i18n from '../../../common/i18n';
import DualOfferCard from './DualOfferCard';
import CancelableFlexibleLoan from './CancelableFlexibleLoan';

const InstallmentPlanMobile = ({
  loanRequest,
  doReload,
  installments,
  selectedInstallment,
  setSelectedInstallment,
  brandList,
  modelList,
  brand,
  model,
  year,
  onChangeBrand,
  vehicleModelLoading,
  onChangeModel,
  onChangeYear,
  maximumLoanable,
  sendOffer,
  sendOfferLoading,
  getInstallments,
  installmentsLoading,
  requestedAmount,
  advanceAmount,
  choseInstallment,
  selectInstallmentLoading,
  onChangeAmount,
  vehicleYearsList,
  selectParallelOffer,
  selectCancelOffer,
  onChangeCancelFlex,
  cancelFlexLoading,
  cancelFlexEmpty,
}) => (
  <>
    <StepsLoanRequest stepActive={i18n.StepsLoanRequest[1]} />
    <Grid container sx={{ paddingLeft: '24px', paddingRight: '24px', backgroundColor: '#FFF' }}>
      <Grid item xs={12}>
        <InstallmentPlanHeaderSection loanRequest={loanRequest} onReload={doReload} isDualOffer={loanRequest.maxAmountDual > 0} />
        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
        {/* OFERTA DUAL */}
        {loanRequest.maxAmountDual && (
          <>
            <Grid container gap={2} sx={{ marginBottom: '32px' }}>
              <Grid item md={5}>
                <DualOfferCard
                  labels={i18n.InstallmentPlanPage.parallelOffer}
                  selected={loanRequest.parallelOfferSelected}
                  onSelect={selectParallelOffer}
                  amount={loanRequest.maxAmountDual}
                />
              </Grid>
              <Grid item md={5}>
                <DualOfferCard
                  labels={i18n.InstallmentPlanPage.cancelOffer}
                  selected={loanRequest.cancelOfferSelected}
                  onSelect={selectCancelOffer}
                  amount={getAvailableAmount(loanRequest)}
                  cancelAmount={getPrePaidAmount(loanRequest)}
                />
              </Grid>
            </Grid>
            {loanRequest.cancelOfferSelected && loanRequest.showCancelablesFlex && (
              <CancelableFlexibleLoan
                loanRequest={loanRequest}
                onChange={onChangeCancelFlex}
                loading={cancelFlexLoading}
                cancelFlexEmpty={cancelFlexEmpty}
              />
            )}
          </>
        )}

        <StepHeader loanRequest={loanRequest} />
        {!loanRequest.response.rejected
          && (loanRequest.response.prePaidLoan || loanRequest.customerInActiveCampaign)
          ? <PremiumCustomerModal /> : <></>}
        {/* DOCUMENTOS REQUERIDOS */}
        <InstallmentPlanRequiredDocuments loanRequest={loanRequest} />

        {/* FORMULARIO PARA ENVIAR OFERTA AL CLIENTE */}
        <InstallmentPlanSendOffer onSubmit={sendOffer} loading={sendOfferLoading} />

        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

        {/* SECCION MARCA Y MODELO DE PRENDARIOS */}
        {isProductToPayVehicle(loanRequest) && (
          <InstallmentPlanPledgeSection
            brandList={brandList}
            modelList={modelList}
            vehicleYearsList={vehicleYearsList}
            brand={brand}
            model={model}
            year={year}
            onChangeBrand={onChangeBrand}
            vehicleModelLoading={vehicleModelLoading}
            onChangeModel={onChangeModel}
            onChangeYear={onChangeYear}
            maximumLoanable={maximumLoanable}
          />
        )}

        {/* SECCION INGRESO DE MONTO */}
        <InstallmentPlanInputAmountForm
          onSubmit={getInstallments}
          loading={installmentsLoading}
          canBack={!(installments && installments.length > 0)}
          onChange={onChangeAmount}
          loanRequest={loanRequest}
          maximumLoanable={isProductToPayVehicle(loanRequest) ? maximumLoanable : undefined}
          cancelFlexEmpty={cancelFlexEmpty}
        />

        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

        {/* SECCION LISTADO DE CUOTAS */}
        {installments && installments.length > 0 && (
          <InstallmentPlanList
            installments={installments}
            selectedInstallment={selectedInstallment}
            setSelectedInstallment={setSelectedInstallment}
            requestedAmount={requestedAmount}
            advanceAmount={advanceAmount}
            onSubmit={choseInstallment}
            loading={selectInstallmentLoading}
          />
        )}
      </Grid>
    </Grid>
  </>
);

InstallmentPlanMobile.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  doReload: PropTypes.func.isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape()),
  selectedInstallment: PropTypes.shape(),
  setSelectedInstallment: PropTypes.func.isRequired,
  brandList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  brand: PropTypes.number,
  model: PropTypes.number,
  year: PropTypes.number,
  onChangeBrand: PropTypes.func.isRequired,
  vehicleModelLoading: PropTypes.bool,
  onChangeModel: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  maximumLoanable: PropTypes.number,
  sendOffer: PropTypes.func.isRequired,
  sendOfferLoading: PropTypes.bool,
  getInstallments: PropTypes.func.isRequired,
  installmentsLoading: PropTypes.bool,
  requestedAmount: PropTypes.number,
  advanceAmount: PropTypes.number,
  choseInstallment: PropTypes.func.isRequired,
  selectInstallmentLoading: PropTypes.bool,
  onChangeAmount: PropTypes.func.isRequired,
  vehicleYearsList: PropTypes.arrayOf(PropTypes.shape()),
  selectParallelOffer: PropTypes.func.isRequired,
  selectCancelOffer: PropTypes.func.isRequired,
  onChangeCancelFlex: PropTypes.func.isRequired,
  cancelFlexLoading: PropTypes.bool,
  cancelFlexEmpty: PropTypes.bool,
};

InstallmentPlanMobile.defaultProps = {
  installments: [],
  selectedInstallment: undefined,
  brand: undefined,
  model: undefined,
  year: undefined,
  vehicleModelLoading: false,
  maximumLoanable: undefined,
  advanceAmount: undefined,
  sendOfferLoading: false,
  installmentsLoading: false,
  selectInstallmentLoading: false,
  requestedAmount: undefined,
  vehicleYearsList: undefined,
  cancelFlexLoading: false,
  cancelFlexEmpty: false,
};

export default InstallmentPlanMobile;
