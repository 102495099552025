import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Box, Typography } from '@mui/material';
import { Checkbox, Divider, FormGroup } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter, moneyFormatterWithoutDecimals } from '../../../utils/formatterUtils';

const CancelableFlexibleLoan = ({
  loanRequest, onChange, loading, cancelFlexEmpty,
}) => {
  const calculateCancelableFlexAmount = () => {
    const totalAmount = loanRequest.loanToBePrePaid.reduce((sum, loan) => sum + loan.amount, 0);
    return Math.ceil(totalAmount);
  };

  return (
    <>
      <Typography align="left" sx={{ fontSize: '16px', fontWeight: 700 }}>
        {
          loanRequest.loanToBePrePaid?.length > 1
            ? i18n.InstallmentPlanPage.cancelableFlex.title[0]
            : i18n.InstallmentPlanPage.cancelableFlex.title[1]
        }
      </Typography>
      <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>
        {
          loanRequest.loanToBePrePaid?.length > 1
            ? fillMessageWith(
              i18n.InstallmentPlanPage.cancelableFlex.description[0],
              loanRequest.loanToBePrePaid?.length,
              moneyFormatter(calculateCancelableFlexAmount()),
            )
            : fillMessageWith(
              i18n.InstallmentPlanPage.cancelableFlex.description[1],
              moneyFormatter(calculateCancelableFlexAmount()),
            )
        }
      </Typography>
      {loanRequest.loanToBePrePaid?.length === 1 && (
        <Box sx={{ marginTop: '24px' }}>
          {loanRequest.loanToBePrePaid.map((loan) => (
            <Typography align="left" sx={{ fontSize: '14px' }} key={loan.loanSappId} display="flex" alignItems="center">
              <Box
                style={{
                  width: '4px', height: '4px', backgroundColor: '#424242', borderRadius: '50%', marginRight: '8px',
                }}
              />
              {parse(fillMessageWith(
                i18n.InstallmentPlanPage.cancelableFlex.loanDetail,
                loan.loanSappId,
                loan.installments,
                moneyFormatter(loan.installmentAmount),
                moneyFormatterWithoutDecimals(loan.amount),
              ))}
            </Typography>
          ))}
        </Box>
      )}
      {loanRequest.loanToBePrePaid?.length > 1 && (
        <>
          <Typography
            align="left"
            sx={{
              marginTop: '24px', fontSize: '16px', fontWeight: 600, marginBottom: '12px',
            }}
          >
            {i18n.InstallmentPlanPage.cancelableFlex.selectLoan}
          </Typography>
          {cancelFlexEmpty && (
            <Typography align="left" sx={{ fontSize: '14px', marginBottom: '12px', color: '#D0021B' }}>
              {i18n.InstallmentPlanPage.cancelableFlex.emptySelection[0]}
            </Typography>
          )}
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: '#C5C5C5A0',
              }),
            }}
          >
            <FormGroup>
              {loanRequest.loanToBePrePaid.map((loan) => (
                <Checkbox
                  key={loan.loanSappId}
                  sx={{ padding: '4px 8px' }}
                  checked={loan.selected}
                  onChange={() => onChange(loan)}
                  label={parse(fillMessageWith(
                    i18n.InstallmentPlanPage.cancelableFlex.loanDetail,
                    loan.loanSappId,
                    loan.installments,
                    moneyFormatter(loan.installmentAmount),
                    moneyFormatterWithoutDecimals(loan.amount),
                  ))}
                />
              ))}
            </FormGroup>
          </LoadingOverlay>
        </>
      )}
      <Box sx={{ marginTop: '24px', marginBottom: '32px' }}>
        <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>
          {parse(fillMessageWith(
            i18n.InstallmentPlanPage.cancelableFlex.resumeInfo,
            loanRequest.cancelableAmountCalculated > 0
              ? moneyFormatter(loanRequest.newMaxAmountCalculated - loanRequest.cancelableAmountCalculated)
              : '$-',
            loanRequest.cancelableAmountCalculated > 0
              ? moneyFormatter(loanRequest.cancelableAmountCalculated)
              : '$-',
          ))}
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: '32px' }} />
    </>
  );
};

CancelableFlexibleLoan.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  cancelFlexEmpty: PropTypes.bool,
};

CancelableFlexibleLoan.defaultProps = {
  loading: false,
  cancelFlexEmpty: false,
};

export default CancelableFlexibleLoan;
