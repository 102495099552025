/* eslint-disable react/jsx-indent */
import parse from 'html-react-parser';
import { useLocation } from 'react-router';
import {
  Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { capitalize, capitalizeEachWord, moneyFormatter } from '../../../utils/formatterUtils';
import {
  getCalculatedAmountToDisburse, getPrePaidAmount, getProductName, getVehicleBrand, getVehicleModel, isPrePaid, isProductToPayVehicle,
} from '../../../utils/loanRequestUtils';
import { useAuth } from '../../../contexts/AuthContext';
import useNavigation from '../../../hooks/useNavigation';

const StepHeader = ({ loanRequest }) => {
  const { getLoggedUserProductList } = useAuth();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToInstallmentPlan } = useNavigation();
  const location = useLocation();

  const getData = () => (
    <>
    <Grid container mt={2} columnGap={2} rowGap={1}>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.idCredit, loanRequest.sappCreditId))}
        </Typography>
      </Grid>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.product, capitalize(getProductName(loanRequest, getLoggedUserProductList()))))}
        </Typography>
      </Grid>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.customerName, capitalizeEachWord(loanRequest.customerName.toLowerCase())))}
        </Typography>
      </Grid>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.customerTaxId, loanRequest.customerId))}
        </Typography>
      </Grid>
      {loanRequest.requestedAmount && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(i18n.StepHeader.requestedAmount, moneyFormatter(getCalculatedAmountToDisburse(loanRequest))))}
          </Typography>
        </Grid>
      )}
      {loanRequest.requestedAmount && isPrePaid(loanRequest) && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(i18n.StepHeader.cancelAmount, moneyFormatter(getPrePaidAmount(loanRequest))))}
          </Typography>
        </Grid>
      )}
      {loanRequest.requestedAmount && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(
              i18n.StepHeader.paymentPlan,
              loanRequest.installmentsNumberSelected,
              moneyFormatter(loanRequest.installmentAmountSelected),
            ))}
          </Typography>
        </Grid>
      )}
      {isProductToPayVehicle(loanRequest) && getVehicleBrand(loanRequest) && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(
              i18n.StepHeader.vehicle,
              getVehicleBrand(loanRequest),
              getVehicleModel(loanRequest),
            ))}
          </Typography>
        </Grid>
      )}
      {loanRequest.advancePaymentAmount > 0 && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(i18n.StepHeader.advanceAmount, moneyFormatter(loanRequest.advancePaymentAmount)))}
          </Typography>
        </Grid>
      )}
    </Grid>
      {!location.pathname.startsWith('/plan-cuotas/') && (
        <Grid container mt={2} columnGap={2} rowGap={1}>
          <Grid item>
            <Button
              className="minimalist"
              variant="tertiary"
              sx={{ padding: '0px 4px' }}
              onClick={() => goToInstallmentPlan(loanRequest.hashKey)}
            >
              <Typography align="left" sx={{ fontSize: '0.875rem', color: '#E72064', textDecoration: 'underline' }}>
                {i18n.InstallmentPlanPage.installmentPlanModify}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <Accordion
          title={i18n.StepHeader.title}
          variant="outline"
          expandIcon={<ExpandMoreIcon fontSize="small" />}
        >
          {getData()}
        </Accordion>
      ) : (
        <>
          <Typography align="left" sx={{ fontWeight: 600 }}>{i18n.StepHeader.title}</Typography>
          {getData()}
        </>
      )}
    </>
  );
};

StepHeader.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default StepHeader;
