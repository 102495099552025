import { Fragment } from 'react';
import { useParams } from 'react-router';
import { Button, CardActions, CardContent } from '@doit/pcnt-react-ui-library';
import {
  Box,
  Divider, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Card from '../../components/commons/Card';
import useCustomerDataController from '../../hooks/controllers/useCustomerDataController';
import StepHeader from './components/StepHeader';
import Loading from '../../components/commons/Loading';
import i18n from '../../common/i18n';
import { customerDataSchemaValidation } from '../../forms/customerDataSchemaValidations';
import CustomerDataInputText from './components/CustomerDataInputText';
import CustomerDataComboBox from './components/CustomerDataComboBox';
import CustomerDataTypeAhead from './components/CustomerDataTypeAhead';
import CustomerDataDebitCardInput from './components/CustomerDataDebitCardInput';
import CheckboxControlled from '../../components/commons/CheckboxControlled';
import TextInputControlled from '../../components/commons/TextInputControlled';
import StepsLoanRequest from './components/StepsLoanRequest';
import useNavigation from '../../hooks/useNavigation';

const CustomerDataPage = () => {
  const { hash } = useParams();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToInstallmentPlan } = useNavigation();
  const {
    globalLoading,
    loanRequest,
    inputGroups,
    resolveToShowConditional,
    resolveToShowUserInputIfAlreadyHasValue,
    onSelectOption,
    sendValidationCode,
    verificateCode,
    mustLoadDebitCardInfo,
    hasDebitCardValid,
    doSubmit,
    ctaLoading,
    onError,
    userFilled,
    doHintAction,
  } = useCustomerDataController();

  const methods = useForm({
    resolver: yupResolver(customerDataSchemaValidation(inputGroups)),
  });

  if (globalLoading) {
    return <Loading />;
  }

  const getContent = () => (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(doSubmit, onError)}>
        {inputGroups.map((inputGroup, index) => (
          <Fragment key={inputGroup.name}>
            <Typography align="left" sx={{ marginBottom: '32px', marginTop: index !== 0 ? '32px' : '0px' }}>
              <b>{inputGroup.name}</b>
            </Typography>
            <Grid container key={inputGroup.name} columnGap="24px" rowGap="32px">
              {inputGroup.variables.map((input) => (
                <Fragment key={input.name}>
                  {input.userInputOptions.type === 'TEXT' && resolveToShowConditional(input) && (
                    <CustomerDataInputText
                      input={input}
                      userFilled={userFilled}
                      methods={methods}
                      loanRequest={loanRequest}
                      onSendValidationCode={(inputAux) => sendValidationCode(inputAux, methods)}
                      onInputCode={(code, inputAux) => verificateCode(code, inputAux, methods)}
                      onHintAction={(inputAux) => doHintAction(inputAux, methods)}
                    />
                  )}
                  {input.userInputOptions.type === 'CHECKBOX' && resolveToShowConditional(input) && (
                    <Grid item xs="auto">
                      <CheckboxControlled
                        name={input.name}
                        label={input.userInputOptions.label}
                        defaultValue={!!input.userInputOptions.defaultValue}
                      />
                    </Grid>
                  )}
                  {(input.userInputOptions.type === 'COMBO' || input.userInputOptions.type === 'RADIO')
                    && resolveToShowConditional(input) && resolveToShowUserInputIfAlreadyHasValue(input) && (
                      <CustomerDataComboBox
                        input={input}
                        inputGroup={inputGroup}
                        index={index}
                        methods={methods}
                        onSelectOption={onSelectOption}
                        userFilled={userFilled}
                        onHintAction={(inputAux) => doHintAction(inputAux, methods)}
                      />
                  )}
                  {input.userInputOptions.type === 'TYPEAHEAD' && resolveToShowConditional(input) && (
                    <CustomerDataTypeAhead
                      input={input}
                      inputGroup={inputGroup}
                      index={index}
                      methods={methods}
                      onSelectOption={onSelectOption}
                      userFilled={userFilled}
                    />
                  )}
                  {input.userInputOptions.type === 'DATE' && resolveToShowConditional(input) && (
                    <Grid item xs="auto">
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DatePicker
                          name={input.name}
                          label={input.userInputOptions.label}
                          defaultValue={input.userInputOptions.defaultValue}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}
                  {input.userInputOptions.type === 'TEXT_AREA' && resolveToShowConditional(input) && (
                    <Grid item xs="auto">
                      <TextInputControlled
                        name={input.name}
                        label={input.userInputOptions.label}
                        defaultValue={input.userInputOptions.defaultValue}
                        variant="outlined"
                        helperText={input.userInputOptions.hint}
                        mandatory={input.userInputOptions.required}
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {input.userInputOptions.type === 'CARD' && resolveToShowConditional(input) && (
                    <Grid item xs={12}>
                      <CustomerDataDebitCardInput
                        input={input}
                        loanRequest={loanRequest}
                        mustLoadDebitCardInfo={mustLoadDebitCardInfo || false}
                        hasDebitCardValid={hasDebitCardValid || false}
                        methods={methods}
                      />
                    </Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
            {index < inputGroups.length - 1 && <Divider sx={{ marginTop: '32px' }} />}
          </Fragment>
        ))}
        <CardActions sx={{ marginTop: { xs: '64px', md: '' } }}>
          <Box>
            {!isMobile && (
              <Button
                variant="tertiary"
                color="grey"
                onClick={() => goToInstallmentPlan(hash)}
                fullWidth={isMobile}
              >
                {i18n.InstallmentPlanPage.installmentPlanBack}
              </Button>
            )}
            <Button
              type="submit"
              color="grey"
              loading={ctaLoading}
              fullWidth={isMobile}
            >
              {i18n.CustomerData.cta}
            </Button>
            {isMobile && (
              <Button
                variant="tertiary"
                color="grey"
                onClick={() => goToInstallmentPlan(hash)}
                fullWidth={isMobile}
                sx={{ marginTop: '12px' }}
              >
                {i18n.InstallmentPlanPage.installmentPlanBack}
              </Button>
            )}
          </Box>
        </CardActions>
      </form>
    </FormProvider>
  );

  return (
    <>
      <StepsLoanRequest stepActive={i18n.StepsLoanRequest[2]} />
      {isMobile ? (
        <Grid container sx={{ backgroundColor: '#FFF', padding: '24px' }}>
          <Grid item xs={12}>
            <StepHeader loanRequest={loanRequest} />
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            {getContent()}
          </Grid>
        </Grid>
      ) : (
        <>
          <Card>
            <CardContent>
              <StepHeader loanRequest={loanRequest} />
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              {getContent()}
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default CustomerDataPage;
